import { defineMessages } from 'react-intl';

const BASE = 'install';

export const INSTALL = defineMessages({
  ASK_ADMIN_INSTALL_PROMPT: {
    id: `${BASE}.ask-admin-install-prompt`,
    description: 'Ask Admin Install Prompt',
    defaultMessage: 'Ask an AWS admin to install',
  },
  ASK_ADMIN_ENTER_EMAIL: {
    id: `${BASE}.ask-admin-enter-email`,
    description: 'Ask admin enter email',
    defaultMessage: 'Enter email address',
  },
  ASK_ADMIN_EMAIL_SENT: {
    id: `${BASE}.ask-admin-email-sent`,
    description: 'Ask admin email sent text',
    defaultMessage: 'Invitation sent.',
  },
  ASK_ADMIN_INSTALL_SUBTEXT: {
    id: `${BASE}.ask-admin-install-subtext`,
    description: 'Ask Admin install subtext',
    defaultMessage: 'Enter their email and we will send them an invite to install.',
  },
  ASK_ADMIN_SEND: {
    id: `${BASE}.ask-admin-send`,
    description: 'Ask admin send text',
    defaultMessage: 'Invite',
  },
  CHECKING_ORG_NAME: {
    id: `${BASE}.checking-org-name`,
    description: 'Checking org name text',
    defaultMessage: 'Checking name availability',
  },
  CREATE_ACCOUNT_FRAGMENT: {
    id: `${BASE}.create-account-fragment`,
    description: 'Create account fragment text',
    defaultMessage: 'create an account',
  },
  CREATE_ORG_PROMPT: {
    id: `${BASE}.create-org-prompt`,
    description: 'Create org prompt',
    defaultMessage: 'What is the name of your organization?',
  },

  CREATE_ORG_LABEL: {
    id: `${BASE}.install-text-input.lbael`,
    description: 'Create org field label',
    defaultMessage: 'Name',
  },
  CREATE_ORG_CAPTION: {
    id: `${BASE}.install-text-input.p`,
    description: 'Create org caption text',
    defaultMessage: 'Name cannot contain special characters or punctuation',
  },
  CREATE_ORG_PLACEHOLDER: {
    id: `${BASE}.create-org-placeholder`,
    description: 'Create Org Input Placeholder',
    defaultMessage: 'Ex. Acme, Acme Corp',
  },
  CREATE_ORG_SUCCESS: {
    id: `${BASE}.create-org-success`,
    description: 'Create org success text',
    defaultMessage: 'Step 1: Organization {orgName} Created',
  },
  CREATE_ORG_BTN: {
    id: `${BASE}.create-org-btn`,
    description: 'Create org button text',
    defaultMessage: 'Create',
  },
  DEPLOY_COMPLETE_HEADER: {
    id: `${BASE}.deploy-complete-header`,
    description: 'Deploy Complete Header',
    defaultMessage: 'Step 1: Organization {orgName} Created',
  },
  DEPLOY_COMPLETE_MESSAGE: {
    id: `${BASE}.deploy-complete-message`,
    description: 'Deploy complete message',
    defaultMessage: 'Step 2: AWS deployment in progress',
  },
  DEPLOY_COMPLETE_BTN: {
    id: `${BASE}.deploy-complete-btn`,
    description: 'Deploy complete btn text',
    defaultMessage: 'Back to Home page',
  },
  DEPLOY_OPEN_RAVEN: {
    id: `${BASE}.deploy-open-raven`,
    description: 'Deploy Open Raven Text',
    defaultMessage: 'Install Open Raven',
  },
  DEPLOY_OPEN_RAVEN_LINKS: {
    id: `${BASE}.deploy-open-raven.bottom-links`,
    description: 'Deploy Open Raven bottom links',
    defaultMessage: 'If you are not able to finish the installation, {adminInstall} or {shareableLink}.',
  },
  DEPLOY_OPEN_RAVEN_LINKS_ASK_ADMIN: {
    id: `${BASE}.deploy-open-raven.bottom-links.a`,
    description: 'Deploy Open Raven bottom links invite admin',
    defaultMessage: 'invite an AWS Admin to install',
  },
  DEPLOY_OPEN_RAVEN_LINKS_SHAREABLE: {
    id: `${BASE}.deploy-open-raven.bottom-links.span`,
    description: 'Deploy Open Raven bottom links shareable link',
    defaultMessage: 'get a shareable invite',
  },
  ERROR_ORG_NAME_REGEX: {
    id: `${BASE}.error-org-name-regex`,
    description: 'Error Org Name wrong characters text',
    defaultMessage: 'Invalid characters. The available characters for the organization name are lowercase and uppercase A-Z, digits 0-9, special characters ". , _ -", and white space.',
  },
  ERROR_ORG_UNAVAILABLE: {
    id: `${BASE}.error-org-unavailable`,
    description: 'Error Org Unavailable text',
    defaultMessage: 'Organization name is unavailable',
  },
  FINISH_IN_AWS: {
    id: `${BASE}.finish-in-aws`,
    description: 'Finish in AWS text',
    defaultMessage: 'Finish installation in AWS',
  },
  LOGIN_FRAGMENT: {
    id: `${BASE}.login-fragment`,
    description: 'Login Prompt login fragment text',
    defaultMessage: 'login',
  },
  LOGIN_PROMPT: {
    id: `${BASE}.login-prompt`,
    description: 'Login prompt text',
    defaultMessage: 'To start {isFromAwsMarketplace, select, true {deployment} false {trial}} please...',
  },
  SHAREABLE_INVITE_COLLAPSE_PROMPT: {
    id: `${BASE}.shareable-invite-collapse-prompt`,
    description: 'Shareable Invite Collapse Prompt text',
    defaultMessage: 'Collapse invite.',
  },
  SHAREABLE_INVITE_COPY_PROMPT: {
    id: `${BASE}.shareable-invite-copy-prompt`,
    description: 'Shareable Invite Copy Prompt text',
    defaultMessage: 'Click to copy to clipboard',
  },
  SHAREABLE_INVITE_EXPAND_PROMPT: {
    id: `${BASE}.shareable-invite-expand-prompt`,
    description: 'Shareable Invite Expand Prompt',
    defaultMessage: 'Expand to view invite.',
  },
  SHAREABLE_INVITE_TITLE: {
    id: `${BASE}.shareable-invite-title`,
    description: 'Shareable Invite title text',
    defaultMessage: 'Shareable Invite',
  },
  ORG_CREATE_SUCCESS_NEXT_HEADLINE: {
    id: `${BASE}.bottom.h6`,
    description: 'organization created successfuly next headline',
    defaultMessage: 'Next up, Step 2: Deploy in AWS',
  },
  ORG_CREATE_SUCCESS_NEXT_TEXT: {
    id: `${BASE}.bottom.p`,
    description: 'organization created successfuly next text',
    defaultMessage: 'You now need to finish your installation in your AWS account. Click the button below to login and complete the CloudFormation installation.',
  },
});
