import { defineMessages } from 'react-intl';

const base = 'yourein.';

export const YOUREIN = defineMessages({
  HEADER: {
    id: `${base}header`,
    description: 'Header for yourein view',
    defaultMessage: 'You’re in!',
  },
  TEXT_INSTALL: {
    id: `${base}text-install`,
    description: 'Text telling user he/she can install',
    defaultMessage: 'You can now install your own instance of Open Raven.',
  },
  TEXT_CLICK: {
    id: `${base}text-click`,
    description: 'Textt telling user to click the button',
    defaultMessage: 'Click the button below to deploy with AWS.',
  },
  INSTALL: {
    id: `${base}install`,
    description: 'Label for install buttom',
    defaultMessage: 'Install',
  },
});
