import React from 'react';
import { useIntl } from 'react-intl';
import { TRANSLATION_KEYS } from 'translations/keys';
import moment from 'moment';
import './legal-links.scss';

const {
  LEGAL_LINKS: {
    COPY,
    PRIVACY,
    TOS,
  },
} = TRANSLATION_KEYS;

const LegalLinks = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="legal-links">
      <span>
        &copy;
        {moment().format('YYYY')}
        &nbsp;
        {formatMessage(COPY)}
      </span>
      <a
        target="_blank" rel="noreferrer"
        href="https://www.openraven.com/msa"
      >
        {formatMessage(TOS)}
      </a>
      <a
        target="_blank" rel="noreferrer"
        href="https://www.openraven.com/privacy"
      >
        {formatMessage(PRIVACY)}
      </a>
      <a
        target="_blank" rel="noreferrer"
        href="https://www.openraven.com/security"
      >
        Security Policy
      </a>
    </div>
  );
};

export {
  LegalLinks,
};
