import { useEffect } from 'react';
import { PARDOT_SCORE } from 'config/constants';
import { ROUTE } from 'containers/root-router/route-constants';

export const usePardotScoreRedirect = (pardotScore, history, getPardotScoreReset) => {
  useEffect(() => {
    if (pardotScore.payload) {
      switch (pardotScore.payload) {
        case PARDOT_SCORE.HIGH:
          history.push(ROUTE.DEMO_PAGE);
          break;
        case PARDOT_SCORE.LOW:
          history.push(ROUTE.VIEW_DEMO);
          break;
        default: break;
      }

      getPardotScoreReset();
    }
  }, [pardotScore.payload, history]);
};
