
import React from 'react';
import { parseIntlText } from 'translations/keys';
import { NavLink } from '../../../nav-link';
import './mobile-items.scss';


const MobileItems = ({
  intl,
  onClick,
  navigationConfig,
}) => {
  const click = () => {
    if (onClick) {
      onClick();
    }
  };


  const items = navigationConfig
    .filter(item => !item.noMenu)
    .map((item, index) => (
      <NavLink
        key={index}
        to={item.to}
        onClick={() => click(item.to, null)}
      >
        {parseIntlText(intl, item.title)}
      </NavLink>
    ));

  return (
    <div className="mobile-items ">
      <div className="mobile-item-content">
        {items}
      </div>

    </div>
  );
};

export { MobileItems };
