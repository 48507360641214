export default {
  BATCH_ACTIONS: 'BATCH_ACTIONS',

  APP_SUBMIT_CONTACT_FORM: 'APP_SUBMIT_CONTACT_FORM',
  APP_CONTACT_FORM_SET_FIELD: 'APP_CONTACT_FORM_SET_FIELD',
  APP_CONTACT_FORM_SET_ERRORS: 'APP_CONTACT_FORM_SET_ERRORS',
  APP_CONTACT_FORM_RESET_FIELDS: 'APP_CONTACT_FORM_RESET_FIELDS',
  APP_CONTACT_FORM_FETCH: 'APP_CONTACT_FORM_FETCH',
  APP_CONTACT_FORM_ERROR: 'APP_CONTACT_FORM_ERROR',
  APP_CONTACT_FORM_SUCCESS: 'APP_CONTACT_FORM_SUCCESS',

  APP_TOGGLE_MODAL: 'APP_TOGGLE_MODAL',

  APP_RESET_EMAIL_FORM: 'APP_RESET_EMAIL_FORM',
  APP_SUBMIT_EMAIL_FORM: 'APP_SUBMIT_EMAIL_FORM',
  APP_EMAIL_FORM_SET_FIELD: 'APP_EMAIL_FORM_SET_FIELD',
  APP_EMAIL_FORM_SET_ERRORS: 'APP_EMAIL_FORM_SET_ERRORS',

  APP_RESET_UNSUBSCRIBE_FORM: 'APP_RESET_UNSUBSCRIBE_FORM',
  APP_SUBMIT_UNSUBSCRIBE_FORM: 'APP_SUBMIT_UNSUBSCRIBE_FORM',
  APP_UNSUBSCRIBE_FORM_SET_FIELD: 'APP_UNSUBSCRIBE_FORM_SET_FIELD',
  APP_UNSUBSCRIBE_FORM_SET_ERRORS: 'APP_UNSUBSCRIBE_FORM_SET_ERRORS',

  APP_POST_EMAIL_CONTACT_REQUEST: 'APP_POST_EMAIL_CONTACT_REQUEST',
  APP_POST_EMAIL_CONTACT_SUCCESS: 'APP_POST_EMAIL_CONTACT_SUCCESS',
  APP_POST_EMAIL_CONTACT_ERROR: 'APP_POST_EMAIL_CONTACT_ERROR',

  APP_SET_ERROR: 'APP_SET_ERROR',
  APP_CLEAR_ERROR: 'APP_CLEAR_ERROR',
  APP_TOGGLE_ERROR_VISIBILITY: 'APP_TOGGLE_ERROR_VISIBILITY',
  APP_BLOG_ADD_LOCAL_COMMENT: 'APP_BLOG_ADD_LOCAL_COMMENT',
  APP_BLOG_RESET_LOCAL_COMMENTS: 'APP_BLOG_RESET_LOCAL_COMMENTS',
  SET_SELECTED_DEMO_TIME: 'SET_SELECTED_DEMO_TIME',

  APP_SET_SEARCH: 'APP_SET_SEARCH',
  SET_DEMO_CONFIRMED: 'SET_DEMO_CONFIRMED',
};
