import createReducer from 'rdx/utils/create-reducer';
import types from 'rdx/modules/app/types';

const initialState = {
  modalIsVisible: false,
};


const modalReducer = createReducer(initialState, {
  [types.APP_TOGGLE_MODAL](_, { payload = true }) {
    return {
      modalIsVisible: payload,
    };
  },
});

export {
  modalReducer,
};
