import React from 'react';
import { SocialIcons } from 'components/social-icons';
import { LegalLinks } from 'components/legal-links';
import './footer.scss';

const Footer = () => (
  <div className="footer-container">
    <footer>
      <div className="bottom-links">
        <SocialIcons />
        <LegalLinks />
      </div>
    </footer>
  </div>
);


export {
  Footer,
};
