import { defineMessages } from 'react-intl';

const base = 'sign-up.';

export const SIGN_UP = defineMessages({
  DOMAIN_ERROR: {
    id: `${base}domain-error`,
    description: 'error for blacklisted domain',
    defaultMessage: 'Sorry, we do not support emails from {domain}',
  },
  TITLE_WELCOME: {
    id: `${base}title-welcome`,
    description: 'Title for account registration',
    defaultMessage: 'Create your Open Raven account.',
  },
  ALREADY_HAVE_ACCOUNT: {
    id: `${base}already-have-account`,
    description: 'Already have an account text',
    defaultMessage: 'Already have an account?',
  },
  SIGN_UP: {
    id: `${base}sign-up`,
    description: 'Sign up text for button',
    defaultMessage: 'Sign up',
  },
  PRIVACY_POLICY: {
    id: `${base}privacy-policy`,
    description: 'Privacy policy text',
    defaultMessage: 'Privacy Policy',
  },
  TERMS_AND_CONDITIONS: {
    id: `${base}terms-and-conditions`,
    description: 'Terms and conditions text',
    defaultMessage: 'Terms and Conditions',
  },
  AGREE: {
    id: `${base}agree`,
    description: 'Agree text',
    defaultMessage: 'By creating an account, you are agreeing to our',
  },
  SENT_CONFIRMATION_EMAIL: {
    id: `${base}sent-confirmation-email`,
    description: 'Title for screen showing confirmation of registration',
    defaultMessage: 'We\'ve sent you a confirmation email',
  },
  CHECK_YOUR_EMAIL: {
    id: `${base}check-your-email`,
    description: 'Message telling the user to check their email',
    defaultMessage: 'Check your email to verify your account.',
  },
  CHECK_SPAM_OR_RESEND: {
    id: `${base}check-spam-or-resend`,
    description: 'Message telling the user to check their spam or resend email',
    defaultMessage: 'If you can\'t locate the email check your Spam folder or click this link to resend.',
  },
  RESEND_EMAIL_VERIFICATION: {
    id: `${base}resend-email-verification`,
    description: 'Link to resend email confirmation',
    defaultMessage: 'Resend confirmation email',
  },
  ACCOUNT_CREATED: {
    id: `${base}account-created`,
    description: 'Account created message',
    defaultMessage: 'Account created',
  },
  GO_TO_LOGIN: {
    id: `${base}go-to-login`,
    description: 'Go to login message for button',
    defaultMessage: 'Go to Login',
  },
  COUPLE_MORE_THINGS: {
    id: `${base}couple-more-things`,
    description: 'Title when user is finishing account creation details',
    defaultMessage: 'We just need a couple more things',
  },
  FINISH: {
    id: `${base}finish`,
    description: 'Finish button text',
    defaultMessage: 'Finish',
  },
  LOG_IN: {
    id: `${base}log-in`,
    description: 'Log In text',
    defaultMessage: 'Log In',
  },
  ERROR_EMAIL_TAKEN: {
    id: `${base}error-email-taken`,
    description: 'Error for sign up flow when user uses email already taken',
    defaultMessage: 'Email already taken',
  },
  ERROR_INVALID_EMAIL: {
    id: `${base}error-invalid-email`,
    description: 'Invalid email error',
    defaultMessage: 'Invalid email provided.',
  },
  ERROR_ACCOUNT_CONFIG: {
    id: `${base}error-account-config`,
    description: 'Error for account config',
    defaultMessage: 'Account configuration failed',
  },
  ACCOUNT_CREATED_MESSAGE: {
    id: `${base}account-created-message`,
    description: 'Account created message',
    defaultMessage: 'Your account has been successfully created.',
  },
  ERROR_EMAIL_RESEND: {
    id: `${base}error-email-resend`,
    description: 'Error for verification email resend fail',
    defaultMessage: 'Could not resend verification email. Please try again.',
  },
});
