import { defineMessages } from 'react-intl';

const base = 'book-demo.';

export const BOOK_DEMO = defineMessages({
  HEADER: {
    id: `${base}header`,
    description: 'Book demo page header',
    defaultMessage: 'Select a time for a meeting',
  },
  TIMEZONE_MESSAGE: {
    id: `${base}timezone-message`,
    description: 'Tells user what timezone times are in',
    defaultMessage: 'All times are PST',
  },
});
