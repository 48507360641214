import { defineMessages } from 'react-intl';

export const COMMON = defineMessages({
  AT: {
    id: 'common.at',
    description: 'at',
    defaultMessage: 'at',
  },
  BACK: {
    id: 'common.back',
    description: 'Back text',
    defaultMessage: 'back',
  },
  BIRTHDAY: {
    id: 'common.birthday',
    description: 'Birthday text',
    defaultMessage: 'Birthday',
  },
  BIRTHDAY_TOOLTIP: {
    id: 'common.birthday-tooltip',
    description: 'Birthday tooltip',
    defaultMessage: 'We like to send you birthday surprises',
  },
  CANCEL: {
    id: 'common.cancel',
    description: 'Cancel text',
    defaultMessage: 'Cancel',
  },
  CHOOSE_FILE: {
    id: 'common.choose-file',
    description: 'Choose file text',
    defaultMessage: 'Choose file',
  },
  CONFIRM_DEMO_LABEL: {
    id: 'common.confirm-demo-label',
    description: 'Label for confirm demo button',
    defaultMessage: 'Confirm Demo Time',
  },
  CONFIRM_NEW_PASSWORD: {
    id: 'common.confirm-new-password',
    description: 'Confirm new Password text',
    defaultMessage: 'Confirm new password',
  },
  CONFIRM_PASSWORD: {
    id: 'common.confirm-password',
    description: 'Confirm password text',
    defaultMessage: 'confirm password',
  },
  COPY: {
    id: 'common.copy',
    description: 'Copy text',
    defaultMessage: 'Copy',
  },
  COPY_CLIPBOARD: {
    id: 'common.copy-clipboard',
    description: 'Copy displayed in clipboard copy component popup',
    defaultMessage: 'Copied to clipboard',
  },
  CREATE_AN_ACCOUNT: {
    id: 'common.signup-btn-label',
    description: 'Label for signup button',
    defaultMessage: 'Create an account',
  },
  DATE: {
    id: 'common.date',
    description: 'Date string',
    defaultMessage: 'date',
  },
  DONE: {
    id: 'common.done',
    description: 'Done text',
    defaultMessage: 'Done',
  },
  EDIT: {
    id: 'common.edit',
    description: 'Edit text',
    defaultMessage: 'Edit',
  },
  EMAIL: {
    id: 'common.email',
    description: 'Email text',
    defaultMessage: 'Email',
  },
  EMAIL_ADDRESS: {
    id: 'common.email-address',
    description: 'Email address text',
    defaultMessage: 'Email Address',
  },
  ERROR_FIELD: {
    id: 'common.error-field',
    description: 'General error for when we detect an error with a field',
    defaultMessage: 'field error',
  },
  ERROR_FIELD_EMPTY: {
    id: 'common.error-field-empty',
    description: 'Empty field error for when we detect an error with a field',
    defaultMessage: 'Please enter the {fieldName}',
  },
  ERROR_INVALID_EMAIL: {
    id: 'common.error-invalid-email',
    description: 'Invalid email error text',
    defaultMessage: 'Please provide a valid email',
  },
  ERROR_PASSWORD_MISMATCH: {
    id: 'common.error-password-mismatch',
    description: 'The confirm password does not match the other one',
    defaultMessage: 'Passwords do not match',
  },
  FIRST_NAME: {
    id: 'common.first-name',
    description: 'First name text',
    defaultMessage: 'First Name',
  },
  GO_BACK_HOME: {
    id: 'common.go-back-home',
    description: 'Text for link to home page',
    defaultMessage: 'Back to the home page',
  },
  GREETING_USER: {
    id: 'common.greeting-user',
    description: 'Greeting to user',
    defaultMessage: 'Happy {dateString}',
  },
  LAST_NAME: {
    id: 'common.last-name',
    description: 'Last name text',
    defaultMessage: 'Last Name',
  },
  LEARN_MORE: {
    id: 'common.learn-more',
    description: 'Learn More Text',
    defaultMessage: 'Learn More',
  },
  LINKEDIN: {
    id: 'common.linkedIn',
    description: 'LinkedIn text',
    defaultMessage: 'LinkedIn',
  },
  LOGIN: {
    id: 'common.login',
    description: 'Login text',
    defaultMessage: 'Log In',
  },
  NAME: {
    id: 'common.name',
    description: 'Name text',
    defaultMessage: 'Name',
  },
  NEW_PASSWORD: {
    id: 'common.new-password',
    description: 'New Password text',
    defaultMessage: 'New password',
  },
  OLD_PASSWORD_PLACEHOLDER: {
    id: 'common.old-password-placeholder',
    description: 'Old Password text',
    defaultMessage: 'Current Password',
  },
  ON: {
    id: 'common.on',
    description: 'on',
    defaultMessage: 'on',
  },
  OPTIONAL_FIELD: {
    id: 'common.optional-field',
    description: 'Optional field label',
    defaultMessage: '(optional)',
  },
  OR: {
    id: 'common.or',
    description: 'Or text',
    defaultMessage: 'or',
  },
  PASSWORD: {
    id: 'common.password',
    description: 'Password text',
    defaultMessage: 'Password',
  },
  PHONE: {
    id: 'common.phone',
    description: 'Phone text',
    defaultMessage: 'phone',
  },
  PHONE_TOOLTIP: {
    id: 'common.phone-tooltip',
    description: 'Phone tooltip',
    defaultMessage: 'For urgent notifications',
  },
  PROFILE_PICTURE: {
    id: 'common.profile-picture',
    description: 'Profile picture text',
    defaultMessage: 'Profile Picture',
  },
  SAVE: {
    id: 'common.save',
    description: 'save button text',
    defaultMessage: 'Save',
  },
  SAVED: {
    id: 'common.saved',
    description: 'saved button text',
    defaultMessage: 'Saved',
  },
  START: {
    id: 'common.start',
    description: 'Start text',
    defaultMessage: 'Start',
  },
  SUBMIT: {
    id: 'common.submit',
    description: 'Submit text',
    defaultMessage: 'Submit',
  },
  SUCCESS: {
    id: 'common.success',
    description: 'success button text',
    defaultMessage: 'Success',
  },
  TOS: {
    id: 'common.tos',
    description: 'Terms of Service text',
    defaultMessage: 'Terms of Service',
  },
  TWITTER: {
    id: 'common.twitter',
    description: 'Twitter text',
    defaultMessage: 'twitter',
  },
  USERNAME_EMAIL: {
    id: 'common.username-email',
    description: 'email text',
    defaultMessage: 'Email',
  },
  ZOOM: {
    id: 'common.zoom',
    description: 'Zoom text',
    defaultMessage: 'zoom',
  },
});
