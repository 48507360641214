import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useCookies } from 'react-cookie';
import { hasLoggedIn } from 'app-cookies';
import PropTypes from 'prop-types';

import { getRdxActionMapper, getRdxSelectionMapper } from 'rdx/utils/props-mapping';

const AuthHandlerView = ({
  userSettings,
  getUserSettings,
  getUserSettingsReset,
  setAccessToken,
  setIdToken,
  setUserInfo,
  userInfo,
}) => {
  const [, setCookie] = useCookies([hasLoggedIn.name]);
  const {
    authState: { idToken, accessToken },
    authService,
  } = useOktaAuth();

  useEffect(() => {
    setAccessToken(accessToken);
  }, [accessToken]);

  useEffect(() => {
    if (idToken) {
      setCookie(hasLoggedIn.name, true, hasLoggedIn.options);
    }
    setIdToken(idToken);
  }, [idToken]);

  useEffect(() => {
    if (idToken && authService) {
      authService.getUser()
        .then(data => setUserInfo(data))
        .catch(err => console.error(err));
    }
  }, [idToken, authService]);

  // Update user settings values when idtoken changes
  useEffect(() => {
    if (idToken && userInfo && userInfo.sub && userSettings) {
      const {
        dataLoaded,
        fetching,
        error,
      } = userSettings;

      if (!fetching && !dataLoaded && !error) {
        getUserSettings({
          pathParams: {
            userId: userInfo.sub,
          },
        });
      }
    } else {
      getUserSettingsReset();
    }
  }, [idToken, userInfo]);

  return null;
};

AuthHandlerView.propTypes = {
  setIdToken: PropTypes.func.isRequired,
};

const stateMapper = getRdxSelectionMapper({
  userSettings: 'getUserSettingsData',
  userInfo: 'getUserInfo',
});

const dispatchMapper = getRdxActionMapper([
  'getUserSettings',
  'getUserSettingsReset',
  'setAccessToken',
  'setIdToken',
  'setUserInfo',
]);

const AuthHandler = compose(
  withRouter,
  connect(stateMapper, dispatchMapper)
)(AuthHandlerView);

export {
  AuthHandler,
};
