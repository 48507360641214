import 'intl'; // required to use IntlProvider on android emulator, refer to: https://github.com/oursky/create-react-native-skygear/issues/26
import React from 'react';
import { IntlProvider } from 'react-intl';

const ConnectedIntlProvider = props => (
  <IntlProvider {...props}>
    {props.children}
  </IntlProvider>
);

export { ConnectedIntlProvider };
