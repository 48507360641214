import { get } from 'lodash';
import { createSelector } from 'reselect';
import {
  blogs,
  news,
  libraries,
  abouts,
  careers,
  jobs,
  platforms,
  products,
  blogTags,
} from 'rdx/data-transformers';
import memoize from 'lodash/memoize';
import { API_REDUCER_INITIAL_STATE } from 'rdx/constants';
import { GOOGLE_EVENT_ID } from 'config/constants';
import apiSelectors from '../api/selectors';

const getProcessedBlogs = createSelector(
  state => get(state, 'api.blogsData', API_REDUCER_INITIAL_STATE),
  state => get(state, 'localBlogComments', []),
  (state, localComments) => {
    if (state.dataLoaded) {
      return memoize(
        selectedTag => ({
          ...state,
          payload: blogs.dataTransformer(state.payload, selectedTag, localComments),
        })
      );
    }

    return memoize(
      () => ({
        ...state,
        payload: [],
      })
    );
  }
);

const getProcessedAbouts = createSelector(
  state => get(state, 'api.aboutsData', API_REDUCER_INITIAL_STATE),
  (state) => {
    const payload = state.dataLoaded ? abouts.dataTransformer(state.payload) : [];

    return memoize(
      () => ({
        ...state,
        payload,
      })
    );
  }
);

const getProcessedJobs = createSelector(
  state => get(state, 'api.jobsData', API_REDUCER_INITIAL_STATE),
  (state) => {
    const payload = state.dataLoaded ? jobs.dataTransformer(state.payload) : [];

    return {
      ...state,
      payload,
    };
  }
);

const getProcessedCareers = createSelector(
  state => get(state, 'api.careersData', API_REDUCER_INITIAL_STATE),
  (state) => {
    const payload = state.dataLoaded ? careers.dataTransformer(state.payload) : [];

    return {
      ...state,
      payload,
    };
  }
);

const getProcessedNews = createSelector(
  state => get(state, 'api.newsData', API_REDUCER_INITIAL_STATE),
  (state) => {
    const payload = state.dataLoaded ? news.dataTransformer(state.payload) : [];

    return memoize(
      () => ({
        ...state,
        payload,
      })
    );
  }
);

const getProcessedLibraries = createSelector(
  state => get(state, 'api.librariesData', API_REDUCER_INITIAL_STATE),
  (state) => {
    const payload = state.dataLoaded ? libraries.dataTransformer(state.payload) : [];

    return memoize(
      () => ({
        ...state,
        payload,
      })
    );
  }
);

const getProcessedPlatforms = createSelector(
  state => get(state, 'api.platformsData', API_REDUCER_INITIAL_STATE),
  (state) => {
    const payload = state.dataLoaded ? platforms.dataTransformer(state.payload) : [];
    return {
      ...state,
      payload,
    };
  }
);

const getProcessedProducts = createSelector(
  state => get(state, 'api.productsData', API_REDUCER_INITIAL_STATE),
  (state) => {
    const payload = state.dataLoaded ? products.dataTransformer(state.payload) : [];

    return {
      ...state,
      payload,
    };
  }
);

const getProcessedTags = createSelector(
  state => get(state, 'api.tagsData', API_REDUCER_INITIAL_STATE),
  (state) => {
    const payload = state.dataLoaded ? blogTags.dataTransformer(state.payload) : [];

    return {
      ...state,
      payload,
    };
  }
);

const getBookedIndividualDemos = createSelector(
  apiSelectors.getDemosData,
  demosData => (demosData.payload
    ? demosData.payload.items.filter(demo => demo.id !== GOOGLE_EVENT_ID.GROUP_DEMO) : [])
);

const getGroupDemos = createSelector(
  apiSelectors.getGroupDemosData,
  demosData => (demosData.payload ? demosData.payload.items : [])
);

export default {
  getContactForm: state => get(state, 'contactForm', {}),
  getEmailForm: state => get(state, 'emailForm', {}),
  getUnsubscribeForm: state => get(state, 'unsubscribeForm', {}),
  getModal: state => get(state, 'modal', {}),
  getError: state => get(state, 'error', {}),
  getSearch: state => get(state, ['header', 'search'], ''),
  getProcessedBlogs,
  getProcessedTags,
  getProcessedNews,
  getProcessedAbouts,
  getProcessedLibraries,
  getProcessedCareers,
  getProcessedJobs,
  getProcessedPlatforms,
  getProcessedProducts,
  getSelectedDemoTime: state => get(state, 'selectedDemoTime', null),
  getBookedIndividualDemos,
  getGroupDemos,
  getAccessToken: state => get(state, 'accessToken', ''),
};
