import { defineMessages } from 'react-intl';

export const HELMET = defineMessages({
  DESCRIPTION: {
    id: 'helmet.description',
    description: 'Description that goes in the head description meta tag of the page',
    defaultMessage: 'Open Raven is the cloud native data protection platform that automates policy monitoring and enforcement. Auto discover, classify, and map your data.',
  },
  META_KEYWORDS: {
    id: 'helmet.keywords',
    description: 'meta keywords for SEO',
    defaultMessage: 'Data breach, Data Security, Data Privacy, DLP, GDPR, CCPA, California Data Privacy, Data Discovery',
  },
  SITE_TITLE: {
    id: 'helmet.site-title',
    description: 'title of the site',
    defaultMessage: 'Open Raven - Data Security, Re-Imagined',
  },
});
