import { get, groupBy } from 'lodash';
import moment from 'moment';
import { mapDomain } from 'lib/utils/map-domain';

const makePublishDate = date => moment(date).format('MMMM YYYY');

const dataTransformer = (_payload) => {
  if (!Array.isArray(_payload)) {
    return [];
  }
  let payload = get(_payload[0], 'library', []).map(item => ({
    ...item,
    image: { url: mapDomain(get(item.image, 'url', false)) },
    resource: { url: mapDomain(get(item.resource, 'url', false)) },
    publishDate: makePublishDate(item.publishDate),
  }));

  payload = groupBy(payload, item => item.type);

  return (
    payload
  );
};

export {
  dataTransformer,
};
