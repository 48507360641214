import { defineMessages } from 'react-intl';

const base = 'access-denied';

export const ACCESS_DENIED = defineMessages({
  DESCRIPTION: {
    id: `${base}.description`,
    description: 'Description on access denied page',
    defaultMessage: 'Permission denied. We are sorry but you do not have permission to to login to this organization.{lineBreak}{lineBreak}Please contact your organization administrator if you think this is not correct.',
  },
  HOME_BUTTON: {
    id: `${base}.home-button`,
    description: 'Hom button on access denied page',
    defaultMessage: 'Go to OpenRaven.com',
  },
});
