import { defineMessages } from 'react-intl';

const prefix = 'discovery';

export const DISCOVER = defineMessages({
  TITLE: {
    id: `${prefix}.title`,
    description: 'Discover Landing Page title',
    defaultMessage: 'What We Discover',
  },
  BLURB: {
    id: `${prefix}.blurb`,
    description: 'Discover Landing Page Blurb',
    defaultMessage: 'Open Raven identifies assets across an environment, fingerprinting data stores of all types for further analysis, data discovery and protection.',
  },
  SORT: {
    id: `${prefix}.sort`,
    description: 'Discover Landing Page sort label',
    defaultMessage: 'Filter by:',
  },
  BACK_BUTTON: {
    id: `${prefix}.back`,
    description: 'Discover Landing back button label',
    defaultMessage: 'Back to What We Discover',
  },
  SERVICE_NAME: {
    id: `${prefix}.service-name`,
    description: 'Discover Landing service name format',
    defaultMessage: '{name} and Open Raven',
  },
  SERVICE_DESCRIPTION: {
    id: `${prefix}.service-description`,
    description: 'Discover Landing service description format',
    defaultMessage: 'What is {name}',
  },
  GRAPH_ATTRIBUTES: {
    id: `${prefix}.graph-attributes`,
    description: 'Discover Landing graph attributes label',
    defaultMessage: 'Graph Attributes',
  },
  DMAP_ATTRIBUTES: {
    id: `${prefix}.graph-versions`,
    description: 'Discover Landing DMAP attributes label',
    defaultMessage: 'Versions',
  },
  METHOD: {
    id: `${prefix}.method`,
    description: 'Discover Landing method label',
    defaultMessage: 'Method:',
  },
  METHOD_WRAPPER: {
    id: `${prefix}.methods`,
    description: 'Discover Landing method label wrapper',
    defaultMessage: '{methodHTML}',
  },
  TYPE: {
    id: `${prefix}.type`,
    description: 'Discover Landing type label',
    defaultMessage: 'Type:',
  },
  TYPE_WRAPPER: {
    id: `${prefix}.type-wrapper`,
    description: 'Discover Landing type html wrapper',
    defaultMessage: '{updatedHTML}',
  },
  UPDATE: {
    id: `${prefix}.last-updated`,
    description: 'Discover Landing last updated',
    defaultMessage: 'Last Updated:',
  },
  UPDATE_WRAPPER: {
    id: `${prefix}.last-updated-wrapper`,
    description: 'Discover Landing last updated html wrapper',
    defaultMessage: '{updatedHTML}',
  },
  FEATURED: {
    id: `${prefix}.featured`,
    description: 'Discover Landing featured label',
    defaultMessage: 'Featured',
  },
  CLASSIFIERS: {
    id: `${prefix}.classifiers`,
    description: 'Discover Landing classifiers label',
    defaultMessage: 'Classifiers',
  },
  MATCHED: {
    id: `${prefix}.matched`,
    description: 'Discover Landing matched label',
    defaultMessage: 'Matched',
  },
  SUBCLASSIFIERS: {
    id: `${prefix}.subclassifiers`,
    description: 'Discover Landing subclassifiers label',
    defaultMessage: 'Subclassifiers',
  },
  INSTRUCTIONS: {
    id: `${prefix}.instructions`,
    description: 'Graph attribtes instruction text',
    defaultMessage: 'Click on an attribute below to view their dependencies',
  },
});
