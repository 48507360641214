import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { parseIntlText } from 'translations/keys';
import { getClassNameBuilder } from 'lib/utils/get-classname-builder';
import { HeaderLink } from '../header-link';
import './desktop-navigation.scss';

const MenuItemContainer = ({
  menu: {
    links,
    title,
    key,
    external,
  },
  intl,
  setIsHovering,
  isHovering,
  isSelected,
  setIsSelected,
}) => {
  const { getClassName } = getClassNameBuilder('desktop-navigation');

  const onMouseEnter = useCallback(() => {
    setIsHovering(true);
    setIsSelected(title);
  }, [title]);

  const onMouseLeave = useCallback(() => {
    setIsHovering(false);
    setIsSelected(null);
  }, []);

  const selected = isHovering && isSelected === title;

  return (
    <div className={getClassName('nav-wrapper')}>
      <HeaderLink
        external={external}
        to={key}
        links={links}
        className="header__link-container"
        message={parseIntlText(intl, title)}
        selected={selected}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </div>

  );
};

MenuItemContainer.propTypes = {
  menu: PropTypes.shape({
    links: PropTypes.array.isRequired,
    noMenu: PropTypes.bool,
    title: PropTypes.string.isRequired,
  }).isRequired,
  setIsHovering: PropTypes.func.isRequired,
  isHovering: PropTypes.bool.isRequired,
  isSelected: PropTypes.string,
  setIsSelected: PropTypes.func.isRequired,
};

MenuItemContainer.defaultProps = {
  isSelected: '',
};

const DesktopNavigation = ({
  intl,
  navigationConfig,
  targetMenuElementId,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isSelected, setIsSelected] = useState('');
  const { baseClass, getClassName } = getClassNameBuilder('desktop-navigation');

  return (
    <div className={baseClass}>
      <div className={getClassName('link-group')}>
        {navigationConfig.map((item, index) => (
          <MenuItemContainer
            menu={item}
            intl={intl}
            key={index}
            targetMenuElementId={targetMenuElementId}
            setIsHovering={setIsHovering}
            isHovering={isHovering}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
          />
        ))}
      </div>
    </div>
  );
};

/* eslint-disable react/forbid-prop-types */
DesktopNavigation.propTypes = {
  targetMenuElementId: PropTypes.string.isRequired,
  navigationConfig: PropTypes.array.isRequired,
};


export default injectIntl(DesktopNavigation);
