export const API_REQUEST_TYPES = ['REQUEST', 'SUCCESS', 'ERROR'];
export const API_METHOD_GET = 'GET';
export const API_METHOD_POST = 'POST';
export const API_METHOD_DELETE = 'DELETE';
export const API_METHOD_PUT = 'PUT';
export const RESET_TYPE = 'RESET';
export const API_REDUCER_INITIAL_STATE = {
  dataLoaded: false,
  fetching: false,
  error: null,
  payload: null,
};
