import { unpackCopy, unpackImage } from 'lib/utils/contentful';

const isPanel = /^Panel/i;
const isHero = /^Hero/i;

const dataTransformer = (_payload) => {
  let payload = _payload;

  if (!Array.isArray(payload)) {
    payload = [{ ..._payload }];
  }

  return payload.map(({
    fields,
  }) => {
    const copy = unpackCopy(fields?.copy);
    const gallery = fields?.images?.map(image => ({ alt: image?.fields?.description, image: unpackImage(image) }));
    const panelsAndHeros = fields?.panels?.map((panel) => {
      const { fields: panelfields } = panel;
      const { image, copy: text, ...rest } = panelfields;
      return (
        {
          ...rest,
          backgroundImage: unpackImage(image?.[0]),
          copy: unpackCopy(text),
        }
      );
    });

    const heros = panelsAndHeros.filter(panel => isHero.test(panel.name));
    const group2Panels = panelsAndHeros.filter(panel => isPanel.test(panel.name));
    const group1Panels = group2Panels.splice(0, 4);

    return ({
      ...fields,
      gallery,
      group1Panels,
      group2Panels,
      copy,
      heros,
    });
  });
};

export {
  dataTransformer,
};
