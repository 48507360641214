import { defineMessages } from 'react-intl';

const prefix = 'product-overview';

export const PRODUCT = defineMessages({
  SHADOW_ACCOUNT_WATCHER_FULL_TEXT: {
    id: `${prefix}.shadow-account-watcher.full-text`,
    description: 'text for shadow account watcher',
    defaultMessage: 'Find accounts that you may not even know about with our {highlight}.',
  },
  SHADOW_ACCOUNT_WATCHER_LABEL: {
    id: `${prefix}.shadow-account-watcher.label`,
    description: 'shadow account watcher label',
    defaultMessage: 'Shadow Account Watcher',
  },
  SHADOW_ACCOUNT_WATCHER_LINK: {
    id: `${prefix}.shadow-account-watcher.link`,
    description: 'link text for shadow account watcher',
    defaultMessage: 'Learn about Shadow Accounts',
  },
  DMAP_FULL_TEXT: {
    id: `${prefix}.dmap.full-text`,
    description: 'text for shadow account watcher',
    defaultMessage: 'Not sure what’s running on all your EC2s? We do. Find out about {dataStore} and {dmap}.',
  },
  DMAP_LABEL: {
    id: `${prefix}.dmap.label`,
    description: 'shadow account watcher label',
    defaultMessage: 'DMAP',
  },
  DATA_STORE_FINGERPRINTING_LABEL: {
    id: `${prefix}.data-store.dmap`,
    description: 'shadow account watcher label',
    defaultMessage: 'Data Store fingerprinting',
  },
  DMAP_LINK: {
    id: `${prefix}.data-store.link`,
    description: 'link text for shadow account watcher',
    defaultMessage: 'Learn about DMAP',
  },
  EDITION_COMMUNITY_BUTTON_LEARN: {
    id: `${prefix}.editions.community.button-learn`,
    description: 'Button learn more text for community edition panel',
    defaultMessage: 'Learn more',
  },
  EDITION_COMMUNITY_BUTTON_GET_STARTED: {
    id: `${prefix}.editions.community.button-get-started`,
    description: 'Button get started text for community edition panel',
    defaultMessage: 'Get Started Now',
  },
});
