import { defineMessages } from 'react-intl';

const base = 'no-access.';

export const NO_ACCESS = defineMessages({
  DELETE_ACCOUNT: {
    id: `${base}delete-account`,
    description: 'Delete account link',
    defaultMessage: 'Delete Account',
  },
  NO_ACCESS_INSTALL: {
    id: `${base}no-access-install`,
    description: 'Message telling user they have no access to install',
    defaultMessage: 'Oops. It looks like you don’t have access to install at this time.',
  },
  GET_EMAIL_NOTIFICATION: {
    id: `${base}get-email-notification`,
    description: 'Message telling user to sign up to get email notifications',
    defaultMessage: 'Enter your email address below to get notified when we are no longer in beta.',
  },
});
