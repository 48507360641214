import createReducer from 'rdx/utils/create-reducer';
import types from 'rdx/modules/app/types';

const initialState = {
  form: {
    email: '',
  },
  errors: {
    email: false,
  },
};


const unsubscribeFormReducer = createReducer(initialState, {
  [types.APP_UNSUBSCRIBE_FORM_SET_FIELD](state, action) {
    return {
      ...state,
      form: {
        ...state.form,
        [action.payload.field]: action.payload.value,
      },
    };
  },
  [types.APP_UNSUBSCRIBE_FORM_SET_ERRORS](state, action) {
    return {
      ...state,
      errors: action.payload,
    };
  },
  [types.APP_RESET_UNSUBSCRIBE_FORM]() {
    return initialState;
  },
});

export {
  unsubscribeFormReducer,
};
