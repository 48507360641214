import { defineMessages } from 'react-intl';

const prefix = 'connectors-landing';

const CONNECTORS_LANDING = defineMessages({
  HEADER_TITLE: {
    id: `${prefix}.header-title`,
    description: 'title for the connectors header',
    defaultMessage: 'connectors',
  },
  HEADER_SUBTITLE: {
    id: `${prefix}.header-subtitle`,
    description: 'subtitle for the connectors header',
    defaultMessage: 'Open Raven Connectors',
  },
  FEATURED_LABEL: {
    id: `${prefix}.featured-label`,
    description: 'tag label for featured connectors',
    defaultMessage: 'featured',
  },
  SEARCH_PLACEHOLDER: {
    id: `${prefix}.search-placeholder`,
    description: 'placeholder for search input',
    defaultMessage: 'Search Connectors',
  },
  SORT_HEADER: {
    id: `${prefix}.sort-header`,
    description: 'header for sort section',
    defaultMessage: 'Sort apps by',
  },
  FILTER_HEADER: {
    id: `${prefix}.filter-header`,
    description: 'header for filter section',
    defaultMessage: 'Filter apps by',
  },
  FILTER_ALL: {
    id: `${prefix}.filter.all`,
    description: 'filter label for all',
    defaultMessage: 'All',
  },
  FILTER_ACTIONS: {
    id: `${prefix}.filter.actions`,
    description: 'filter label for actions',
    defaultMessage: 'Actions',
  },
  FILTER_GRAPH_INGEST: {
    id: `${prefix}.filter.graph-ingest`,
    description: 'filter label for graph ingest',
    defaultMessage: 'Graph Ingest',
  },
  FILTER_TICKETING: {
    id: `${prefix}.filter.ticketing`,
    description: 'filter label for ticketing',
    defaultMessage: 'Ticketing',
  },
  SORT_MOST_POPULAR: {
    id: `${prefix}.sort.most-popular`,
    description: 'label for sort by most popular',
    defaultMessage: 'Most Popular',
  },
  SORT_A_Z: {
    id: `${prefix}.sort.a-z`,
    description: 'label for sort by A-Z',
    defaultMessage: 'A-Zs',
  },
});

export {
  CONNECTORS_LANDING,
};
