import React from 'react';
import env from '@beam-australia/react-env';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { INLINES } from '@contentful/rich-text-types';

const contentful = require('contentful'); // not ES6

const SPACE_ID = env('SPACE_ID') || process.env['REACT_APP_SPACE_ID'];
const ACCESS_TOKEN = env('ACCESS_TOKEN') || process.env['REACT_APP_ACCESS_TOKEN'];
const PREVIEW_ACCESS_TOKEN = env('PREVIEW_ACCESS_TOKEN') || process.env['REACT_APP_PREVIEW_ACCESS_TOKEN'];
const PREVIEW_HOST = env('PREVIEW_HOST') || process.env['REACT_APP_PREVIEW_HOST'];
const isStaging = /staging\.openraven\.com/.test(window.location.hostname);

const CustomComponent = ({ title, url }) => (
  <a
    href={url}
    target="_blank"
    rel="noreferrer"
  >
    {title}
  </a>
);

const options = {
  renderNode: {
    [INLINES.ASSET_HYPERLINK]: (node) => {
      const { title, file: { url } } = node.data.target.fields;
      return <CustomComponent title={title} url={url} />;
    },
    'embedded-asset-block': (node) => {
      const { file: { url }, title } = node.data.target.fields;
      return <div className="image-holder"><img src={url} alt={title} /></div>;
    },
  },
};

let productionClient;
let previewClient;

try {
  productionClient = contentful.createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: SPACE_ID,
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: ACCESS_TOKEN,
  });

  previewClient = contentful.createClient({
    space: SPACE_ID,
    accessToken: PREVIEW_ACCESS_TOKEN,
    host: PREVIEW_HOST,
  });
} catch (error) {
  console.error('There was an error', error);
}

const client = isStaging ? previewClient : productionClient;

// Load all entries for a given Content Type from Contentful
function fetchEntriesForContentType(contentType) {
  return client.getEntries({
    content_type: contentType,
  })
    .then(response => response.items);
}

// Load all Content Types in your space from Contentful
const fetchContentTypes = () => (client.getContentTypes()
  .then(response => response.items)
);

const fetchPlatformDetail = ({ entryId, contentType, ...rest }) => {
  // client.getEntry(entryId).then(entry => entry)
  // must use getEntries to include all links.
  // https://github.com/contentful/contentful.js/issues/95

  const entryOptions = {
    'include': 5,
    'sys.id': entryId,
    'content_type': contentType,
    ...rest,
  };
  return client.getEntries(entryOptions).then(response => response?.items?.[0]);
};

const unpackCopy = copy => documentToReactComponents(copy, options);
const unpackCopyToReactComponent = unpackCopy;

const unpackCopyToPlainText = copy => documentToPlainTextString(copy);

const unpackImage = image => image?.fields?.file?.url;

const fetchEntry = entryId => (
  // client.getEntry(entryId).then(entry => entry)
  // must use getEntries to include all links.
  // https://github.com/contentful/contentful.js/issues/95

  client.getEntries({
    'include': 5,
    'sys.id': entryId,
  }).then(response => response?.items?.[0])
);

const fetch = (contentType, callback = response => response) => {
  try {
    return fetchEntriesForContentType(contentType).then(callback).catch((error) => {
      console.error(`An error occured when fetching the ${contentType}`, error);
    });
  } catch (error) {
    console.error(`An error occured when fetching the ${contentType}`, error);
    return Promise.resolve([]);
  }
};

const fetchWhatWeDiscover = async () => {
  try {
    const tags = fetchEntriesForContentType('tag').then(response => response).catch((error) => {
      console.error('An error occured when fetching the tags', error);
    });
    const services = fetchEntriesForContentType('services').then(response => response).catch((error) => {
      console.error('An error occured when fetching the services', error);
    });
    return Promise.all([tags, services]);
  } catch (error) {
    console.error('An error occured while fetching What we Discover', error);
    return [[], [], []];
  }
};

const fetchMediaItems = async () => fetch('mediaItem');

const fetchResourceLibraryPage = async () => fetch('resourceLibraryPage', response => response?.[0]?.fields?.mediaItems);

const fetchMediaKitPage = async () => fetch('mediaKitPage');

const fetchNewsItems = async () => fetch('newsItem');

// can be used to help find a panel within the contentful response data with a matching name or title.
const findCmsPanelByField = (data = [], panelName, fieldName = 'name') => {
  if (!data.length) {
    return {};
  }
  return data?.find(panel => (panel?.fields[`${fieldName}`])?.toLowerCase() === panelName.toLowerCase());
};


// use this to grab all testimonials from cms response
const parseTestimonialsFromCmsData = (cmsData) => {
  if (!cmsData.length) {
    return [];
  }
  return cmsData?.filter(datum => (datum?.fields?.author && datum?.fields?.quote && datum?.fields?.authorsProfession)
  || (datum?.fields?.author && datum?.fields?.extendedQuote && datum?.fields?.authorsProfession)
  );
};

// use this to format unordered list data from cms panels copy section
const parseUnorderedList = cmsListData => cmsListData?.map(listItem => listItem?.content?.[0].content?.[0].value);

// use this to format resource panel data
const formatResourcePanels = (data = {}) => data.map(resource => ({
  copy: unpackCopy(resource?.fields?.copy),
  headline: resource?.fields?.headline,
  name: resource?.fields?.name,
}));

export {
  fetchContentTypes,
  fetchWhatWeDiscover,
  fetchMediaKitPage,
  fetchPlatformDetail,
  fetchMediaItems,
  fetchNewsItems,
  fetchResourceLibraryPage,
  fetchEntry,
  unpackCopy,
  unpackCopyToReactComponent,
  unpackImage,
  unpackCopyToPlainText,
  findCmsPanelByField,
  parseTestimonialsFromCmsData,
  parseUnorderedList,
  formatResourcePanels,
};
