import { TRANSLATION_KEYS } from 'translations/keys';

const {
  ACCOUNT_MANAGEMENT: {
    PROFILE_SETTINGS: profileSettings,
    CHANGE_PASSWORD: changePassword,
  },
} = TRANSLATION_KEYS;

const BASE_ROUTE = 'account-management';

const ROUTES = {
  BASE: BASE_ROUTE,
  MY_WORKSPACES: `/${BASE_ROUTE}/my-workspaces`,
  PROFILE_SETTINGS: `/${BASE_ROUTE}/profile-settings`,
  CHANGE_PASSWORD: `/${BASE_ROUTE}/change-password`,

};

const ACCOUNT_MANAGEMENT_TABS = [
  {
    key: 'MY_WORKSPACES',
    to: ROUTES.MY_WORKSPACES,
    label: 'My Workspaces',
  },
];

const PROFILE_TABS = [
  {
    key: 'PROFILE_SETTINGS',
    to: ROUTES.PROFILE_SETTINGS,
    label: profileSettings,
  },
  {
    key: 'CHANGE_PASSWORD',
    to: ROUTES.CHANGE_PASSWORD,
    label: changePassword,
  },
];


const [
  PROFILE_SETTINGS,
  CHANGE_PASSWORD,
  MY_WORKSPACES,
] = ACCOUNT_MANAGEMENT_TABS.map(({ name }) => name);

export {
  ACCOUNT_MANAGEMENT_TABS,
  PROFILE_TABS,
  PROFILE_SETTINGS,
  MY_WORKSPACES,
  CHANGE_PASSWORD,
  ROUTES,
};
