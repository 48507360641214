import React from 'react';
import {
  TwitterIconWhite,
  LinkedInIconWhite,
  FacebookIconWhite,
  InstagramIconWhite,
  OctocatIconWhite,
} from 'assets/images';
import { getClassNameBuilder } from 'lib/utils/get-classname-builder';
import './social-icons.scss';


const { baseClass, getClassName } = getClassNameBuilder('social-media-links');

const SOCIALS = [
  {
    Icon: TwitterIconWhite,
    link: 'https://twitter.com/openraven',
  },
  {
    Icon: LinkedInIconWhite,
    link: 'https://www.linkedin.com/company/open-raven',
  },
  {
    Icon: FacebookIconWhite,
    link: 'https://www.facebook.com/openraven/',
  },
  {
    Icon: InstagramIconWhite,
    link: 'https://www.instagram.com/open.raven/',
  },
  {
    Icon: OctocatIconWhite,
    link: 'https://github.com/openraven/',
  },
];

const SocialLink = ({
  Icon,
  link,
}) => (
  <a
    className={getClassName('link')}
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Icon />
  </a>
);

const SocialIcons = () => (
  <div className={baseClass}>
    {SOCIALS.map((props, i) => <SocialLink key={i} {...props} />)}
  </div>
);

export {
  SocialIcons,
};
