import { defineMessages } from 'react-intl';

const base = 'account-management.';

export const ACCOUNT_MANAGEMENT = defineMessages({
  ADD_ORGANIZATION: {
    id: `${base}add-organization`,
    description: 'Add workspace text',
    defaultMessage: 'Add Workspace',
  },
  BEFORE_YOU_GO: {
    id: `${base}before-you-go`,
    description: 'Before you go header for deleting account',
    defaultMessage: 'Before you go...',
  },
  DELETING_TITLE: {
    id: `${base}deleting-title`,
    description: 'Deleting title header for deleting account',
    defaultMessage: 'To delete, type in your email',
  },
  NO_LONGER_RECEIVE_NOTIFICATIONS: {
    id: `${base}no-longer-receive-notifications`,
    description: 'Sentence for stopping receipt of notifications',
    defaultMessage: 'If you no longer want to receive emails or notifications, you can',
  },
  NO_LONGER_RECEIVE_NOTIFICATIONS_LINK: {
    id: `${base}no-longer-receive-messages-link`,
    description: 'Link showing you where to stop notifications',
    defaultMessage: 'disable them here',
  },
  DELETE_MY_ACCOUNT_BUTTON: {
    id: `${base}delete-my-account-button`,
    description: 'Button text for deleting account',
    defaultMessage: 'Delete my account',
  },
  DELETE_MY_ACCOUNT_LOGOUT_FAILED: {
    id: `${base}delete-my-account-logout-failed`,
    description: 'Logout failed while trying to delete account',
    defaultMessage: 'Deletion failed. Try refreshing and logging in again before attempting to delete your account.',
  },
  BLOG_POSTS: {
    id: `${base}blog-posts`,
    description: 'Blog posts option label',
    defaultMessage: 'Blog Posts',
  },
  BLOG_POSTS_DESCRIPTION: {
    id: `${base}blog-posts-description`,
    description: 'Blog posts option description',
    defaultMessage: 'Do you want blog posts via email? Subscribe to stay up-to-date.',
  },
  BUTTON_CREATE_ORG: {
    id: `${base}button-create-org`,
    description: 'Button text for creating a new workspace',
    defaultMessage: 'Create New Workspace',
  },
  CHANGE_PASSWORD: {
    id: `${base}change-password`,
    description: 'Change Password tab title',
    defaultMessage: 'Change Password',
  },
  CHOOSE_AVATAR: {
    id: `${base}choose-avatar`,
    description: 'Choose user avatar',
    defaultMessage: 'Choose an avatar',
  },
  COMMUNITY_SLACK: {
    id: `${base}community-slack`,
    description: 'Community slack option label',
    defaultMessage: 'Community Slack',
  },
  COMMUNITY_SLACK_DESCRIPTION: {
    id: `${base}community-slack-description`,
    description: 'Community slack option description',
    defaultMessage: 'Join our community slack to discuss all things Open Raven with our growing community of users.',
  },
  COMPANY_NEWSLETTER: {
    id: `${base}company-newsletter`,
    description: 'Company newsletter option label',
    defaultMessage: 'Company Newsletter',
  },
  COMPANY_NEWSLETTER_DESCRIPTION: {
    id: `${base}company-newsletter-description`,
    description: 'Company newsletter description',
    defaultMessage: 'Subscribe to our company newsletter to stay informed about industry updates, and company news.',
  },
  DELETE_ACCOUNT: {
    id: `${base}delete-account`,
    description: 'Delete account link',
    defaultMessage: 'Delete Account',
  },
  DELETE_ACCOUNT_WARNING: {
    id: `${base}delete-account-warning`,
    description: 'Warning for account deletion',
    defaultMessage: 'Once you delete it is final. There is no way to restore or recover your account.',
  },
  DELETE_ACCOUNT_ORPHAN_ORG: {
    id: `${base}delete-account-orphan-org`,
    description: 'Prompt that you must delete your orgs first',
    defaultMessage: 'To delete this account, you must first delete or transfer ownership of workspaces you own.',
  },
  DEV_SLACK: {
    id: `${base}dev-slack`,
    description: 'Dev slack option label',
    defaultMessage: 'Developer Slack',
  },
  DEV_SLACK_DESCRIPTION: {
    id: `${base}dev-slack-description`,
    description: 'Dev slack description',
    defaultMessage: 'Talk shop with other developers tinkering with Open Raven.',
  },
  ERROR_INCORRECT_OLD_PASSWORD: {
    id: `${base}error-incorrect-old-password`,
    description: 'Invalid Old Password text',
    defaultMessage: 'Current password is incorrect.',
  },
  ERROR_UPDATING_MARKETING_PREFERENCES: {
    id: `${base}error-updating-marketing-preferences`,
    description: 'Error updating marketing preferences text',
    defaultMessage: 'Error updating marketing preferences, please try again.',
  },
  ERROR_UPDATING_PROFILE: {
    id: `${base}error-updating-profile`,
    description: 'Error updating profile text',
    defaultMessage: 'Error updating profile, please try again.',
  },
  FIELD_UPDATED_SUCCESSFULLY: {
    id: `${base}field-updated-successfully`,
    description: 'Field updated text',
    defaultMessage: 'Your {field} has been successfully updated.',
  },
  KEEP_MY_ACCOUNT_BUTTON: {
    id: `${base}keep-my-account-button`,
    description: 'Button text for keeping account',
    defaultMessage: 'Keep my account',
  },
  MAILING_LISTS: {
    id: `${base}mailing-lists`,
    description: 'Mailing lists account options',
    defaultMessage: 'Mailing Lists',
  },
  MARKETING_PREFERENCES: {
    id: `${base}marketing-preferences`,
    description: 'Marketing Preferences tab title',
    defaultMessage: 'Marketing Preferences',
  },
  MARKETING_PREFERENCES_UPDATE_SUCCESS: {
    id: `${base}marketing-preferences-update-success`,
    description: 'Marketing Preferences update success',
    defaultMessage: 'Your marketing preferences have been updated.',
  },
  MARKETING_PREFERENCES_FIELD_UNSUBSCRIBED: {
    id: `${base}marketing-preferences-field-unsubscribed`,
    description: 'Marketing Preferences field unsubscribed',
    defaultMessage: 'You have unsubscribed from the {field}.',
  },
  MARKETING_PREFERENCES_FIELD_SUBSCRIBED: {
    id: `${base}marketing-preferences-field-subscribed`,
    description: 'Marketing Preferences field subscribed',
    defaultMessage: 'You are now subscribed to the {field}.',
  },
  MODAL_ORG_CREATE_CLOSE: {
    id: `${base}modal-org-create-close`,
    description: 'Close button of the confirmation model to create org',
    defaultMessage: 'Cancel',
  },
  MODAL_ORG_CREATE_CONFIRM: {
    id: `${base}modal-org-create-confirm`,
    description: 'Confirm button of the confirmation model to create org',
    defaultMessage: 'OK, lets go!',
  },
  MODAL_ORG_CREATE_DESCRIPTION: {
    id: `${base}modal-org-create-description`,
    description: 'Description of the confirmation model to create org',
    defaultMessage: 'Ready to create a new workspace? You’ll be taken to the Add New Workspace flow.',
  },
  MODAL_ORG_CREATE_TITLE: {
    id: `${base}modal-org-create-title`,
    description: 'Title of the confirmation model to create org',
    defaultMessage: 'Deploy Open Raven to a New Org',
  },
  MODAL_ORG_DELETE_CONFIRM_TITLE: {
    id: `${base}modal-org-delete-confirm-title`,
    description: 'Title of the confirm model to delete org',
    defaultMessage: 'To delete {orgName}, type in your workspace name',
  },
  MODAL_ORG_DELETE_CLOSE: {
    id: `${base}modal-org-delete-close`,
    description: 'Close button of the confirmation model to delete org',
    defaultMessage: 'Delete this Workspace',
  },
  MODAL_ORG_DELETE_CONFIRM: {
    id: `${base}modal-org-delete-confirm`,
    description: 'Confirm button of the confirmation model to delete org',
    defaultMessage: 'Keep this Workspace',
  },
  MODAL_ORG_DELETE_DESCRIPTION: {
    id: `${base}modal-org-delete-description`,
    description: 'Description of the confirmation model to delete org',
    defaultMessage: 'Workspaces can only be deleted after all other users have been removed. Once you delete this workspace, it is final. There is no way to restore this org other than to create a new workspace.',
  },
  MODAL_ORG_DELETE_TITLE: {
    id: `${base}modal-org-delete-title`,
    description: 'Title of the confirmation model to delete org',
    defaultMessage: 'Are you sure you want to delete {orgName}?',
  },

  MODAL_ORG_LEAVE_CONFIRM_TITLE: {
    id: `${base}modal-org-leave-confirm-title`,
    description: 'Title of the confirm model to leave org',
    defaultMessage: 'To leave {orgName}, type in your workspace name',
  },
  MODAL_ORG_LEAVE_CLOSE: {
    id: `${base}modal-org-leave-close`,
    description: 'Close button of the confirmation model to leave org',
    defaultMessage: 'Leave this Workspace',
  },
  MODAL_ORG_LEAVE_CONFIRM: {
    id: `${base}modal-org-leave-confirm`,
    description: 'Confirm button of the confirmation model to leave org',
    defaultMessage: 'Stay in this Workspace',
  },
  MODAL_ORG_LEAVE_DESCRIPTION: {
    id: `${base}modal-org-leave-description`,
    description: 'Description of the confirmation model to leave org',
    defaultMessage: 'Once you leave this workspace, you must be reinvited by the admin in order to rejoin. Are you sure you want to leave?',
  },
  MODAL_ORG_LEAVE_TITLE: {
    id: `${base}modal-org-leave-title`,
    description: 'Title of the confirmation model to leave org',
    defaultMessage: 'Are you sure you want to leave {orgName}?',
  },
  MODAL_ORG_WARNING_DESCRIPTION: {
    id: `${base}modal-org-warning-description`,
    description: 'Description of the warning model',
    defaultMessage: 'Please delete an workspace before adding a new one.',
  },
  MODAL_ORG_WARNING_TITLE: {
    id: `${base}modal-org-warning-title`,
    description: 'Title of the warning model',
    defaultMessage: 'You have exceeded your limit of 5 workspaces.',
  },
  MY_ORGANIZATIONS: {
    id: `${base}my-organizations`,
    description: 'My Workspace tab title',
    defaultMessage: 'My Workspaces',
  },
  MY_ORG_FETCH_FAIL: {
    id: `${base}my-org-fetch-fail`,
    description: 'Fetch workspaces fail text',
    defaultMessage: 'Failed to fetch workspaces. Please try again.',
  },
  MY_ORG_DELETE_FAIL: {
    id: `${base}my-org-delete-fail`,
    description: 'Delete workspace fail text',
    defaultMessage: 'Failed to delete ‘{orgName}’. Please try again.',
  },
  MY_ORG_DELETE_SUCCESS: {
    id: `${base}my-org-delete-success`,
    description: 'Delete workspace success text',
    defaultMessage: 'The workspace ‘{orgName}’ has been deleted.',
  },
  MY_ORG_LEAVE_FAIL: {
    id: `${base}my-org-leave-fail`,
    description: 'Leave workspace fail text',
    defaultMessage: 'Failed to leave ‘{orgName}’. Please try again.',
  },
  MY_ORG_LEAVE_SUCCESS: {
    id: `${base}my-org-leave-success`,
    description: 'Leave workspace success text',
    defaultMessage: 'You have left the workspace ‘{orgName}’.',
  },
  OPTION_DELETE_ORG: {
    id: `${base}option-delete-org`,
    description: 'Table action name for Workspace Deletion',
    defaultMessage: 'Delete Workspace',
  },
  ORG_SEARCH_PLACEHOLDER: {
    id: `${base}org-search-placeholder`,
    description: 'Search input placeholder for the Workspace search',
    defaultMessage: 'Search Workspaces',
  },
  ORG_SEARCH_RESULT: {
    id: `${base}org-search-result`,
    description: 'Search input result text for the Workspace search',
    defaultMessage: '{count} Active {count, plural, one {Organization} other {Organizations}}',
  },
  OPTION_LEAVE_ORG: {
    id: `${base}option-leave-org`,
    description: 'Table action name for Workspace Leave',
    defaultMessage: 'Leave Workspace',
  },
  PASSWORD_SUCCESSFULLY_CHANGED: {
    id: `${base}password-successfully-changed`,
    description: 'Password successfully changed message',
    defaultMessage: 'Your password has been successfully changed',
  },
  PRESET_AVATAR_STEP1: {
    id: `${base}preset-avatar-step1`,
    description: 'Preset avatar step 1 description',
    defaultMessage: '1. Pick a color',
  },
  PRESET_AVATAR_STEP2: {
    id: `${base}preset-avatar-step2`,
    description: 'Preset avatar step 2 description',
    defaultMessage: '2. Choose a raven',
  },
  RELEASE_NOTIFICATIONS: {
    id: `${base}release-notifications`,
    description: 'Release newsletter option label',
    defaultMessage: 'Release Notifications',
  },
  RELEASE_NOTIFICATIONS_DESCRIPTION: {
    id: `${base}release-notifications-description`,
    description: 'Release newseltter description',
    defaultMessage: 'Subscribe to our product release notifications for the latest info about bug fixes, features, and releases.',
  },
  PROFILE_SETTINGS: {
    id: `${base}profile-settings`,
    description: 'Profile Settings tab title',
    defaultMessage: 'Profile Settings',
  },
  PROFILE_SETTINGS_UPDATED: {
    id: `${base}profile-settings-updated`,
    description: 'Profile settings updated text',
    defaultMessage: 'Your profile has been successfully updated.',
  },
  SLACK_CHANNELS: {
    id: `${base}slack-channels`,
    description: 'Slack channels option label',
    defaultMessage: 'Slack Channels',
  },
  TABLE_COLUMN_HEADER_CREATION_DATE: {
    id: `${base}table-column-header-creation-date`,
    description: 'My Workspaces table column header for Creation Date',
    defaultMessage: 'Creation Date',
  },
  TABLE_COLUMN_HEADER_ORG_NAME: {
    id: `${base}table-column-header-org-name`,
    description: 'My Workspaces table column header for Workspace Name',
    defaultMessage: 'Workspace Name',
  },
  TABLE_COLUMN_HEADER_ORG_TYPE: {
    id: `${base}table-column-header-org-type`,
    description: 'My Workspaces table column header for Workspace Type',
    defaultMessage: 'Workspace Type',
  },
  TABLE_COLUMN_HEADER_ROLE: {
    id: `${base}table-column-header-role`,
    description: 'My Workspaces table column header for Role',
    defaultMessage: 'Role',
  },
  TABLE_COLUMN_HEADER_STATUS: {
    id: `${base}table-column-header-status`,
    description: 'My Workspaces table column header for Status',
    defaultMessage: 'Status',
  },
  UPLOAD_PHOTO: {
    id: `${base}upload-photo`,
    description: 'Title for uploading user avatar photo',
    defaultMessage: 'Upload new avatar photo',
  },
  UPLOAD_PHOTO_INSTRUCTION: {
    id: `${base}upload-photo-instruction`,
    description: 'Instructions for uploading user avatar photo',
    defaultMessage: 'Drag and drop to upload',
  },
  UPLOAD_PHOTO_RESTRICTIONS: {
    id: `${base}upload-photo-restrictions`,
    description: 'Restrictions on user photo uploads',
    defaultMessage: '.jpg .png, Max file size 1MB',
  },
});
