import { defineMessages } from 'react-intl';

const prefix = 'contact';

const CONTACT = defineMessages({
  TEXT_CONTACT_US: {
    id: `${prefix}.text.contact-us`,
    description: 'title for the title box',
    defaultMessage: 'contact us',
  },
  TITLE_BOX_DESCRIPTION: {
    id: `${prefix}.title-box-description`,
    description: 'detail text for the title box',
    defaultMessage: 'Get in touch',
  },
  LINK_GO_TO_SUPPORT_PAGE: {
    id: `${prefix}.link.go-to-support-page`,
    description: 'text for the support link',
    defaultMessage: 'Go to Support Page',
  },
  LINK_DOCUMENTATION: {
    id: `${prefix}.link.documentation`,
    description: 'documentation link text',
    defaultMessage: 'Documentation',
  },
  LINK_COMMUNITY_SUPPORT: {
    id: `${prefix}.link.community-support`,
    description: 'community support link text',
    defaultMessage: 'Community Support',
  },
  LABEL_GENERAL: {
    id: `${prefix}.label.general`,
    description: 'label for general email address',
    defaultMessage: 'General',
  },
  LABEL_CAREERS: {
    id: `${prefix}.label.careers`,
    description: 'label for careers email address',
    defaultMessage: 'Careers',
  },
  LABEL_PRESS: {
    id: `${prefix}.label.press`,
    description: 'label for press email address',
    defaultMessage: 'Press',
  },
  LABEL_SALES: {
    id: `${prefix}.label.sales`,
    description: 'label for sales email address',
    defaultMessage: 'Sales',
  },
  LABEL_PHONE: {
    id: `${prefix}.label.phone`,
    description: 'label for phone number',
    defaultMessage: 'Phone',
  },
  LABEL_ADDRESS: {
    id: `${prefix}.label.address`,
    description: 'label for physical address',
    defaultMessage: 'Address',
  },
  LABEL_SUPPORT: {
    id: `${prefix}.label.support`,
    description: 'label for support link',
    defaultMessage: 'Support',
  },
});


export {
  CONTACT,
};
