import React from 'react';
import { getRdxSelectionMapper, getRdxActionMapper } from 'rdx/utils/props-mapping';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ErrorToast as ErrorToastComponent } from '@openraven/react-styleguide';

const ErrorToastView = ({ error, toggleErrorVisibility }) => (
  <ErrorToastComponent
    className="error-toast"
    visible={error.visible}
    toggleVisible={toggleErrorVisibility}
    description={error.title}
    detailedBreakdown={error.details}
  />
);

const stateMapper = getRdxSelectionMapper({
  error: 'getError',
});

const actionMapper = getRdxActionMapper([
  'toggleErrorVisibility',
]);

ErrorToastView.propTypes = {
  error: PropTypes.shape({
    title: PropTypes.string,
    details: PropTypes.string,
    visible: PropTypes.bool,
  }).isRequired,
  toggleErrorVisibility: PropTypes.func.isRequired,
};

ErrorToastView.defaultProps = {
};

const ErrorToast = connect(
  stateMapper,
  actionMapper,
)((ErrorToastView));

export {
  ErrorToast,
};
