import { mapDomain } from 'lib/utils/map-domain';
import get from 'lodash/get';

const formatDomain = (members, attribute = 'image') => (
  members.map(member => ({
    ...member,
    [attribute]: mapDomain(get(member, [attribute, 'url'], '')),
  }))
);

const dataTransformer = (payload) => {
  if (!Array.isArray(payload)) {
    return [];
  }

  return (
    payload.map(({
      hero,
      panel,
      principles,
      ...data
    }) => ({
      ...data,
      hero: formatDomain(hero),
      panel: formatDomain(panel, 'backgroundImage'),
      principles: formatDomain(principles, 'backgroundImage'),
    }))
  );
};

export {
  dataTransformer,
};
