import { blogConstants } from 'constants';

const { defaultTag } = blogConstants;

const dataTransformer = (payload) => {
  if (!Array.isArray(payload)) {
    return [];
  }

  const results = payload.map(tag => ({
    ...tag.fields,
  }));

  results.unshift(defaultTag);

  return results;
};

export {
  dataTransformer,
};
