import apiSelectors from 'rdx/modules/api/selectors';
import appSelectors from 'rdx/modules/app/selectors';
import localeSelectors from 'rdx/modules/locale/selectors';
import authSelectors from 'rdx/modules/auth/selectors';
import messageSelectors from 'rdx/modules/messages/selectors';
// IMPORT_PT (for script -- do not remove!)

const selectors = {
  ...apiSelectors,
  ...appSelectors,
  ...localeSelectors,
  ...authSelectors,
  ...messageSelectors,
// INSERTION_PT (for script -- do not remove!)
};

export default selectors;
