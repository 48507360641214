import { defineMessages } from 'react-intl';

export const BLOG = defineMessages({
  DIRECT_LINK: {
    id: 'blog.direct-link',
    description: 'Button text for getting direct link to blog post',
    defaultMessage: 'Direct Link',
  },
  DIRECT_LINK_CLICK: {
    id: 'blog.direct-link.tip',
    description: 'Tool tip text',
    defaultMessage: 'Click to copy direct link to clipboard',
  },
  REPLY: {
    id: 'blog.reply',
    description: 'Link text for replying to a blog post/comment',
    defaultMessage: 'Reply',
  },
  ADD_COMMENT: {
    id: 'blog.add-comment',
    description: 'Placeholder for comment textarea',
    defaultMessage: 'Add your comment...',
  },
  SUBMIT_COMMENT: {
    id: 'blog.submit-comment',
    description: 'Button text for submitting blog comment',
    defaultMessage: 'Submit Comment',
  },
  COMMENTS: {
    id: 'blog.comments',
    description: 'Comments header title',
    defaultMessage: 'Comments',
  },
  BLOG_AUTHOR_DATE: {
    id: 'blog.author-date',
    description: 'Blog author and date format',
    defaultMessage: '{authorName} on {dateString}',
  },
  BLOG_LIST_TITLE: {
    id: 'blog.list-title',
    description: 'Blog title for list page',
    defaultMessage: 'BLOG',
  },
  BLOG_LIST_DESCRIPTION: {
    id: 'blog.list-description',
    description: 'Blog description for list page',
    defaultMessage: 'Our thoughts',
  },
  SEARCH_BLOG: {
    id: 'blog.search-blog',
    description: 'Placeholder text for search bar',
    defaultMessage: 'Search blog',

  },
  EMAIL_UPDATE: {
    id: 'blog.email-update',
    description: 'Text describing email updates',
    defaultMessage: 'Stay updated via email',

  },
  RSS_FEED: {
    id: 'blog.rss-feed',
    description: 'Text describing RSS feed updates',
    defaultMessage: 'Add to RSS feed',
  },
});
