import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import env from '@beam-australia/react-env';
import { withScope, captureException } from '@sentry/browser';
import { errorImage } from 'assets/images';
import './error-boundary.scss';

const NODE_ENV = env('NODE_ENV');

export const ErrorBoundaryScreen = () => (
  <div className="error-boundary-screen">
    <img
      className="error-boundary-screen__image"
      src={errorImage}
      alt="error"
    />
    <div className="error-boundary-screen__error-text">
      There was an error loading this page. Please clear your cache and cookies then refresh the page.
    </div>
    <button
      style={{
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '10px',
      }}
      onClick={() => { window.location.reload(true); }}
    >
      Refresh
    </button>
  </div>
);

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      errorStack: '',
    };
  }

  componentDidCatch(error, errorInfo) {
    if (NODE_ENV === 'production') {
      withScope((scope) => {
        scope.setExtras(errorInfo);
        captureException(error);
      });
    }

    this.setState({
      error,
      errorStack: errorInfo.componentStack,
    });
  }

  render() {
    const { error, errorStack } = this.state;

    if (error) {
      return (
        <ErrorBoundaryScreen
          error={error.toString()}
          errorStack={errorStack}
        />
      );
    }

    return this.props.children;
  }
}

// ErrorBoundaryScreen.propTypes = {
//   error: PropTypes.string,
//   errorStack: PropTypes.string,
// };

// ErrorBoundaryScreen.defaultProps = {
//   error: '',
//   errorStack: '',
// };
