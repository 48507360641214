// fetch() polyfill for making API calls.
require('core-js');
require('regenerator-runtime/runtime');
require('core-js/stable/object/assign');
require('core-js/stable/promise');
require('fastestsmallesttextencoderdecoder');
require('blueimp-canvas-to-blob');

// require('whatwg-fetch');

// if (typeof TextDecoder === 'undefined' || typeof TextEncoder === 'undefined') {
//   const { TextDecoder, TextEncoder } = require('text-encoding');

//   global.TextDecoder = TextDecoder;
//   global.TextEncoder = TextEncoder;
// }

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
  require('raf').polyfill(global);
}

if (!String.prototype.repeat) {
  /* eslint-disable-next-line */
  String.prototype.repeat = function repeat(count) {
    let str = this.toString();
    // To convert string to integer.
    count = +count;
    // Check NaN
    /* eslint-disable-next-line */
    if (isNaN(count)) {
      count = 0;
    }

    if (count < 0) {
      throw new RangeError('repeat count must be non-negative');
    }

    if (count === Infinity) {
      throw new RangeError('repeat count must be less than infinity');
    }

    count = Math.floor(count);
    if (str.length === 0 || count === 0) {
      return '';
    }

    // Ensuring count is a 31-bit integer allows us to heavily optimize the
    // main part. But anyway, most current (August 2014) browsers can't handle
    // strings 1 << 28 chars or longer, so:
    /* eslint-disable-next-line */
    if (str.length * count >= 1 << 28) {
      throw new RangeError('repeat count must not overflow maximum string size');
    }

    const maxCount = str.length * count;
    count = Math.floor(Math.log(count) / Math.log(2));
    while (count) {
      str += str;
      count -= 1;
    }
    str += str.substring(0, maxCount - str.length);
    return str;
  };
}
