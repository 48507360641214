import { LOCAL_STORAGE_KEY } from 'config/constants';
import { ROUTE } from 'containers/root-router/route-constants';

const getDemoButtonConfig = ({ isLoggedIn, onClick, isOnAdPage = false }) => {
  try {
    const demoState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.DEMO_STATE));

    if (location.pathname === ROUTE.VIEW_DEMO && isLoggedIn) { // eslint-disable-line no-restricted-globals
      return {
        showRequestInstallModal: true,
        label: 'Request Demo',
      };
    }

    if (demoState && demoState.selectedDemoTime) {
      return {
        to: ROUTE.CONFIRM_DEMO,
        label: 'Request Demo',
      };
    }

    if (demoState && demoState.demoCompleted) {
      return {
        to: ROUTE.INSTALL,
        label: 'Request Demo',
      };
    }

    if (isOnAdPage) {
      return {
        to: ROUTE.DEMO_PAGE,
        onClick,
        label: 'Request Demo',
        className: 'blue-version',
      };
    }

    return {
      to: ROUTE.DEMO_PAGE,
      onClick,
      label: 'Request Demo',
    };
  } catch (e) { console.error(e); }
};
export {
  getDemoButtonConfig,
};
