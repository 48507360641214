import { defineMessages } from 'react-intl';

export const HEADER = defineMessages({
  INSTALL_BUTTON: {
    id: 'header.install.button',
    description: 'Header install button text',
    defaultMessage: 'Install',
  },
  DEMO: {
    id: 'header.demo',
    description: 'Buttom label for starting demo flow',
    defaultMessage: 'Demo',
  },
  SEARCH_PLACEHOLDER: {
    id: 'styleguide.header.search-placeholder',
    description: 'Placeholder text for search bar in header',
    defaultMessage: 'Search Open Raven',
  },
  INSTALL_MODAL_TEXT: {
    id: 'styleguide.header.request-install-modal-text',
    description: 'Text for request install modal',
    defaultMessage: 'Enter your email address to request an installation and we’ll let you know when it’s available.',
  },
  REQUEST_INSTALLATION: {
    id: 'styleguide.header.request-installation',
    description: 'Request installation button label',
    defaultMessage: 'Request Installation',
  },
  INSTALL_MODAL_INPUT_PLACEHOLDER: {
    id: 'styleguide.header.install-modal-input-placeholder',
    description: 'Placeholder text for email input in modal',
    defaultMessage: 'Enter email',
  },
  INSTALL_REQUESTED_MODAL_HEADER: {
    id: 'styleguide.header.install-requested-modal-header',
    description: 'Header for install requested modal',
    defaultMessage: 'Installation Requested',
  },
  INSTALL_REQUESTED_MODAL_TEXT_RECEIVED: {
    id: 'styleguide.header.install-requested-modal-text-received',
    description: 'Text telling user request was received',
    defaultMessage: 'We’ve received your installation request.',
  },
  INSTALL_REQUESTED_MODAL_TEXT_INBOX: {
    id: 'styleguide.header.install-requested-modal-text-inbox',
    description: 'Text telling user to keep an eye on their inbox',
    defaultMessage: 'Keep an eye on your inbox and we’ll be sending you more information soon.',
  },
  BACK_TO_DEMOS: {
    id: 'styleguide.header.back-to-demos',
    description: 'Label for back to demos button on requested installation modal',
    defaultMessage: 'Back to Demos',
  },
  READY_TO_INSTALL: {
    id: 'styleguide.header.ready-to-install-button',
    description: 'Label for install button on view-demo page while loggeed in',
    defaultMessage: 'Ready to Install?',
  },
  GET_STARTED: {
    id: 'styleguide.header.get-started',
    description: 'Label for install button',
    defaultMessage: 'Get Started',
  },
  MENU_ITEM_ACCOUNT_PREFERENCES: {
    id: 'styleguide.header.menu-item.account-preferences',
    description: 'Text in the Menu Item on the Dropdown Menu in the Header',
    defaultMessage: 'Account Preferences',
  },
  MENU_ITEM_SUPPORT: {
    id: 'styleguide.header.menu-item.support',
    description: 'Text in the Menu Item on the Dropdown Menu in the Header',
    defaultMessage: 'Support',
  },
  MENU_ITEM_COMMUNITY_SLACK: {
    id: 'styleguide.header.menu-item.community-slack',
    description: 'Text in the Menu Item on the Dropdown Menu in the Header',
    defaultMessage: 'Launch Community Slack',
  },
  MENU_ITEM_DEVELOPER_SLACK: {
    id: 'styleguide.header.menu-item.developer-slack',
    description: 'Text in the Menu Item on the Dropdown Menu in the Header',
    defaultMessage: 'Launch Developer\'s Slack',
  },
  MENU_ITEM_SIGN_OUT: {
    id: 'styleguide.header.menu-item.sign-out',
    description: 'Text in the Menu Item on the Dropdown Menu in the Header',
    defaultMessage: 'Sign Out',
  },
  LOGIN: {
    id: 'styleguide.header.login',
    description: 'Header login link text',
    defaultMessage: 'Log in',
  },
  SIGN_UP: {
    id: 'styleguide.header.sign-up',
    description: 'Header sign up link text',
    defaultMessage: 'Sign up',
  },
  PRODUCT: {
    id: 'styleguide.header.product',
    description: 'Product sign up link text',
    defaultMessage: 'Product',
  },
  RESOURCES: {
    id: 'styleguide.header.resources',
    description: 'Resources sign up link text',
    defaultMessage: 'Resources',
  },
  ABOUT: {
    id: 'styleguide.header.about',
    description: 'About sign up link text',
    defaultMessage: 'About',
  },
  CAREERS: {
    id: 'styleguide.header.careers',
    description: 'Careers sign up link text',
    defaultMessage: 'Careers',
  },
  YOUR_WORKSPACES: {
    id: 'styleguide.header.your-organizations',
    description: 'Button to open up a popup with a list of the users organizations',
    defaultMessage: 'Your Workspaces',
  },
  YOUR_WORKSPACES_INACTIVE: {
    id: 'styleguide.header.your-organizations-inactive',
    description: 'Inactive text displayed on tooltip for an inactive organization',
    defaultMessage: 'Inactive',
  },
  YOUR_WORKSPACES_LAUNCH: {
    id: 'styleguide.header.your-organizations-launch',
    description: 'Launch text displayed on the right side of each organization item',
    defaultMessage: 'Launch',
  },
});
