import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import customPropTypes from 'lib/custom-prop-types';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router';
import { Header } from 'components/header';
import { useOktaAuth } from '@okta/okta-react';
// import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import Track from 'lib/utils/tracking';
import { injectIntl } from 'react-intl';
import { usePardotScoreRedirect } from 'lib/hooks';
import { compose } from 'redux';
import { getRdxActionMapper, getRdxSelectionMapper } from 'rdx/utils/props-mapping';
import { testAccountAvatar } from 'assets/images';
import { DEFAULT_POST_LOGIN_ROUTE } from 'lib/api/handle-unauthorized';
import { ROUTES } from 'constants/account-management';
import { NewVersionAvailableProvider } from 'lib/utils/check-version';
// import { hasCreatedAccount, hasLoggedIn } from 'app-cookies';
import { featureFlags, featureDisabled } from 'feature-flags';
import { navigationConfig } from 'containers/root-router/routes';
import { ROUTE as APPLICATION_ROUTES } from 'containers/root-router';
import { getDemoButtonConfig } from 'lib/utils/demo-button';
import { removeLoggedOutCookie, setLoggedOutCookie } from 'config/okta-auth-service';
import { MOBILE_NAVIGATION_CONFIG } from 'components/header/constants';

// Placeholder for avatar when it's in the loading state.
const BLANK_PIXEL = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
const INSTALL_BASE = '/install';
const INSTALL_DEPLOY = `${INSTALL_BASE}/deploy`;
const ADVERTISEMENT_PAGE = APPLICATION_ROUTES.AD_LANDING_PAGE;

const ORG_STATUS_ACTIVE = 'active';
const isOrgActive = ({ status }) => (status || '').toLowerCase() === ORG_STATUS_ACTIVE;

const LOGGED_IN_EMAIL = 'logged-in-email';

const HeaderContainer = ({
  getUserSettings,
  location: {
    pathname,
  },
  emailRequestInstallationData,
  getPardotScore,
  getPardotScoreReset,
  history,
  groupSummaries: { payload: organizations },
  pardotScore,
  postEmailRequestInstallation,
  postGroupSummaries,
  search,
  setSearch,
  userInfo,
  userSettings,
  postGroupSummariesReset,
}) => {
  const { authState: { accessToken }, authService } = useOktaAuth();
  const communitySlackOptIn = get(userSettings, ['payload', 'profile', 'communitySlackOptIn'], false) && featureDisabled(featureFlags.HEADER.COMMUNITY_SLACK);
  const developerSlackOptIn = get(userSettings, ['payload', 'profile', 'developerSlackOptIn'], false) && featureDisabled(featureFlags.HEADER.DEV_SLACK);

  const [savedAvatar, setSavedAvatar] = useState(BLANK_PIXEL);
  // const [cookies] = useCookies([hasLoggedIn.name, hasCreatedAccount.name]);
  // const cookieProps = {
  //   hasLoggedIn: get(cookies, hasLoggedIn.name),
  //   hasCreatedAccount: get(cookies, hasCreatedAccount.name),
  // };

  usePardotScoreRedirect(pardotScore, history, getPardotScoreReset);

  const isInstallationFlow = pathname === INSTALL_DEPLOY;
  const isOnInstallPages = pathname.includes(INSTALL_BASE);
  const isOnAdPage = pathname === ADVERTISEMENT_PAGE;
  const isOnAccountManagementPages = pathname.includes(APPLICATION_ROUTES.ACCOUNT_MANAGEMENT);

  useEffect(() => {
    if (accessToken) {
      removeLoggedOutCookie();
    }
  }, [accessToken]);

  useEffect(() => {
    if (userInfo) {
      const { email } = userInfo;
      const loggedInEmail = localStorage.getItem(LOGGED_IN_EMAIL);

      if (accessToken && !loggedInEmail && email) {
        localStorage.setItem(LOGGED_IN_EMAIL, email);
        Track({
          eventName: 'Corp Login',
          email,
        });
      } else if (!accessToken && loggedInEmail) {
        localStorage.removeItem(LOGGED_IN_EMAIL);
        Track({
          eventName: 'Corp Logout',
          email,
        });
      }
    }
  }, [accessToken, userInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Necessary or when using "Back button" it doesnt work
    window.requestAnimationFrame(() => window.scrollTo(0, 0));
  }, [pathname]);

  useEffect(() => {
    ReactGA.set({ userId: userInfo?.sub || null });
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);

    const { name, email, sub } = userInfo;

    if (name && email && sub) {
      Track({
        methodName: 'identify',
        eventName: sub,
        name,
        email,
      });

      Track({
        methodName: 'page',
        eventName: pathname,
        userId: sub,
      });
    }
  }, [pathname, userInfo]);

  useEffect(() => {
    if (accessToken && pathname !== ROUTES.MY_WORKSPACES) {
      postGroupSummaries({
        accessToken,
        useAsgard: true,
        noLoginOnUnauthorized: true,
      });
    }
  }, [accessToken, postGroupSummaries]);

  useEffect(() => {
    const {
      dataLoaded,
      error,
      fetching,
      payload,
    } = userSettings;

    if (accessToken && !dataLoaded && !error && !fetching && !payload && userInfo.sub) {
      getUserSettings({
        pathParams: {
          userId: userInfo.sub,
        },
      });
    }
  }, [
    accessToken,
    getUserSettings,
    userInfo,
    userSettings,
    postGroupSummariesReset,
  ]);

  useEffect(() => {
    // Handle case where user logs out and we need to reset the user avatar
    if (!accessToken) {
      postGroupSummariesReset();
    }
  }, [accessToken]);

  // Account header icon should be blank until user settings data is loaded. If
  // it fails than use the fallback icon.
  useEffect(() => {
    const {
      dataLoaded,
      payload,
    } = userSettings;

    if (dataLoaded) {
      setSavedAvatar(
        get(payload, ['profile', 'profileAvatarUrl'], testAccountAvatar)
      );
    } else {
      setSavedAvatar(testAccountAvatar);
    }
  }, [userSettings]);

  const activeOrganizations = useMemo(() => (organizations || []).filter(isOrgActive), [organizations]);

  const corpLogin = () => {
    authService.login(DEFAULT_POST_LOGIN_ROUTE);
  };

  const userEmail = get(userSettings, ['payload', 'profile', 'email'], '');

  return (
    <NewVersionAvailableProvider>
      <Header
        communitySlackOptIn={communitySlackOptIn}
        developerSlackOptIn={developerSlackOptIn}
        hasRequestedInstallationData={emailRequestInstallationData.dataLoaded}
        isLoggedIn={Boolean(accessToken)}
        isInstallationFlow={isInstallationFlow}
        isOnInstallPages={isOnInstallPages}
        isOnAdPage={isOnAdPage}
        isOnAccountManagementPages={isOnAccountManagementPages}
        installationLinkConfig={getDemoButtonConfig({ isLoggedIn: Boolean(accessToken), onClick: getPardotScore, isOnAdPage })}
        onLoginClick={corpLogin}
        onLogoutClick={() => authService.logout().then(setLoggedOutCookie)}
        navigationConfig={navigationConfig}
        mobileNavigationConfig={MOBILE_NAVIGATION_CONFIG}
        organizations={activeOrganizations}
        onRequestInstallationClick={postEmailRequestInstallation}
        savedAvatar={savedAvatar}
        searchValue={search}
        onSearchChange={setSearch}
        onSearchSubmit={() => history.push('/search')}
        userEmail={userEmail}
        authEnabled
        homePageUrl="https://www.openraven.com/"
      />
    </NewVersionAvailableProvider>
  );
};

/* eslint-disable react/forbid-prop-types */
HeaderContainer.propTypes = {
  getUserSettings: PropTypes.func.isRequired,
  userSettings: customPropTypes.apiData.isRequired,
  getPardotScore: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  pardotScore: PropTypes.object.isRequired,
  emailRequestInstallationData: PropTypes.object.isRequired,
  postEmailRequestInstallation: PropTypes.func.isRequired,
  postGroupSummaries: PropTypes.func.isRequired,
  getPardotScoreReset: PropTypes.func.isRequired,
};

HeaderContainer.defaultProps = {
};

const stateMapper = getRdxSelectionMapper({
  search: 'getSearch',
  userInfo: 'getUserInfo',
  userSettings: 'getUserSettingsData',
  pardotScore: 'getPardotScoreData',
  emailRequestInstallationData: 'getPostEmailRequestInstallationData',
  groupSummaries: 'getPostGroupSummariesData',
});

const actionMapper = getRdxActionMapper([
  'getUserSettings',
  'setSearch',
  'getPardotScore',
  'postEmailRequestInstallation',
  'postGroupSummaries',
  'postGroupSummariesReset',
  'getPardotScoreReset',
]);

export default compose(
  withRouter,
  injectIntl,
  connect(stateMapper, actionMapper),
)(HeaderContainer);
