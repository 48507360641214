const avgSecondsInMonth = 2592000;

const defaultOptions = {
  path: '/',
  maxAge: avgSecondsInMonth,
  sameSite: 'lax',
};

const hasLoggedIn = {
  name: 'HAS_LOGGED_IN',
  options: defaultOptions,
};

const hasCreatedAccount = {
  name: 'HAS_CREATED_ACCOUNT',
  options: defaultOptions,
};

export {
  hasLoggedIn,
  hasCreatedAccount,
};
