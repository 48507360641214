import { defineMessages } from 'react-intl';

const base = 'view-demo.';

export const VIEW_DEMO = defineMessages({
  HEADER: {
    id: `${base}header`,
    description: 'Header for view-demo unauthenticated view',
    defaultMessage: 'Demo',
  },
  MESSAGE: {
    id: `${base}message`,
    description: 'Message for view-demo unauthenticated view',
    defaultMessage: 'To see a demo, please:',
  },
  OR: {
    id: `${base}or`,
    description: 'Or text',
    defaultMessage: 'or',
  },
  LOGIN_BTN_LABEL: {
    id: `${base}login-btn-label`,
    description: 'Label for login buttom',
    defaultMessage: 'Login to Open Raven',
  },
  AUTHENTICATED_HEADER: {
    id: `${base}video-header`,
    description: 'Header for view-demo authenticated view',
    defaultMessage: 'Open Raven demos',
  },
});
