import React from 'react';
import { ActionButton } from '@openraven/react-styleguide';
import { getClassNameBuilder } from 'lib/utils/get-classname-builder';
import './login-signup.scss';

const { baseClass, getClassName } = getClassNameBuilder('login-signup');

const LoginSignup = ({ loginLinkProps }) => {
  const onClick = () => {
    loginLinkProps.onClick();
  };

  return (
    <div className={baseClass}>
      <ActionButton
        className={getClassName('login-link')}
        onClick={onClick}
        variant="primary"
      >
        <p>Sign In</p>
      </ActionButton>
    </div>
  );
};

export {
  LoginSignup,
};
