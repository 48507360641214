import React, { lazy } from 'react';
import { wrapAsDefaultExport } from 'lib/utils/wrap-as-default-export';
import { Redirect } from 'react-router-dom';
import { BASE_NAVIGATION_CONFIG } from 'components/header/constants';
import { FOOTER_NAVIGATION_CONFIG } from 'components/footer/constants';
import { with404 } from 'lib/utils/route-helpers';
import { ROUTE } from './route-constants';

const InstallPage = lazy(() => import(
  /* webpackChunkName: "betaInstallPage" */
  /* webpackMode: "lazy" */
  'pages/install-page'
).then(wrapAsDefaultExport('InstallPage')));
const InstallLandingPage = lazy(() => import(
  /* webpackChunkName: "installLanding" */
  /* webpackMode: "lazy" */
  'pages/install-landing-page'
).then(wrapAsDefaultExport('InstallLandingPage')));
const EnterpriseInstallPage = lazy(() => import(
  /* webpackChunkName: "enterpriseInstallPage" */
  /* webpackMode: "lazy" */
  'pages/enterprise-install-page'
).then(wrapAsDefaultExport('InstallPage')));

const LogoutPage = lazy(() => import(
  /* webpackChunkName: "logout" */
  /* webpackMode: "lazy" */
  'pages/logout-page'
).then(wrapAsDefaultExport('LogoutPage')));

const UnsubscribePage = lazy(() => import(
  /* webpackChunkName: "unsubscribe" */
  /* webpackMode: "lazy" */
  'pages/unsubscribe-page'
).then(wrapAsDefaultExport('UnsubscribePage')));

const SubscribePage = lazy(() => import(
  /* webpackChunkName: "subscribe" */
  /* webpackMode: "lazy" */
  'pages/subscribe-page'
).then(wrapAsDefaultExport('SubscribePage')));

const AccountManagementPage = lazy(() => import(
  /* webpackChunkName: "acct-mgmt" */
  /* webpackMode: "lazy" */
  'pages/account-management-page'
).then(wrapAsDefaultExport('AccountManagementPage')));

const SignUpPage = lazy(() => import(
  /* webpackChunkName: "signup" */
  /* webpackMode: "lazy" */
  'pages/sign-up-page'
).then(wrapAsDefaultExport('SignUpPage')));

const CreateAccountPage = lazy(() => import(
  /* webpackChunkName: "create-account" */
  /* webpackMode: "lazy" */
  'pages/create-account-page'
).then(wrapAsDefaultExport('CreateAccountPage')));

const ForgotPasswordPage = lazy(() => import(
  /* webpackChunkName: "forgot-password" */
  /* webpackMode: "lazy" */
  'pages/forgot-password'
).then(wrapAsDefaultExport('ForgotPasswordPage')));

const NoAccessPage = lazy(() => import(
  /* webpackChunkName: "no-access" */
  /* webpackMode: "lazy" */
  'pages/no-access-page'
).then(wrapAsDefaultExport('NoAccessPage')));

const AccessDeniedPage = lazy(() => import(
  /* webpackChunkName: "access-denied" */
  /* webpackMode: "lazy" */
  'pages/access-denied-page'
).then(wrapAsDefaultExport('AccessDeniedPage')));

const LoginCallbackPage = lazy(() => import(
  /* webpackChunkName: "login-callback-page" */
  /* webpackMode: "lazy" */
  'pages/login-callback'
).then(wrapAsDefaultExport('LoginCallbackPage')));

const formatNavigation = config => config.map(item => ({
  ...item,
  links: item.links.map((link) => {
    const path = link.key;

    if (link.to) {
      return link;
    }

    return {
      ...link,
      path: link.key,
      to: path,
      exact: true,
    };
  }),
}));


const navigationConfig = formatNavigation(BASE_NAVIGATION_CONFIG);
const footerNavigationConfig = formatNavigation(FOOTER_NAVIGATION_CONFIG);

/* webpackChunkName: main-page */
/* webpackMode: "lazy" */
const routes = with404([
  {
    key: 'home',
    path: '/',
    component: () => <Redirect to={ROUTE.ACCOUNT_MANAGEMENT} />,
    exact: true,
  },
  {
    key: 'install-deploy',
    path: '/install/deploy',
    component: InstallPage,
  },
  {
    key: 'enterprise-install',
    path: '/enterprise-install',
    exact: true,
    component: () => <Redirect to="/enterprise-install/deploy" />,
  },
  {
    key: 'enterprise-install-deploy',
    path: '/enterprise-install/deploy',
    component: EnterpriseInstallPage,
  },
  {
    key: 'subscribe',
    path: ROUTE.SUBSCRIBE,
    component: SubscribePage,
    exact: false,
  },
  {
    key: 'unsubscribe-hash',
    path: ROUTE.UNSUBSCRIBE_HASH,
    component: UnsubscribePage,
    exact: false,
  },
  {
    key: 'unsubscribe',
    path: ROUTE.UNSUBSCRIBE,
    component: UnsubscribePage,
    exact: false,
  },
  {
    key: 'sign-up',
    path: ROUTE.SIGN_UP,
    component: SignUpPage,
    useSplash: true,
    exact: true,
  },
  {
    key: 'create-account-page',
    path: ROUTE.CREATE_ACCOUNT,
    component: CreateAccountPage,
    useSplash: true,
    exact: false,
  },
  {
    key: 'forgot-password-token',
    path: ROUTE.FORGOT_PASSWORD_TOKEN,
    component: ForgotPasswordPage,
    useSplash: true,
    exact: false,
  },
  {
    key: 'forgot-password',
    path: ROUTE.FORGOT_PASSWORD,
    component: ForgotPasswordPage,
    useSplash: true,
    exact: false,
  },
  {
    key: 'implicit-callback',
    path: ROUTE.IMPLICIT_CALLBACK,
    component: LoginCallbackPage,
    exact: true,
  },
  {
    key: 'access-denied',
    path: ROUTE.ACCESS_DENIED,
    component: AccessDeniedPage,
    exact: true,
  },
  {
    key: 'install',
    path: ROUTE.INSTALL,
    component: () => <Redirect to={ROUTE.TRIAL} />,
    exact: true,
  },
  {
    key: 'enterprise-install',
    path: ROUTE.ENTERPRISE_INSTALL,
    component: InstallLandingPage,
    exact: true,
  },
  {
    key: 'trial',
    path: ROUTE.TRIAL,
    component: InstallPage,
    exact: true,
  },
  {
    key: 'logout',
    path: ROUTE.LOGOUT,
    component: LogoutPage,
    exact: true,
  },
]);
const secureRoutes = [
  {
    key: 'account-management',
    path: ROUTE.ACCOUNT_MANAGEMENT,
    component: AccountManagementPage,
    exact: true,
  },
  {
    key: 'account-management-tab',
    path: `${ROUTE.ACCOUNT_MANAGEMENT}/:profileTab`,
    component: AccountManagementPage,
    exact: true,
  },
  {
    key: 'no-access',
    path: ROUTE.NO_ACCESS,
    component: NoAccessPage,
    exact: true,
  },
];

export {
  navigationConfig,
  footerNavigationConfig,
  routes,
  secureRoutes,
};
