import createReducer from 'rdx/utils/create-reducer';
import types from 'rdx/modules/app/types';

const initialFormState = {
  form: {
    oid: '00D3i000000EHEW',
    retURL: `${window.location.origin}/contact`,
    firstName: '',
    lastName: '',
    email: '',
    reason: '',
  },
  // TODO: set validation [OR-14]
  errors: {
    firstName: false,
    lastName: false,
    email: false,
    reason: false,
  },
};


const contactFormReducer = createReducer(initialFormState, {
  [types.APP_CONTACT_FORM_RESET_FIELDS]: () => initialFormState,
  [types.APP_CONTACT_FORM_SET_FIELD](state, action) {
    return {
      ...state,
      form: {
        ...state.form,
        [action.payload.field]: action.payload.value,
      },
    };
  },

  [types.APP_CONTACT_FORM_SET_ERRORS](state, action) {
    return {
      ...state,
      errors: action.payload,
    };
  },
  [types.APP_CONTACT_FORM_FETCH](state, { payload = true }) {
    return {
      ...state,
      fetching: payload,
      error: false,
      success: false,
    };
  },
  [types.APP_CONTACT_FORM_ERROR](state, { payload = true }) {
    return {
      ...state,
      fetching: false,
      error: payload,
      success: false,
    };
  },
  [types.APP_CONTACT_FORM_SUCCESS](state, { payload = true }) {
    return {
      ...state,
      fetching: false,
      error: false,
      success: payload,
    };
  },
});

export {
  contactFormReducer,
};
