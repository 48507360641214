/**
 * Creates a generic api action
 * @author Ericky Dos Santos
 * @file create-api-action.js
 */

import { generatePath } from 'react-router';
import authSelectors from 'rdx/modules/auth/selectors';
import { handleUnauthorized } from 'lib/api/handle-unauthorized';
import createAction from './create-action';
import { api, asgardApi } from '../api';

/**
 * Creates a generic api action based on types, method, url, and configuration passed to it
 * @param {array} types - REQUEST, SUCCESS, ERROR types in that same order
 * @param {string} method - Method of the api call
 * @param {string} url - Url to hit with the api call
 * @param {Object} config - Config Object {
 *   @property {Object} body - Body of the api call (usually used on POST requests)
 *   @property {Object} pathParams - Query params that are replaced in the url path
 *   @property {Object} queryParams - Query params that are added to the url
 * }
 * @returns {function} Async Action
 */
export default function createApiAction(types, method, url, {
  body,
  pathParams,
  queryParams,
  useAsgard = false,
  noLoginOnUnauthorized,
  accessToken,
} = {}) {
  return async (dispatch, getState) => {
    dispatch(createAction(types[0]));
    const storedAccessToken = authSelectors.getAccessToken(getState());

    try {
      const client = useAsgard ? asgardApi : api;
      const { data } = await client.request({
        // TODO: come up with a better way of deciding which endpoints should be auth'd
        ...(accessToken || (url.includes('okta') && storedAccessToken) ? { headers: { 'Authorization': `Bearer ${accessToken || storedAccessToken}` } } : {}),
        method,
        url: generatePath(url, pathParams),
        data: body,
        params: queryParams,
      });

      dispatch(createAction(types[1], data));
      return data;
    } catch (err) {
      console.error(err);
      await handleUnauthorized({
        axiosError: err,
        noLoginOnUnauthorized,
      });

      dispatch(createAction(types[2], err));
      return false;
    }
  };
}
