import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { HELMET } from 'translations/keys/helmet';
import { logoPng } from 'assets/images';

const {
  DESCRIPTION,
  SITE_TITLE,
  META_KEYWORDS,
} = HELMET;

/**
 * helmet optimization
 * https://www.metatagseo.com/
 * https://github.com/nfl/react-helmet#readme
 */

const RootHelmetView = ({
  intl: {
    formatMessage,
  },
  history,
}) => {
  const appName = formatMessage(SITE_TITLE);
  const description = formatMessage(DESCRIPTION);
  const metaKeywords = formatMessage(META_KEYWORDS);
  const title = 'Data Security, Reimagined';
  const URL = 'https://www.openraven.com';

  return (
    <Helmet
      defaultTitle={appName}
      titleTemplate={`${appName} - %s`}
    >
      <meta name="description" content={description} />
      <meta name="keywords" content={metaKeywords} />
      <meta property="og:site_name" content={appName} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${URL}${logoPng}`} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={URL} />
      <meta property="og:type" content="article" />
      <meta name="robots" content="index,follow" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={window.location.href} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${URL}${logoPng}`} />
      <meta name="twitter:site" content="@openraven" />
      <link rel="canonical" href={`${URL}${history.location.pathname}`} />
      <link
        rel="alternate"
        type="application/rss+xml"
        title="RSS Feed"
        href="https://www.openraven.com/feed"
      />
    </Helmet>
  );
};

const RootHelmet = withRouter(injectIntl(RootHelmetView));

export { RootHelmet };
