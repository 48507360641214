import { defineMessages } from 'react-intl';

const base = 'legal-links';

export const LEGAL_LINKS = defineMessages({
  COPY: {
    id: `${base}.copy`,
    description: 'label to the right of the copyright symbol',
    defaultMessage: 'Open Raven',
  },
  TOS: {
    id: `${base}.tos`,
    description: 'Terms and conditions label',
    defaultMessage: 'Terms and Conditions',
  },
  PRIVACY: {
    id: `${base}.privacy`,
    description: 'privacy policy label',
    defaultMessage: 'Privacy Policy',
  },
});
