import types from 'rdx/modules/app/types';
import createAction from 'rdx/utils/create-action';

export default {
  batchActions: payload => createAction(types.BATCH_ACTIONS, payload),
  // contact form actions
  submitContactForm: payload => createAction(types.APP_SUBMIT_CONTACT_FORM, payload),
  setContactFormFetching: payload => createAction(types.APP_CONTACT_FORM_FETCH, payload),
  setContactFormError: payload => createAction(types.APP_CONTACT_FORM_ERROR, payload),
  setContactFormSuccess: payload => createAction(types.APP_CONTACT_FORM_ERROR, payload),
  setContactFormField: payload => createAction(types.APP_CONTACT_FORM_SET_FIELD, payload),
  setContactFormFieldErrors: payload => createAction(types.APP_CONTACT_FORM_SET_ERRORS, payload),
  resetContactForm: payload => createAction(types.APP_CONTACT_FORM_RESET_FIELDS, payload),
  // email form actions
  submitEmailForm: payload => createAction(types.APP_SUBMIT_EMAIL_FORM, payload),
  setEmailFormField: payload => createAction(types.APP_EMAIL_FORM_SET_FIELD, payload),
  setEmailFormFieldErrors: payload => createAction(types.APP_EMAIL_FORM_SET_ERRORS, payload),
  resetEmailForm: payload => createAction(types.APP_RESET_EMAIL_FORM, payload),
  // unsubscribe form actions
  submitUnsubscribeForm: payload => createAction(types.APP_SUBMIT_UNSUBSCRIBE_FORM, payload),
  setUnsubscribeFormField: payload => createAction(types.APP_UNSUBSCRIBE_FORM_SET_FIELD, payload),
  setUnsubscribeFormFieldErrors: payload => createAction(
    types.APP_UNSUBSCRIBE_FORM_SET_ERRORS,
    payload
  ),
  resetUnsubscribeForm: payload => createAction(types.APP_RESET_UNSUBSCRIBE_FORM, payload),
  // modal
  toggleModal: payload => createAction(types.APP_TOGGLE_MODAL, payload),
  // Global error actions
  setError: payload => createAction(types.APP_SET_ERROR, payload),
  clearError: payload => createAction(types.APP_CLEAR_ERROR, payload),
  toggleErrorVisibility: payload => createAction(types.APP_TOGGLE_ERROR_VISIBILITY, payload),
  // blog
  addLocalComment: payload => createAction(types.APP_BLOG_ADD_LOCAL_COMMENT, payload),
  resetLocalComments: () => createAction(types.APP_BLOG_RESET_LOCAL_COMMENTS),
  setSelectedDemoTime: payload => createAction(types.SET_SELECTED_DEMO_TIME, payload),

  // Search bar in header
  setSearch: search => createAction(types.APP_SET_SEARCH, search),
  setDemoConfirmed: payload => createAction(types.SET_DEMO_CONFIRMED, payload),
};
