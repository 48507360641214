import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { ROUTE } from 'containers/root-router/route-constants';
import { getClassNameBuilder } from 'lib/utils/get-classname-builder';
import { makeId, IDENTIFIERS } from 'lib/utils/make-id';
import {
  SvgArrowDropdownSmallUp,
  ActionButton,
  PopoverMenu,
} from '@openraven/react-styleguide';
import { TRANSLATION_KEYS } from 'translations/keys';
import './your-organizations.scss';

const {
  HEADER: {
    YOUR_WORKSPACES,
  },
} = TRANSLATION_KEYS;

const { baseClass, getClassName } = getClassNameBuilder('your-organizations');

const YourOrganizationsView = ({
  myOrganizations,
  isDirectLink,
  history,
  onClick,
}) => {
  const { formatMessage } = useIntl();
  const [isYourOrganizationsOpen, setIsYourOrganizationsOpen] = useState(false);
  const [listOptions, setListOptions] = useState([]);
  const isMyOrganizationsPage = history?.location?.pathname === ROUTE.MY_WORKSPACES ?? false;
  const getButton = () => {
    const button = document.getElementsByClassName('your-organizations__button');

    if (button && button[0]) {
      return button[0];
    }

    return null;
  };

  useEffect(() => {
    if (myOrganizations.length) {
      const options = [...myOrganizations, {
        label: 'View All My Workspaces',
        onClick: () => history.push(ROUTE.MY_WORKSPACES),
      }];
      setListOptions(options);
    }
  }, [myOrganizations]);


  const click = () => {
    if (onClick) {
      onClick();
    }
    const button = getButton();
    if (button) { button.blur(); }
    history.push(ROUTE.MY_WORKSPACES);
  };

  const simulateRollover = () => {
    const button = getButton();
    if (button) { button.click(); }
  };

  if (isMyOrganizationsPage) {
    return null;
  }

  if (isDirectLink) {
    return (
      <div className={baseClass} id={makeId({ name: 'your-organizations', prefix: IDENTIFIERS.HEADER, suffix: IDENTIFIERS.FOOTER })}>
        <ActionButton
          className={getClassName('button')}
          message={formatMessage(YOUR_WORKSPACES)}
          size="large"
          variant="primary-dark"
          onClick={click}
        >
          <SvgArrowDropdownSmallUp className={classNames(isYourOrganizationsOpen && 'is-open')} />
        </ActionButton>
      </div>
    );
  }

  return (
    <div
      className={baseClass}
      onMouseEnter={() => simulateRollover()}
      onMouseLeave={() => simulateRollover()}
      id={makeId({ name: 'your-organizations', prefix: IDENTIFIERS.HEADER, suffix: IDENTIFIERS.BUTTON })}
    >
      <PopoverMenu
        className={getClassName('popover')}
        menuItems={listOptions}
        onChange={setIsYourOrganizationsOpen}
      >
        <ActionButton
          className={getClassName('button')}
          message={formatMessage(YOUR_WORKSPACES)}
          size="large"
          variant="primary-dark"
          onMouseDown={click}
        >
          <SvgArrowDropdownSmallUp className={classNames(isYourOrganizationsOpen && 'is-open')} />
        </ActionButton>
      </PopoverMenu>
    </div>
  );
};

const YourOrganizations = withRouter(YourOrganizationsView);

export {
  YourOrganizations,
};
