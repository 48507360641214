import { defineMessages } from 'react-intl';

const base = 'get-started.';
export const GET_STARTED = defineMessages({
  AWS_DOCKER_TITLE: {
    id: `${base}aws-docker-title`,
    description: 'Title for aws docker get started',
    defaultMessage: 'Getting started with Docker Install',
  },
  AWS_DOCKER_CREATE_ORG: {
    id: `${base}aws-docker-create-org`,
    description: 'Create org text',
    defaultMessage: 'Create an organization',
  },
  ORGANIZATION_NAME: {
    id: `${base}organization-name`,
    description: 'Organization name text',
    defaultMessage: 'ORGANIZATION NAME',
  },
  NEXT_BUTTON: {
    id: `${base}next-button`,
    description: 'Next button for get started flow',
    defaultMessage: 'Next',
  },
  RUN_DOCKER: {
    id: `${base}run-docker`,
    description: 'Launch docker',
    defaultMessage: 'Run this Docker command',
  },
  RUN_DOCKER_COMMAND: {
    id: `${base}run-docker-command`,
    description: 'Run docker command',
    defaultMessage: 'Run Docker command',
  },

  POST_DOCKER_INSTRUCTIONS: {
    id: `${base}post-docker-instructions`,
    description: 'What to do after docker runs',
    defaultMessage: 'After you run the Docker command and it has completed, paste the following url into your browser: {url}',
  },

  DOWNLOAD_DOCKER_NOTE: {
    id: `${base}download-docker-note`,
    description: 'Download docker note',
    defaultMessage: 'Note: If you do not have Docker Desktop installed, click below to download and install.',
  },
  DOWNLOAD_DOCKER: {
    id: `${base}download-docker`,
    description: 'Download docker',
    defaultMessage: 'Download Docker Desktop',
  },
  AWS_DOCKER_BREADCRUMB: {
    id: `${base}aws-docker-breadcrumb`,
    description: 'Aws docker get started breadcrumb',
    defaultMessage: 'Get started',
  },
  ORG_AVAILABLE: {
    id: `${base}aws-docker-org-available`,
    description: 'Aws docker org available',
    defaultMessage: 'AVAILABLE',
  },
  LINK_HERE: {
    id: `${base}aws-docker-link-here`,
    description: 'Link here text',
    defaultMessage: 'here',
  },
});
