import createReducer from 'rdx/utils/create-reducer';
import types from 'rdx/modules/app/types';

const initialState = {
  form: {
    email: '',
  },
  errors: {
    email: false,
  },
};


const emailFormReducer = createReducer(initialState, {
  [types.APP_EMAIL_FORM_SET_FIELD](state, action) {
    return {
      ...state,
      form: {
        ...state.form,
        [action.payload.field]: action.payload.value,
      },
    };
  },
  [types.APP_EMAIL_FORM_SET_ERRORS](state, action) {
    return {
      ...state,
      errors: action.payload,
    };
  },
  [types.APP_RESET_EMAIL_FORM](state) {
    return {
      ...initialState,
      modalIsVisible: state.modalIsVisible,
    };
  },
});

export {
  emailFormReducer,
};
