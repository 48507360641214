import { contactFormReducer as contactForm } from './contact-form';
import { emailFormReducer as emailForm } from './email-form';
import { unsubscribeFormReducer as unsubscribeForm } from './unsubscribe-form';
import { modalReducer as modal } from './modal';
import { errorReducer as error } from './error';
import { headerReducer as header } from './header';
import { reducer as localBlogComments } from './local-blog-comments';
import { selectedDemoTimeReducer as selectedDemoTime } from './selected-demo-time';

export default {
  contactForm,
  emailForm,
  unsubscribeForm,
  modal,
  error,
  header,
  localBlogComments,
  selectedDemoTime,
};
