import { defineMessages } from 'react-intl';

export const INSTALL_NAV = defineMessages({
  BREADCRUMB_INSTALL: {
    id: 'install.nav.breadcrumb.install',
    description: 'Install nav breadcrumb install',
    defaultMessage: 'Install',
  },
  LATEST_RELEASE: {
    id: 'install.nav.latest-release',
    description: 'Install nav text latest release',
    defaultMessage: 'Latest Release:',
  },
  SUBTITLE: {
    id: 'install.nav.subtitle',
    description: 'Install nav text subtitle',
    defaultMessage: 'Deploy Open Raven',
  },
  TITLE: {
    id: 'install.nav.title',
    description: 'Install nav text title',
    defaultMessage: 'INSTALL',
  },
});
