import { defineMessages } from 'react-intl';
import { ABOUT } from './about';
import { ACCESS_DENIED } from './access-denied';
import { COMMON } from './common';
import { FORM } from './form';
import { HEADER } from './header';
import { HOME } from './home';
import { HELMET } from './helmet';
import { INSTALL } from './install';
import { INSTALL_LANDING } from './install-landing';
import { INSTALL_NAV } from './install-nav';
import { SIGN_UP } from './sign-up';
import { ACCOUNT_MANAGEMENT } from './account-management';
import { LOGIN } from './login';
import { BLOG } from './blog';
import { FORGOT_PASSWORD } from './forgot-password';
import { GET_STARTED } from './get-started';
import { NO_ACCESS } from './no-access';
import { CONTACT } from './contact';
import { GET_STARTED_CARD } from './get-started-card';
import { VIEW_DEMO } from './view-demo';
import { PRODUCT } from './product-overview';
import { BOOK_DEMO } from './book-demo';
import { CONFIRM_DEMO } from './confirm-demo';
import { YOUREIN } from './yourein';
import { SEARCH_RESULTS } from './search-results';
import { CONNECTORS_LANDING } from './connectors-landing';
import { LIBRARY } from './library';
import { DISCOVER } from './discover';
import { LEGAL_LINKS } from './legal-links';
import { MEDIA_KIT } from './media-kit';

export const parseIntlText = (intl, text) => {
  if (typeof text === 'object' && text.id) {
    return intl.formatMessage(text);
  }

  return text;
};

const APP_MESSAGES = defineMessages({
  APP_NAME: {
    id: 'app-name',
    description: 'App name',
    defaultMessage: 'Open Raven',
  },
  LABEL: {
    id: 'label',
    description: 'Label of the locale',
    defaultMessage: 'English',
  },
  PAGE_NOT_FOUND: {
    id: 'page-not-found',
    description: 'Page not found message',
    defaultMessage: 'We can find a lot of things, but not this page...',
  },
});

export const TRANSLATION_KEYS = {
  ABOUT,
  ACCESS_DENIED,
  COMMON,
  FORM,
  HEADER,
  HELMET,
  HOME,
  INSTALL,
  INSTALL_LANDING,
  INSTALL_NAV,
  SIGN_UP,
  ACCOUNT_MANAGEMENT,
  LOGIN,
  FORGOT_PASSWORD,
  BLOG,
  GET_STARTED,
  NO_ACCESS,
  CONTACT,
  GET_STARTED_CARD,
  VIEW_DEMO,
  PRODUCT,
  YOUREIN,
  BOOK_DEMO,
  CONFIRM_DEMO,
  SEARCH_RESULTS,
  CONNECTORS_LANDING,
  LIBRARY,
  DISCOVER,
  LEGAL_LINKS,
  MEDIA_KIT,
  ...APP_MESSAGES,
};
