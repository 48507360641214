import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { rules, pipeValidators } from '@codeparticle/formal';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Visible } from '@codeparticle/react-visible';
import { compose } from 'redux';
import Track from 'lib/utils/tracking';
import {
  SvgLaunch,
  testAccountAvatar,
  Modal,
} from '@openraven/react-styleguide';
import {
  HeaderAccountManagement,
  SocialIcons,
  LegalLinks,
} from 'components';
import { LogoWhite } from 'assets/images';
import { getClassNameBuilder } from 'lib/utils/get-classname-builder';
import { TRANSLATION_KEYS } from 'translations/keys';
import { DesktopNavigation } from './desktop-navigation';
import { MobileNavigation } from './mobile-navigation';
import { NavLink } from './nav-link';
import './header.scss';

const {
  HEADER: {
    READY_TO_INSTALL,
    INSTALL_MODAL_TEXT,
    REQUEST_INSTALLATION,
    INSTALL_MODAL_INPUT_PLACEHOLDER,
    INSTALL_REQUESTED_MODAL_HEADER,
    INSTALL_REQUESTED_MODAL_TEXT_RECEIVED,
    INSTALL_REQUESTED_MODAL_TEXT_INBOX,
    BACK_TO_DEMOS,
    YOUR_WORKSPACES_INACTIVE,
    YOUR_WORKSPACES_LAUNCH,
  },
  COMMON: {
    CANCEL,
  },
} = TRANSLATION_KEYS;

const TOP_BANNER_DISMISSED = 'top-banner-dissmissed';

const isValidEmail = pipeValidators([rules.isValidEmail]);

const HeaderView = ({
  authEnabled,
  hasRequestedInstallationData,
  hideSearch,
  homePageUrl,
  installationLinkConfig,
  isLoggedIn,
  isInstallationFlow,
  isOnAccountManagementPages,
  location: {
    pathname,
  },
  intl: {
    formatMessage,
  },
  navigationConfig,
  mobileNavigationConfig,
  onLoginClick,
  onLogoutClick,
  onRequestInstallationClick,
  organizations,
  savedAvatar,
  userEmail,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isTopBannerVisible, setIsTopBannerVisible] = useState(false);
  const [isRequestInstallModalShown, setIsRequestInstallModalShown] = useState(false);
  const [requestInstallationModalEmail, setRequestInstallationModalEmail] = useState('');
  const desktopNavigationMenuTargetId = 'desktop-navigation-menu-target-id';

  const loginLinkProps = useMemo(() => {
    if (onLoginClick) {
      // Let the parent decide what to do.
      return {
        onClick: () => {
          setMobileMenuOpen(false);
          onLoginClick();
        },
      };
    }
    return {};
  }, [onLoginClick, setMobileMenuOpen]);

  const popoverMenuItems = useMemo(() => (organizations || []).map((item) => {
    const { clusterUrl, name, status } = item;
    const isDisabled = (status || '').toLowerCase() !== 'active';
    const description = isDisabled ? '' : formatMessage(YOUR_WORKSPACES_LAUNCH);
    const descriptionTooltip = isDisabled ? '' : clusterUrl;
    const labelTooltip = isDisabled ? formatMessage(YOUR_WORKSPACES_INACTIVE) : '';

    return {
      description,
      DescriptionIcon: SvgLaunch,
      descriptionTooltip,
      disabled: isDisabled,
      external: true,
      label: name,
      labelTooltip,
      onClick: () => Track({
        eventName: 'Launch Cluster',
        ...item,
        email: userEmail,
      }),
      target: '_blank',
      to: clusterUrl,
    };
  }), [organizations, userEmail]);

  useEffect(() => {
    if (localStorage && !localStorage.getItem(TOP_BANNER_DISMISSED)) {
      setIsTopBannerVisible(true);
    }
  }, []);

  const renderModalContent = () => {
    const { baseClass, getClassName, getClassNames } = getClassNameBuilder('request-install-modal');

    return (
      <div className={baseClass}>
        <h2 className={getClassName('header')}>{formatMessage(hasRequestedInstallationData ? INSTALL_REQUESTED_MODAL_HEADER : READY_TO_INSTALL)}</h2>
        <Visible
          when={hasRequestedInstallationData}
          fallback={(
            <div className={getClassName('text')}>{formatMessage(INSTALL_MODAL_TEXT)}</div>
          )}
        >
          <div className={getClassName('text')}>{formatMessage(INSTALL_REQUESTED_MODAL_TEXT_RECEIVED)}</div>
          <div className={getClassName('text')}>{formatMessage(INSTALL_REQUESTED_MODAL_TEXT_INBOX)}</div>
        </Visible>
        <Visible when={!hasRequestedInstallationData}>
          <input
            type="email"
            onChange={(e) => { setRequestInstallationModalEmail(e.currentTarget.value); }}
            value={requestInstallationModalEmail}
            className={getClassName('input')}
            placeholder={formatMessage(INSTALL_MODAL_INPUT_PLACEHOLDER)}
          />
        </Visible>
        <div className={getClassName('actions')}>
          <Visible when={!hasRequestedInstallationData}>
            <button
              onClick={setIsRequestInstallModalShown.bind(null, false)}
              className={getClassNames('btn', 'btn--cancel')}
            >
              {formatMessage(CANCEL)}
            </button>
          </Visible>
          <Visible
            when={!hasRequestedInstallationData}
            fallback={(
              <button
                onClick={() => {
                  setIsRequestInstallModalShown(false);
                }}
                className={getClassNames('btn', 'btn--action')}
              >
                {formatMessage(BACK_TO_DEMOS)}
              </button>
            )}
          >
            <button
              onClick={onRequestInstallationClick}
              disabled={!isValidEmail(requestInstallationModalEmail).isSuccess}
              className={getClassNames('btn', 'btn--action')}
            >
              {formatMessage(REQUEST_INSTALLATION)}
            </button>
          </Visible>
        </div>
      </div>
    );
  };

  const { baseClass, getClassName } = getClassNameBuilder('header-rct-component');

  return (
    <div
      className={classNames(baseClass, isTopBannerVisible && 'banner-visible')}
      id={desktopNavigationMenuTargetId}
    >
      <Modal
        rounded
        toggleModal={setIsRequestInstallModalShown}
        modalIsVisible={isRequestInstallModalShown}
      >
        {renderModalContent()}
      </Modal>
      <div className={getClassName('inner-container')}>
        <NavLink
          external={Boolean(homePageUrl)}
          title="Home"
          to={homePageUrl || '/'}
        >
          <LogoWhite
            alt="Site logo"
            className="logo"
          />
        </NavLink>
        <Visible when={isLoggedIn}>
          <DesktopNavigation
            hideSearch={hideSearch}
            navigationConfig={navigationConfig}
            targetMenuElementId={desktopNavigationMenuTargetId}
          />
        </Visible>

        <div className={getClassName('right-items')}>
          <Visible when={isLoggedIn}>
            <Visible
              fallback={(
                <div className={classNames('account-management-link', 'non-clickable')}>
                  <img
                    className="avatar"
                    src={savedAvatar}
                    alt="Avatar"
                  />
                </div>
              )}
              when={!isInstallationFlow}
            >
              <HeaderAccountManagement
                savedAvatar={savedAvatar}
                authEnabled={authEnabled}
                onLogoutClick={onLogoutClick}
              />
            </Visible>
          </Visible>

          <Visible when={isOnAccountManagementPages}>
            <MobileNavigation
              visible={mobileMenuOpen}
              toggleVisible={setMobileMenuOpen}
              hideSearch={hideSearch}
              navigationConfig={mobileNavigationConfig}
              savedAvatar={savedAvatar}
              myOrganizations={popoverMenuItems}
              installationLinkConfig={installationLinkConfig}
              isLoggedIn={isLoggedIn}
              loginLinkProps={loginLinkProps}
              pathname={pathname}
            >
              <SocialIcons />
              <LegalLinks />
            </MobileNavigation>
          </Visible>
        </div>
      </div>
    </div>
  );
};

/* eslint-disable react/forbid-prop-types */
HeaderView.propTypes = {
  authEnabled: PropTypes.bool,
  hasRequestedInstallationData: PropTypes.bool,
  homePageUrl: PropTypes.string,
  installationLinkConfig: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isInstallationFlow: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  // Determines which URL or relative path to request for logins
  navigationConfig: PropTypes.array,
  onLoginClick: PropTypes.func,
  onRequestInstallationClick: PropTypes.func,
  organizations: PropTypes.arrayOf(PropTypes.shape({
    clusterUrl: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
  })),
  savedAvatar: PropTypes.string,
  userEmail: PropTypes.string,
  // Determines what url to go to after logout
};

HeaderView.defaultProps = {
  authEnabled: null,
  hasRequestedInstallationData: false,
  homePageUrl: '',
  installationLinkConfig: null,
  isLoggedIn: false,
  isInstallationFlow: false,
  navigationConfig: [],
  onLoginClick: null,
  onRequestInstallationClick: null,
  organizations: null,
  savedAvatar: testAccountAvatar,
  userEmail: '',
};

export default compose(
  injectIntl,
  withRouter,
)(HeaderView);
