import createReducer from 'rdx/utils/create-reducer';
import types from 'rdx/modules/app/types';

const headerReducer = createReducer({ search: '' }, {
  [types.APP_SET_SEARCH](state, action) {
    return {
      ...state,
      search: action.payload || '',
    };
  },
});

export {
  headerReducer,
};
