import { defineMessages } from 'react-intl';

const base = 'about-page';

export const ABOUT = defineMessages({
  ABOUT_BANNER_HEADLINE: {
    id: `${base}.about-banner-headline`,
    description: 'About Us Text',
    defaultMessage: 'About Us',
  },
  ABOUT_BANNER_STATEMENT: {
    id: `${base}.about-banner-statement`,
    description: 'About Us Banner Statement',
    defaultMessage: 'Product-Driven',
  },
  INVESTORS_HEADER: {
    id: `${base}.investors-header`,
    description: 'Investors header text',
    defaultMessage: 'Investors',
  },
  LINK_LINKEDIN: {
    id: `${base}.link-linkedin`,
    description: 'Linkedin Link text',
    defaultMessage: 'LinkedIn',
  },
  LINK_TWITTER: {
    id: `${base}.link-twitter`,
    description: 'Twitter link text',
    defaultMessage: 'Twitter',
  },
  MEET_BELE_HEADER: {
    id: `${base}.meet-bele-header`,
    description: 'Meet Bele Header text',
    defaultMessage: 'Meet Bele',
  },
  MISSION_PARAGRAPH: {
    id: `${base}.mission-paragraph`,
    description: 'About page mission paragraph',
    defaultMessage: 'It’s simply too hard to protect data today. Our problems are futuristic, yet our tools are Jurassic. Customers deserve better products to understand and control their data. Open Raven’s mission is to reinvent data security for a modern era and put an end to data exposure.',
  },
  MISSION_STATEMENT: {
    id: `${base}.mission-statement`,
    description: 'About page mission statement',
    defaultMessage: 'Our mission is to end data exposure by putting organizations back in control of their data',
  },
  OUR_STORY: {
    id: `${base}.our-story`,
    description: 'Our Story Text',
    defaultMessage: 'Our Story',
  },
  TEAM_HEADER: {
    id: `${base}.team-header`,
    description: 'Team section header text',
    defaultMessage: 'The team',
  },
  TEAM_JOIN_PROMPT: {
    id: `${base}.team-join-prompt`,
    description: 'Team section join prompt text',
    defaultMessage: 'See what it\'s like to work here',
  },
});
