import createReducer from 'rdx/utils/create-reducer';
import types from 'rdx/modules/app/types';

const initialState = [];

const reducer = createReducer(initialState, {
  [types.APP_BLOG_ADD_LOCAL_COMMENT](state, { payload }) {
    return [...state, payload];
  },
  [types.APP_BLOG_RESET_LOCAL_COMMENTS]() {
    return initialState;
  },
});

export {
  reducer,
};
