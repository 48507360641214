import createReducer from 'rdx/utils/create-reducer';
import types from 'rdx/modules/app/types';

const selectedDemoTimeReducer = createReducer({}, {
  [types.SET_SELECTED_DEMO_TIME](state, { payload }) {
    return {
      ...state,
      ...payload,
    };
  },
  [types.SET_DEMO_CONFIRMED](state, { payload }) {
    return {
      ...state,
      isConfirmed: payload,
    };
  },
});

export {
  selectedDemoTimeReducer,
};
