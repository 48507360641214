import { defineMessages } from 'react-intl';

export const HOME = defineMessages({
  GET_STARTED_SECTION_TITLE: {
    id: 'home.get-started-section.title',
    description: 'Get started section title',
    defaultMessage: 'Let’s get started',
  },
  GET_STARTED_SECTION_DESCRIPTION: {
    id: 'home.get-started-section.description',
    description: 'Get started section description',
    defaultMessage: 'Open Raven deploys into your own cloud environment within minutes without the hassle of agents or time-consuming configuration. There is no fee for Community Edition, but we require a short call to make sure you’re successful with the deployment.',
  },
  GET_STARTED_SECTION_CARD_DEMO: {
    id: 'home.get-started-section.card-demo',
    description: 'Get started section demo card title',
    defaultMessage: 'Get Started',
  },
  GET_STARTED_SECTION_CARD_SALES: {
    id: 'home.get-started-section.card-sales',
    description: 'Get started section sales card title',
    defaultMessage: 'Talk to Sales',
  },
  GET_STARTED_BANNER_TITLE: {
    id: 'home.get-started-banner.title',
    description: 'Get started banner title',
    defaultMessage: 'Community Edition is now available & free',
  },
  GET_STARTED_BANNER_BUTTON_TEXT: {
    id: 'home.get-started-banner.button-text',
    description: 'Get started banner button text',
    defaultMessage: 'Get Started',
  },
});
