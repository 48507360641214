import env from '@beam-australia/react-env';

const featureDisabled = (feature, allowOnDev = true) => ((allowOnDev && env('NODE_ENV') !== 'production') || feature);

const featureFlags = {
  INSTALL: {
    AWS_DOCKER: true,
    AZURE_DOCKER: false,
    AWS_MARKETPLACE: false,
    SOURCE_CODE: false,
  },
  HEADER: {
    SUPPORT: false,
    COMMUNITY_SLACK: false,
    DEV_SLACK: false,
  },
};

export {
  featureDisabled,
  featureFlags,
};
