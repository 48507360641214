/**
 * Functions to help when dealing with route logic
 */

import { lazy } from 'react';
import { wrapAsDefaultExport } from 'lib/utils/wrap-as-default-export';

const NotFoundPage = lazy(() => import(
  /* webpackChunkName: "notFoundPage" */
  /* webpackMode: "lazy" */
  'pages/not-found-page'
).then(wrapAsDefaultExport('NotFoundPage')));

const notFoundRouteProps = {
  key: 'not-found',
  component: NotFoundPage,
  exact: false,
  option: false,
  status: 404,
};

const append = route => routes => [...routes, route];

const with404 = append(notFoundRouteProps);

export {
  with404,
};
