import { get, groupBy } from 'lodash';
import { unpackCopy } from 'lib/utils/contentful';

const dataTransformer = (_payload) => {
  if (!_payload) {
    return [];
  }
  let payload = _payload;

  if (!Array.isArray(payload)) {
    payload = [{ ...payload }];
  }

  payload = payload.map((item) => {
    const { fields, sys: { id } } = item;
    const { description: text } = fields;
    const description = unpackCopy(text);

    return {
      ...fields,
      id,
      description,
    };
  });

  payload = groupBy(payload, item => get(item, ['category'], ''));

  return (
    payload
  );
};

export {
  dataTransformer,
};
