import { defineMessages } from 'react-intl';

const base = 'forgot-password.';

export const FORGOT_PASSWORD = defineMessages({
  CHECK_EMAIL: {
    id: `${base}check-email`,
    description: 'Message informing user to check email for forgotten password email',
    defaultMessage: 'Click the link in the email to finish resetting your password.',
  },
  EMAIL_SENT_MESSAGE: {
    id: `${base}email-sent-message`,
    description: 'Password reset email sent text',
    defaultMessage: 'Password reset email sent',
  },
  EMAIL_RESENT_MESSAGE: {
    id: `${base}email-resent-message`,
    description: 'Password reset email resent text',
    defaultMessage: 'Password reset email has been sent again',
  },
  RESEND_EMAIL: {
    id: `${base}resend-email`,
    description: 'Resend email text',
    defaultMessage: 'Resend email',
  },
  ERROR_SENDING_EMAIL: {
    id: `${base}error-sending-email`,
    description: 'error sending email text',
    defaultMessage: 'Error sending email. Please try again.',
  },
  ERROR_RESETTING_PASSWORD: {
    id: `${base}error-resetting-password`,
    description: 'error resetting password text',
    defaultMessage: 'Error Resetting Password. Please try again',
  },
  SUCCESSFUL_RESET: {
    id: `${base}successful-reset`,
    description: 'Your password has been successfully reset text',
    defaultMessage: 'Your password has been successfully reset.',
  },
});
