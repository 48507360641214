/* eslint-env window */
import env from '@beam-australia/react-env';

/*
  wrapper for react-segment
  This file prevents multiple loading of Segment
  All calls must be through window.analytics for tracking to work correctly
*/

// ES5 Module
require('react-segment');

const SEGMENT_KEY = env('SEGMENT_KEY');
const NODE_ENV = env('NODE_ENV');
const localTracking = false; // switch for local develoopment
const enabled = NODE_ENV === 'production' || localTracking;

const init = () => {
  if (enabled) {
    if (localTracking) {
      console.info('local tracking enabled');
    }

    try {
      if (window && window.analytics) {
        window.analytics.load(SEGMENT_KEY);
      }
    } catch (e) { console.error('error react-segement'); }
  }
};

const Track = (result) => {
  if (enabled) {
    const {
      eventName,
      methodName = 'track',
      ...rest
    } = result;
    if (window && window.analytics && window.analytics[methodName]) {
      window.analytics[methodName](eventName, {
        ...rest,
      });
    } else {
      console.error(`No method named "${methodName}" found in Tracking`);
    }
  }
};

init();

export default Track;
