import { defineMessages } from 'react-intl';

const prefix = 'media-kit';

export const MEDIA_KIT = defineMessages({
  MEDIA_KIT_SECTION_3_TITLE: {
    id: `${prefix}.section-3.title`,
    description: 'section-3 title',
    defaultMessage: 'Leadership',
  },
  MEDIA_KIT_SECTION_3_DOWNLOAD_HEADSHOT: {
    id: `${prefix}.section-3.download-headshot`,
    description: 'section-3 download headshot link text',
    defaultMessage: 'Download Headshot',
  },
  MEDIA_KIT_SECTION_4_TITLE: {
    id: `${prefix}.section-4.title`,
    description: 'section-4 title',
    defaultMessage: 'Downloads',
  },
  MEDIA_KIT_SECTION_4_LOGOS_TITLE: {
    id: `${prefix}.section-4.logos-title`,
    description: 'section-4 logos title',
    defaultMessage: 'Open Raven Logos',
  },
  MEDIA_KIT_SECTION_4_KIT_TITLE: {
    id: `${prefix}.section-4.kit-title`,
    description: 'section-4 kit title',
    defaultMessage: 'Open Raven Press Kit',
  },
  MEDIA_KIT_SECTION_4_DOWNLOAD: {
    id: `${prefix}.section-4.download`,
    description: 'section-4 download text',
    defaultMessage: 'Download',
  },
  MEDIA_KIT_SECTION_5_TITLE: {
    id: `${prefix}.section-5.title`,
    description: 'section-5 title text',
    defaultMessage: 'Contact',
  },
  MEDIA_KIT_SECTION_5_PRESS: {
    id: `${prefix}.section-5.press`,
    description: 'section-5 press inquiry',
    defaultMessage: 'For press inquiry',
  },
  MEDIA_KIT_SECTION_5_PHONE: {
    id: `${prefix}.section-5.phone`,
    description: 'section-4 phone text',
    defaultMessage: 'Phone',
  },
});
