import { defineMessages } from 'react-intl';

export const GET_STARTED_CARD = defineMessages({
  HEADER: {
    id: 'helmet.header',
    description: 'Header of the get started card',
    defaultMessage: 'Ready to get started?',
  },
  ACTION_BUTTON_LABEL: {
    id: 'helmet.action-button-label',
    description: 'Label of the action button',
    defaultMessage: 'Demo',
  },
});
