import createReducer from 'rdx/utils/create-reducer';
import types from 'rdx/modules/app/types';
import get from 'lodash/get';
import { reportSentryError } from 'lib/report-sentry-error';

const initialState = {
  title: '',
  details: '',
  visible: false,
};

const errorReducer = createReducer(initialState, {
  [types.APP_SET_ERROR](state, action) {
    let { details } = action.payload;
    const { error, noSentryError } = action.payload;

    if (typeof details === 'object') {
      try {
        const data = get(details, ['error', 'response', 'data'], get(details, ['response', 'data'], details));
        const status = get(details, ['error', 'response', 'status']);
        details = get(data, ['message', 'errorSummary'], '');

        if (!details) {
          details = JSON.stringify({
            status,
            data: get(data, ['message'], data),
          });
        }
      } catch (err) {
        console.log(err);
      }
    }

    if (error && !noSentryError) {
      reportSentryError(error);
    }

    return {
      title: action.payload.title,
      details,
    };
  },
  [types.APP_TOGGLE_ERROR_VISIBILITY](state, action) {
    return {
      ...state,
      visible: action.payload,
    };
  },
  [types.APP_CLEAR_ERROR]() {
    return initialState;
  },
});

export {
  errorReducer,
};
