import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './header-link.scss';
import { makeId, IDENTIFIERS } from 'lib/utils/make-id';
import { NavLink } from '../nav-link';

const HeaderLink = forwardRef(({
  external,
  onClick,
  selected,
  target,
  to,
  className,
  message,
  links,
  ...rest
}, ref) => {
  const [isSelected, setIsSelected] = useState(selected);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  const click = () => {
    setIsSelected(false);
  };

  return (
    <div
      {...rest}
      className={classNames('header__link-container', isSelected ? 'selected' : '', className)}
      ref={ref}
    >
      <NavLink
        onClick={click}
        title={message}
        to={to}
        className="link"
        id={makeId({ name: message, prefix: IDENTIFIERS.HEADER, suffix: IDENTIFIERS.BUTTON })}
      >
        {message}
      </NavLink>

      {links && (
        <ul className="drop-down-menu" id={makeId({ name: message, suffix: 'dropdown', prefix: IDENTIFIERS.HEADER })}>
          {/* eslint-disable */}
          {Array.isArray(links) && links.map(link => {
            if (link.mobileOnly) {
              return null;
            }

            return (
              <li key={link.title}>
                <NavLink
                  to={link.external ? link.path : link.to}
                  external={link.external}
                  onClick={click}
                  title={link.title}
                  id={makeId({name: link.title, prefix: IDENTIFIERS.HEADER, suffix: IDENTIFIERS.LINK })}
                >
                  {link.title}
                </NavLink>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  );
});

HeaderLink.propTypes = {
  external: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
};

HeaderLink.defaultProps = {
  external: false,
  onClick: null,
  selected: false,
  className: '',
  to: '',
};

export { HeaderLink };
