import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import axios from 'axios';
import poll from './poll';

const NewVersionContext = createContext({
  newVersionAvailable: false,
});

const checkVersionStartedRef = {};
let previousResult = null;
// 1 minute in milliseconds
const POLLING_INTERVAL = 60000;

async function compare(setter) {
  const res = await axios({
    url: '/',
    method: 'get',
  });
  const etag = res?.headers?.etag;
  if (previousResult && previousResult !== etag) {
    setter(true);
  }

  console.log('Checking for version updates', etag);
  previousResult = etag;
}

const startCheckingVersion = (setter) => {
  if (!checkVersionStartedRef.current) {
    poll(() => compare(setter), POLLING_INTERVAL);

    checkVersionStartedRef.current = true;
  }
};

const useNewVersionAvailable = () => useContext(NewVersionContext);

const NewVersionAvailableProvider = (props) => {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  useEffect(() => {
    startCheckingVersion(setNewVersionAvailable);
  }, []);

  return (
    <NewVersionContext.Provider value={{ newVersionAvailable }} {...props} />
  );
};

export {
  startCheckingVersion,
  useNewVersionAvailable,
  NewVersionAvailableProvider,
};
