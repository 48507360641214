const AWS_MARKETPLACE_TOKEN_KEY = 'aws-marketplace-token';
const AWS_MARKETPLACE_TOKEN_REDIRECT_KEY = 'aws-marketplace-token-redirect';

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
const DEFAULT_DOCKER_POST_URL = 'http://localhost:8000';

const LOCAL_STORAGE_KEY = {
  SECURE_REFERRER_PATH: 'secureRouterReferrerPath',
  DEMO_STATE: 'demoState',
};

const LOAD_STATES = {
  LOADING: 'loading',
  ERROR: 'error',
  NO_RESULTS: 'noResults',
};

const PARDOT_SCORE = {
  HIGH: 'HIGH',
  LOW: 'LOW',
};

const MOMENT_DEMO_TIMEZONE = 'America/Los_Angeles';

const INDIVIDUAL_DEMO_TIMES = [
  '8',
  '10',
  '13',
  '15',
];


const DEMO_TYPE = {
  GROUP: 'GROUP',
  INDIVIDUAL: 'INDIVIDUAL',
};

const MAX_DEMO_SCHEDULE_DAYS = 90;

const GOOGLE_EVENT_ID = {
  GROUP_DEMO: 'groupdemo',
};

export {
  isIE11,
  AWS_MARKETPLACE_TOKEN_KEY,
  AWS_MARKETPLACE_TOKEN_REDIRECT_KEY,
  LOAD_STATES,
  PARDOT_SCORE,
  DEFAULT_DOCKER_POST_URL,
  LOCAL_STORAGE_KEY,
  MOMENT_DEMO_TIMEZONE,
  INDIVIDUAL_DEMO_TIMES,
  DEMO_TYPE,
  MAX_DEMO_SCHEDULE_DAYS,
  GOOGLE_EVENT_ID,
};
