import types from 'rdx/modules/auth/types';
import createAction from 'rdx/utils/create-action';

export default {
  setAuthToken: authToken => createAction(types.SET_AUTH_TOKEN, authToken),
  setUserInfo: payload => createAction(types.SET_USER_INFO, payload),
  requestLogin: payload => createAction(types.REQUEST_LOGIN, payload),
  requestLogout: payload => createAction(types.REQUEST_LOGOUT, payload),
  setAccessToken: accessToken => createAction(types.SET_ACCESS_TOKEN, accessToken),
  setIdToken: idToken => createAction(types.SET_ID_TOKEN, idToken),
};
