import env from '@beam-australia/react-env';

const mapDomain = (imageUrl) => {
  if (imageUrl && env('NODE_ENV') === 'development') {
    return (
      `${env('DEV_CMS_API_URL')}${imageUrl}`
    );
  }
  return imageUrl;
};

export {
  mapDomain,
};
