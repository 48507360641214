import { get } from 'lodash';
import { createSelector } from 'reselect';

const getIdToken = state => get(state, 'idToken', '');
const getUserInfo = createSelector(
  getIdToken,
  (idToken) => {
    if (!idToken) { return {}; }

    try {
      const readableUserInfo = atob(idToken.split('.')[1]);

      return JSON.parse(readableUserInfo);
    } catch (err) {
      return {};
    }
  }
);

export default {
  getAuthToken: state => get(state, 'authToken', ''),
  getIdToken,
  getAccessToken: state => get(state, 'accessToken', ''),
  getUserInfo,
};
