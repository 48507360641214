import React, { useEffect } from 'react';
import classNames from 'classnames';
import {
  SvgXIcon,
  SvgHamburger,
  LinkOrButton,
  SvgLogo,
} from '@openraven/react-styleguide';
import { HeaderAccountManagement } from 'components/header-account-management';
import { YourOrganizations } from 'components/your-organizations';
import { LoginSignup } from 'components/login-signup';
import { Visible } from '@codeparticle/react-visible';
import layout from 'styles/_index.scss';
import { Flex } from 'reflexbox';
import { TabletLayout } from './tablet-layout';
import { NavLink } from '../nav-link';
import './mobile-navigation.scss';

const widthBreakpointMd = parseInt(layout['width-breakpoint-md'], 10);
// const { baseClass, getClassName } = getClassNameBuilder('mobile-menu');

const MobileNavigation = ({
  savedAvatar,
  children,
  visible,
  toggleVisible,
  navigationConfig,
  myOrganizations,
  installationLinkConfig,
  pathname,
  loginLinkProps,
  isLoggedIn,
}) => {
  useEffect(() => {
    const closeIfNotMobile = () => {
      if (window.innerWidth > widthBreakpointMd && visible) {
        toggleVisible(false);
      }
    };

    window.addEventListener('resize', closeIfNotMobile);

    return () => window.removeEventListener('resize', closeIfNotMobile);
  }, [visible]);

  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [visible]);

  return (
    <>
      <LinkOrButton
        className="mobile-menu__trigger"
        onClick={() => toggleVisible(!visible)}
      >
        <SvgHamburger />
      </LinkOrButton>
      <div className={classNames('mobile-menu', visible && 'show')}>
        <div className="mobile-menu__header">
          <Flex
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <NavLink
              title="Home"
              to="/"
              className="logo"
              onClick={() => {
                toggleVisible(false);
              }}
            >
              <SvgLogo
                alt="Site logo"
                className="logo"
              />
            </NavLink>

            <Flex
              alignItems="center"
            >
              <Visible when={isLoggedIn}>
                <HeaderAccountManagement
                  savedAvatar={savedAvatar}
                />
              </Visible>
              <LinkOrButton
                className="mobile-menu__close-button"
                onClick={() => toggleVisible(false)}
              >
                <SvgXIcon />
              </LinkOrButton>
            </Flex>
          </Flex>
          <Flex
            justifyContent="space-between"
            paddingBottom="20px"
          >
            <Visible
              fallback={
                <LoginSignup
                  pathname={pathname}
                  loginLinkProps={loginLinkProps}
                />
              }
              when={isLoggedIn}
            >
              <YourOrganizations
                myOrganizations={myOrganizations}
                isDirectLink
                onClick={() => toggleVisible(false)}
              />
            </Visible>
          </Flex>
        </div>
        <TabletLayout
          navigationConfig={navigationConfig}
          setIsOpen={toggleVisible}
          installationLinkConfig={installationLinkConfig}
          onClick={() => toggleVisible(false)}
        >
          <div className="children-container">
            {children}
          </div>
        </TabletLayout>
      </div>
    </>
  );
};

export default MobileNavigation;
