import { mapDomain } from 'lib/utils/map-domain';
import get from 'lodash/get';

const formatDomain = (members, attribute = 'image') => (
  members.map(member => ({
    ...member,
    [attribute]: mapDomain(get(member, [attribute, 'url'], '')),
  }))
);

const dataTransformer = (payload) => {
  if (!Array.isArray(payload)) {
    return [];
  }

  return (
    payload.map(({
      hero,
      panels,
      ...data
    }) => ({
      ...data,
      hero: {
        ...hero,
        image: mapDomain(get(hero, ['image', 'url'], false)),
      },
      panels: formatDomain(panels, 'backgroundImage'),
    }))
  );
};

export {
  dataTransformer,
};
