import React from 'react';
import cx from 'classnames';
import { string } from 'prop-types';

import { authSplash } from 'assets/images';
import './splash-container.scss';

const SplashContainer = ({ className, background, children }) => (
  <div className={cx('splash-container--rct-component', className)} style={{ backgroundImage: `url(${background})` }}>
    {children}
  </div>
);

SplashContainer.propTypes = {
  background: string,
  className: string,
};

SplashContainer.defaultProps = {
  background: authSplash,
  className: null,
};

export {
  SplashContainer,
};
