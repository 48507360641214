import React, { Fragment, Suspense } from 'react';
import { Switch, Route } from 'react-router';
import { HeaderContainer } from 'components/header-container';
import { SplashContainer } from 'components/splash-container';
import { Footer } from 'components/footer';
import {
  Security,
  SecureRoute,
} from '@okta/okta-react';
import { ErrorToast } from 'containers/error-toast';
import { AuthHandler } from 'containers/auth-handler';
import { authService } from 'config/okta-auth-service';
import { routes, secureRoutes } from './routes';
import './root-router.scss';

const renderRoute = (route) => {
  if (route.useSplash) {
    const { component: Component } = route;

    return (
      <Route
        {...route}
        component={props => (
          <SplashContainer>
            <Component {...props} />
          </SplashContainer>
        )}
      />
    );
  }

  return <Route {...route} />;
};

function renderSecureRoute(route) {
  return (
    <SecureRoute {...route} />
  );
}

// TODO: add pkce back?
const RootRouter = () => (
  <Fragment>
    <Security authService={authService}>
      <div className="okta-security-wrapper">
        <div className="content-wrapper">
          <HeaderContainer />
          <Suspense fallback={<div />}>
            <Switch>
              {secureRoutes.map(renderSecureRoute)}
              {routes.map(renderRoute)}
            </Switch>
            <Footer />
          </Suspense>
        </div>
        <ErrorToast />
        <AuthHandler />
      </div>
    </Security>
  </Fragment>
);

export default RootRouter;
