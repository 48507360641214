import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { parseIntlText } from 'translations/keys';
import './tablet-layout.scss';
import { NavLink } from '../../nav-link';
import { MobileItems } from './mobile-items/mobile-items';

const TabletLayout = ({
  children,
  intl,
  navigationConfig,
  onClick,
}) => {
  const click = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="tablet-layout">
      <div className="tablet-items">
        {navigationConfig.map((item) => {
          if (item.noMenu) {
            return null;
          }

          return (
            <div key={item.title} className="category">
              <h5>
                {parseIntlText(intl, item.title)}
              </h5>
              {item?.links?.map((link, index) => (
                <NavLink
                  className="category-item"
                  key={index}
                  to={link.to}
                  onClick={() => click(link.to, link.external)}
                  external={link.external}
                >
                  {parseIntlText(intl, link.title)}
                </NavLink>
              ))}
            </div>
          );
        })}
      </div>
      <MobileItems
        intl={intl}
        onClick={onClick}
        navigationConfig={navigationConfig}
      />
      <div className="footer-items">
        {children}
      </div>
    </div>
  );
};

/* eslint-disable react/forbid-prop-types */
TabletLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  navigationConfig: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

TabletLayout.defaultProps = {
  children: {},
  onClick: () => {},
};

export default compose(
  injectIntl,
)(TabletLayout);
