import env from '@beam-australia/react-env';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { init } from '@sentry/browser';
import configureStore from 'rdx/configure-store';
import { CookiesProvider } from 'react-cookie';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import ReactGA from 'react-ga';
import { ConnectedIntlProvider } from 'containers/intl-provider';
import { RootHelmet } from 'containers/root-helmet';
import { RootRouter } from 'containers/root-router';
import { ErrorBoundary } from 'containers/error-boundary';

import './app.scss';

lazySizes.init();

ReactGA.initialize(env('GA_ID'));

const sentryConfig = {
  dsn: (env('NODE_ENV') === 'production' && window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1')
    ? env('SENTRY_DSN') : null,
};
const { CI_PIPELINE_ID } = process.env;
if (CI_PIPELINE_ID) {
  const release = `public-www@${CI_PIPELINE_ID}`;
  console.log('Release Version', release);

  sentryConfig.release = release;
}
init(sentryConfig);

const { store } = configureStore();

// IMPORTANT: persistgate will save the state into localStorage
// and load it from there; to use a new initialState, go to
// console -> Application Tab -> Local Storage
// remove "persist:root" and reload the app
const App = () => (
  <Provider store={store}>
    <ConnectedIntlProvider>
      <CookiesProvider>
        <Router>
          <ErrorBoundary>
            <RootHelmet />
            <RootRouter />
          </ErrorBoundary>
        </Router>
      </CookiesProvider>
    </ConnectedIntlProvider>
  </Provider>
);

export { App };
