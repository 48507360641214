import { defineMessages } from 'react-intl';

const base = 'login.';

export const LOGIN = defineMessages({
  LOGIN_ERROR: {
    id: `${base}login-failed`,
    description: 'Error text when login fails',
    defaultMessage: 'Sign in failed, username or password was incorrect.',
  },
  CREATE_NEW_ACCOUNT: {
    id: `${base}create-new-account`,
    description: 'Create new account message',
    defaultMessage: 'Create a new account',
  },
  GREETING: {
    id: `${base}greeting`,
    description: 'Login greeting message',
    defaultMessage: 'Welcome back',
  },
  FORGOT_PASSWORD: {
    id: `${base}forgot-password`,
    description: 'Login forgot password link',
    defaultMessage: 'Forgot password?',
  },
  RESET: {
    id: `${base}reset`,
    description: 'Reset text',
    defaultMessage: 'Reset',
  },
  FORGOT_PASSWORD_DESCRIPTION: {
    id: `${base}forgot-password-description`,
    description: 'Description for forgot password link',
    defaultMessage: 'We will send you an email to reset your password',
  },
  GO_TO_LOGIN: {
    id: `${base}go-to-login`,
    description: 'Go to login text',
    defaultMessage: 'Go to login',
  },
  CREATE_NEW_PASSWORD: {
    id: `${base}create-new-password`,
    description: 'Create a new password text',
    defaultMessage: 'Create new password',
  },
  CONFIRM_PASSWORD: {
    id: `${base}confirm-password`,
    description: 'Confirm password text',
    defaultMessage: 'confirm password',
  },
  PASSWORD_RESET: {
    id: `${base}password-reset`,
    description: 'Password reset confirmation text',
    defaultMessage: 'Password reset.',
  },
});
