import env from '@beam-australia/react-env';
import { AuthService } from '@okta/okta-react';
import Cookies from 'js-cookie';

const config = {
  issuer: `${env('OKTA_URL')}/oauth2/${env('OKTA_ISSUER_AUDIENCE')}`,
  clientId: env('OKTA_CLIENT_ID'),
  redirectUri: `${window.location.origin}/implicit/callback`,
  audience: env('OKTA_AUDIENCE'),
  pkce: false,
  postLogoutRedirectUri: `${window.location.origin}`,
};

const COOKIE_KEY = 'openraven_logged_out';
const DOMAIN = '.openraven.com';

export const removeLoggedOutCookie = () => {
  Cookies.remove(COOKIE_KEY, { domain: DOMAIN });
};

// Cookie to tell other domains to logout such as IDEAS portal
export const setLoggedOutCookie = () => {
  Cookies.set(COOKIE_KEY, 'true', { domain: DOMAIN });
};

export const authService = new AuthService(config);
