import { defineMessages } from 'react-intl';

const base = 'library-page';

export const LIBRARY = defineMessages({
  DOWNLOAD: {
    id: `${base}.download`,
    description: 'Download text for library',
    defaultMessage: 'Download',
  },
  READ_MORE: {
    id: `${base}.read-more`,
    description: 'Read More Button Text',
    defaultMessage: 'Read More',
  },
  WRITTEN_BY: {
    id: `${base}.written-by`,
    description: 'written by text',
    defaultMessage: 'Written By',
  },
  HEADLINE: {
    id: `${base}.headline`,
    description: 'headline text',
    defaultMessage: 'White Papers and Data Sheets',
  },
  CAPTION: {
    id: `${base}.caption`,
    description: 'caption text',
    defaultMessage: 'Library',
  },
  DATA_SHEETS: {
    id: `${base}.data sheets`,
    description: 'data sheets label',
    defaultMessage: 'Data Sheets',
  },
  WHITE_PAPERS: {
    id: `${base}.white-papers`,
    description: 'white papers label',
    defaultMessage: 'White Papers',
  },
});
