import { unpackCopy, unpackImage } from 'lib/utils/contentful';
import {
  kara as karaPicture,
} from 'assets/images';

const findPerson = (name, people) => people.find(person => person.name === name);

const dataTransformer = (_payload) => {
  let payload = _payload;

  if (!payload) {
    return [];
  }

  if (!Array.isArray(payload)) {
    payload = [{ ...payload }];
  }

  return (
    payload.map(({
      fields,
    }) => {
      const description = unpackCopy(fields?.copy);
      const gallery = fields?.images?.map(image => ({ alt: image?.fields?.description, image: unpackImage(image) }));
      const panels = fields?.panels?.map(panel => (
        {
          ...panel.fields,
          image: unpackImage(panel?.fields?.image?.[0]),
          images: panel?.fields?.image?.map(image => ({
            photo: unpackImage(image),
            title: image?.fields?.title,
          })),
          copy: unpackCopy(panel?.fields?.copy),
        }
      ));

      const investors = panels.find(panel => panel.name === 'Investors');

      const people = fields?.people?.map(({ fields: person }) => {
        const {
          bio,
          image,
          title,
          ...rest
        } = person;
        const personBio = unpackCopy(bio);
        const picture = unpackImage(image);
        return ({
          picture,
          position: title,
          description: personBio,
          ...rest,
        });
      });

      const ourValues = panels.find(panel => panel.name === 'Our Values');
      const bele = panels.find(panel => panel.name === 'Bele');

      // make team members and investors
      const dave = findPerson('Dave Cole', people);
      const mark = findPerson('Mark Curphey', people);
      const alan = findPerson('Alan Buckley', people);
      const rob = findPerson('Rob Markovich', people);
      const kara = findPerson('Kara Nortman', people);
      const bucky = findPerson('Bucky Moore', people);
      kara.picture = karaPicture;
      const team = [dave, mark, alan, rob];
      const board = [kara, bucky, dave, mark];

      return (
        {
          ...fields,
          description,
          gallery,
          ourValues,
          bele,
          team,
          board,
          investors,
        }
      );
    })
  );
};

export {
  dataTransformer,
};
