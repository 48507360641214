import { createStore, compose, applyMiddleware } from 'redux';
import compileReducers from 'rdx/reducers';
import thunk from 'redux-thunk';

const initialState = {};

const configureStore = () => {
  const middlewares = [
    thunk,
  ];
  const reducer = compileReducers();
  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  const store = createStore(
    reducer,
    initialState,
    compose(...enhancers)
  );

  return { store };
};

export default configureStore;
