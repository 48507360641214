const santize = /\s/g;

const FOOTER = 'footer';
const HEADER = 'header';
const LINK = 'link';
const BUTTON = 'button';

export const IDENTIFIERS = {
  FOOTER,
  HEADER,
  LINK,
  BUTTON,
};

export const makeId = (data) => {
  if (!data) {
    console.info('no data recieved to make id');
    return;
  }
  const { name, prefix = '', suffix = '' } = data;
  if (!name) {
    console.error('No name provided to make id');
    return;
  }
  let results = `${prefix} ${name} ${suffix}`;

  results = results.trim().replace(santize, '-').toLowerCase();
  return results;
};
