/**
 * @fileoverview ClassName util to simplify nesting classes
 * @author Gabriel Womble
 */

export function getClassNameBuilder(baseClass) {
  return {
    baseClass,
    getClassName: (...classNames) => `${baseClass}__${classNames.join('__')}`,
    getClassNames: (...classNames) => classNames.map(className => `${baseClass}__${className}`).join(' '),
  };
}
