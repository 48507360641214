import { authService } from 'config/okta-auth-service';

export const DEFAULT_POST_LOGIN_ROUTE = '/account-management/my-workspaces';

const login = async () => authService.login();

export async function handleUnauthorized({
  axiosError,
  postLoginPath = DEFAULT_POST_LOGIN_ROUTE,
  noLoginOnUnauthorized,
}) {
  if (axiosError?.response?.status !== 401) {
    return false;
  }

  try {
    console.log('Rechecking auth state on 401 unauthorized error');

    // Refresh auth state to see if tokens can be refreshed
    await authService.updateAuthState();
    const authState = authService.getAuthState() || {};

    const { isAuthenticated } = authState;

    if (!isAuthenticated && !noLoginOnUnauthorized) {
      await login(postLoginPath);

      return true;
    }
  } catch (err) {
    console.error('Encountered error while updating authentication state', err);

    if (!noLoginOnUnauthorized) {
      console.log('Reinitializing login');
      await login(postLoginPath);
      return true;
    }
  }

  // No remedial action taken, bubble up true to handle other errors normally
  return false;
}
