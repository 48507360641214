export const ROUTE = {
  HOME: '/',
  LOGOUT: '/logout',
  INSTALL: '/install',
  ENTERPRISE_INSTALL: '/enterprise-install',
  PRIVACY: '/privacy',
  SUBSCRIBE: '/subscribe/:hash',
  UNSUBSCRIBE_HASH: '/unsubscribe/:hash',
  UNSUBSCRIBE: '/unsubscribe',
  LOGIN: '/login',
  LOGIN_REDIRECT: '/login/redirect',
  SIGN_UP: '/sign-up',
  CREATE_ACCOUNT: '/create-account/:activationToken',
  FORGOT_PASSWORD_TOKEN: '/forgot-password/:recoveryToken',
  FORGOT_PASSWORD: '/forgot-password',
  IMPLICIT_CALLBACK: '/implicit/callback',
  IMPLICIT_CALLBACK_REDIRECT: '/implicit/callback/redirect',
  ACCOUNT_MANAGEMENT: '/account-management',
  ACCESS_DENIED: '/access-denied',
  NO_ACCESS: '/no-access',
  NOT_FOUND: '/not-found',
  MY_WORKSPACES: '/account-management/my-workspaces',
};
