import { combineReducers } from 'redux';
import apiReducers from 'rdx/modules/api/reducers';
import appReducers from 'rdx/modules/app/reducers';
import authReducers from 'rdx/modules/auth/reducers';
import messageReducers from 'rdx/modules/messages/reducers';
import localeReducers from 'rdx/modules/locale/reducers';
// IMPORT_PT (for script -- do not remove!)

export const reducers = {
  ...apiReducers,
  ...appReducers,
  ...authReducers,
  ...messageReducers,
  ...localeReducers,
// INSERTION_PT (for script -- do not remove!)
};

export default function compileReducers() {
  return combineReducers(reducers);
}
