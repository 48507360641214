export { default as aboutBannerBird } from './about-banner-bird.svg';
export { default as aboutBirds } from './about-birds.svg';
export { default as earthBird } from './earth-bird.svg';
export { default as aboutHeroShapesLeft } from './about-hero-shapes-left.png';
export { default as aboutHeroShapesRight } from './about-hero-shapes-right.png';
export { default as aboutBele } from './about-bele.gif';
export { default as authSplash } from './auth_splash.png';
export { default as animatedBele } from './animated-bele.gif';
export { default as aboutLandscapeBird } from './about-landscape-bird.png';
export { default as aboutYou } from './about-you.png';
export { default as book } from './book.svg';
export { default as active, ReactComponent as Active } from './active.svg';
export { default as inactive, ReactComponent as Inactive } from './inactive.svg';
export { default as aws } from './aws.svg';
export { default as azure } from './azure.svg';
export { default as box, ReactComponent as Box } from './box.svg';
export { default as clipIcon } from './clip-icon.svg';
export { default as landscapeBg } from './landscape-bg.svg';
export { default as suitcase } from './suitcase.svg';
export { default as downloadBig } from './download-big.svg';
export { default as downloadSmall } from './download-small.svg';
export { default as logo } from './logo.svg';
export { default as logoPng } from './logo.jpg';
export { default as whiteLogo } from './white-logo.svg';
export { default as scrollIcon } from './scroll-icon.svg';
export { default as cloudArchitecture } from './cloud_architecture.png';
export { default as notification, ReactComponent as Notification } from './notification.svg';
export { default as confirmationIcon } from './confirmation-icon.svg';
export { default as connectLight } from './connect_light.svg';
export { default as connect, ReactComponent as SvgConnect } from './connect.svg';
export { default as closeIcon, ReactComponent as CloseIconSvg } from './close-icon.svg';
export { default as deployAws } from './deploy_aws.png';
export { default as docker } from './docker.svg';
export { default as remoteLocations } from './remote-locations.svg';
export { default as ravenProfile } from './raven-profile.svg';
export { default as venice } from './venice.png';
export { default as work } from './work.svg';
export { default as laOutside } from './la-outside.png';
export { default as ravensOnWire } from './ravens-on-wire.svg';
export { default as downloadLight } from './download-light.svg';
export { default as downloadForLibrary } from './download-for-library.svg';
export { default as installNavLogo } from './install-nav-logo.svg';
export { default as key } from './key.svg';
export { default as mailIcon } from './mail-icon.svg';
export { default as organizationLight } from './organization_light.svg';
export { default as download, ReactComponent as SvgDownload } from './download.svg';
export { default as organization, ReactComponent as SvgOrganization } from './organization.svg';
export { default as raven } from './raven.svg';
export { default as ravens } from './ravens.svg';
export { default as sourceCode } from './source-code.svg';
export { default as upload } from './upload.svg';
export { default as perchedRaven } from './perched-raven.svg';
export { default as flyingRaven } from './flying-raven.svg';
export { default as libraryHeaderLeft } from './library-header-left.svg';
export { default as libraryHeaderRight } from './library-header-right.svg';
export { default as mountains } from './mountains.svg';
export { default as mountainsMobile } from './mountains-mobile.svg';
export { default as footerMountain } from './footer-mountain.svg';
export { default as footerMountainMobile } from './footer-mountain-mobile.svg';
// TODO: Move this to the common UI components lib
export { default as testAccountAvatar } from './test-account-avatar.png';
export { default as cameraIcon } from './camera-icon.svg';
export { default as infoIcon } from './info-icon.svg';
export { default as dragAndDropCircle } from './drag-and-drop-circle.svg';
export { default as ravenChooseAvatar } from './raven-choose-avatar.svg';
export { default as eyeballIcon } from './eyeball-icon.svg';
export { default as chooseAvatarRaven1 } from './choose-avatar-raven-1.svg';
export { default as chooseAvatarRaven2 } from './choose-avatar-raven-2.svg';
export { default as chooseAvatarRaven3 } from './choose-avatar-raven-3.svg';
export { default as chooseAvatarRaven4 } from './choose-avatar-raven-4.svg';
export { default as chooseAvatarRaven5 } from './choose-avatar-raven-5.svg';
export { default as chooseAvatarRaven6 } from './choose-avatar-raven-6.svg';
export { default as leftArrowIcon } from './left-arrow-icon.svg';
export { default as emailSentIcon } from './email-sent-icon.svg';
export { default as accountCreatedIcon } from './account-created-icon.svg';
export { default as checkMarkIcon } from './check-mark.svg';
export { default as copyIcon } from './copy-icon.svg';
export { default as warningIcon, ReactComponent as WarningIcon } from './warning-icon.svg';
export { default as facebookIcon } from './facebook-icon.svg';
export { default as linkedInIcon } from './linkedIn-icon.svg';
export { default as mailFavicon } from './mail-favicon.svg';
export { default as plusIcon } from './plus-icon.svg';
export { default as rssIcon, ReactComponent as RssIcon } from './rss-icon.svg';
export { default as twitterIcon } from './twitter-icon.svg';
export { default as xIcon } from './x-icon.svg';
export { default as installIcon } from './install-icon.svg';
export { default as speechBubbleIcon } from './speech-bubble-icon.svg';
export { default as awsAmi } from './aws-ami.svg';
export { default as installTrianglesLeft } from './install-triangles-left.svg';
export { default as installTrianglesRight } from './install-triangles-right.svg';
export { default as addRole } from './add-role.svg';
export { default as rightArrowIcon } from './right-arrow-icon.svg';
export { default as noEntry } from './no-entry.svg';
export { default as notFoundBackground } from './not-found-background.png';
export { default as contactTitleBackgroundLeft } from './contact-title-background-left.svg';
export { default as contactTitleBackgroundRight } from './contact-title-background-right.svg';
export { default as contactMailRaven } from './contact-mail-raven.svg';
export { default as investorShapesLeft } from './investor-shapes-left.png';
export { default as investorShapesRight } from './investor-shapes-right.png';
export { default as viewDemoShapesLeft } from './view-demo-shapes-left.svg';
export { default as viewDemoShapesRight } from './view-demo-shapes-right.svg';
export { default as document } from './document.svg';
export { default as greenCheck } from './green-check.svg';
export { default as padlock } from './padlock.svg';
export { default as visualize } from './visualize.svg';
export { default as awsEc2 } from './aws_ec2.png';
export { default as awsRds } from './aws_rds.png';
export { default as awsS3 } from './aws_s3.png';
export { default as azureCompute } from './azure_compute.png';
export { default as slack } from './slack.png';
export { default as testimonialTriangles } from './testimonial_triangles.png';
export { default as IntelliSecure } from './IntelliSecure.png';
export { default as sauceLabs, ReactComponent as SvgSauceLabsLogoIcon } from './sauce-labs-logo.svg';
export { default as eaSports } from './ea_sports.png';
export { default as cluster } from './cluster.png';
export { default as platformIcon } from './platform_icon.svg';
export { default as homepageHero } from './homepage_hero.png';
export { default as homepageHeroCorner } from './homepage_hero_corner.png';
export { default as productPageShapes } from './product-page-shapes.png';
export { default as homepage3dGif } from './homepage_3d.gif';
export { default as eyeballHideIcon } from './eyeball-hide-icon.svg';
export { default as installHeaderBg } from './install-header-bg.png';
export { default as installFlowBg } from './install_flow_bg.png';
export { default as installLandingHero } from './install_landing_hero.png';
export { default as platformShapesRight } from './platform_shapes_right.png';
export { default as platformShapesLeft } from './platform_shapes_left.png';
export { default as openSourceCore } from './open_source_core.png';
export { default as platformGraph } from './platform_graph.png';
export { default as ravenTreeGraph } from './raven_tree_graph.png';
export { default as fastIcon } from './fast_icon.svg';
export { default as openIcon } from './open_icon.svg';
export { default as flexibleIcon } from './flexible_icon.svg';
export { default as privateIcon } from './private_icon.svg';
export { default as principlesShapesRight } from './principles_shapes_right.png';
export { default as principlesShapesLeft } from './principles_shapes_left.png';
export { default as connectorsLeftShapes } from './connectors-left-shapes.svg';
export { default as connectorsRightShapes } from './connectors-right-shapes.svg';
export { default as connectorsHeaderIcon } from './connectors-header-icon.svg';
export { default as honeyIcon } from './honey-icon.png';
export { default as asanaIcon } from './asana-icon.png';
export { default as googleAppsIcon } from './google-apps-icon.png';
export { default as zapierIcon } from './zapier-icon.png';
export { default as segementIcon } from './segement-icon.png';
export { default as campfireIcon } from './campfire-icon.png';
export { default as niceReplyIcon } from './nice-reply-icon.png';
export { default as magentoIcon } from './magento-icon.png';
export { default as fullstoryIcon } from './fullstory-icon.png';
export { default as onepageCrmIcon } from './onepage-crm-icon.png';
export { default as wooIcon } from './woo-icon.png';
export { default as paperclipIcon, ReactComponent as SvgPaperclip } from './paperclip_icon.svg';
export { default as installCTALeft } from './install_cta_left.svg';
export { default as installCTARight } from './install_cta_right.svg';
export { default as AWSInstall1 } from './aws_install_1.png';
export { default as AWSInstall2 } from './aws_install_2.png';
export { default as AWSInstall3 } from './aws_install_3.png';
export { default as AWSInstall4 } from './aws_install_4.png';
export { default as AWSInstall5 } from './aws_install_5.png';
export { default as AWSInstall6 } from './aws_install_6.png';
export { default as AWSInstall7 } from './aws_install_7.png';
export { default as AWSInstall8 } from './aws_install_8.png';
export { default as playButtonIcon } from './play-button.svg';
export { default as demoShapesLeft } from './demo-shapes-left.svg';
export { default as demoShapesRight } from './demo-shapes-right.svg';
export { default as whiteRightArrow } from './white-right-arrow.svg';
export { default as confirmDemoShapesLeft } from './confirm-demo-shapes-left.svg';
export { default as confirmDemoShapesRight } from './confirm-demo-shapes-right.svg';
export { default as laptopIcon } from './laptop-icon.svg';
export { default as errorImage } from './error.png';
export { default as awsBackupIcon } from './aws-backup.png';
export { default as awsDocumentDbIcon } from './aws-document-db.png';
export { default as awsDynamoDbIcon } from './aws-dynamo-db.png';
export { default as awsElasticBlockStoreIcon } from './aws-elastic-block-store.png';
export { default as awsElasticFileSystemIcon } from './aws-elastic-file-system.png';
export { default as awsElasticSearchServiceIcon } from './aws-elasticsearch-service.png';
export { default as awsFsxIcon } from './aws-fsx.png';
export { default as awsRedshiftIcon } from './aws-redshift.png';
export { default as connectorDetailsIcon } from './connector-details-icon.png';
export { default as valuesSectionShapesLeft } from './values_section_shapes_left.png';
export { default as valuesSectionShapesRight } from './values_section_shapes_right.png';
export { default as discoverBackgroundImage } from './WhatWeDicover-BKGHeader.jpg';
export { default as laptop, ReactComponent as SvgLaptop } from './laptop.svg';
export { default as salesPerson, ReactComponent as SvgSalesPerson } from './sales-person.svg';
export { default as downloadCloud, ReactComponent as SvgDownloadCloud } from './download-cloud.svg';
export { default as arrowRight, ReactComponent as SvgArrowRight } from './arrow-right.svg';
export { default as dataLeakIcon } from './homepage/data-leak-monitoring-icon.png';
export { default as dataLeakScreen } from './homepage/data-leak-monitoring-screen.png';
export { default as dataLeakScreenOptimized } from './homepage/data-leak-monitoring-screen.jpg';
export { default as everyAccountIcon } from './homepage/every-account-icon.png';
export { default as everyAccountScreen } from './homepage/every-account-screen.png';
export { default as everyAccountScreenOptimized } from './homepage/every-account-screen.jpg';
export { default as exploreIcon } from './homepage/explore-icon.png';
export { default as exploreScreen } from './homepage/explore-screen.png';
export { default as exploreScreenOptimized } from './homepage/explore-screen.jpg';
export { default as identificationIcon } from './homepage/identification-icon.png';
export { default as identificationScreen } from './homepage/identification-screen.png';
export { default as identificationScreenOptimized } from './homepage/identification-screen.jpg';
export { default as rightPolygonPattern } from './right-square-pattern.png';
export { default as leftPolygonPattern } from './left-square-pattern.png';
export { default as radarIcon, ReactComponent as SvgRadarIcon } from './radar.svg';
export { default as eyeCloud, ReactComponent as SvgEyeCloudIcon } from './eye-cloud.svg';
export { default as gem, ReactComponent as SvgGemIcon } from './gem.svg';
export { default as pinpoint, ReactComponent as SvgPinpointIcon } from './pinpoint.svg';
export { default as identifyDataStores, ReactComponent as SvgIdentifyDataStoresIcon } from './identify-datastores.svg';
export { default as proGem, ReactComponent as SvgProGemIcon } from './pro-gem.svg';
export { default as triCircles, ReactComponent as SvgTriCirclesIcon } from './tri-circles.svg';
export { default as foldedPage, ReactComponent as SvgFoldedPageIcon } from './folded-page.svg';
export { default as circlePage, ReactComponent as SvgCirclePageIcon } from './circle-page.svg';
export { default as previewIcon, ReactComponent as SvgPreviewIcon } from './preview.svg';
export { default as discoverIcon, ReactComponent as SvgDiscoverIcon } from './discover.svg';
export { default as classifyIcon, ReactComponent as SvgClassifyIcon } from './classify.svg';
export { default as monitorIcon, ReactComponent as SvgMonitorIcon } from './monitor.svg';
export { default as monitorWithFieldIcon, ReactComponent as SvgMonitorWithFieldIcon } from './monitor-with-field-icon.svg';
export { default as integrationsIcon, ReactComponent as SvgIntegrationsIcon } from './integrations.svg';
export { default as fingerprintIcon, ReactComponent as SvgFingerprintIcon } from './fingerprint.svg';
export { default as discoverWithFieldIcon, ReactComponent as SvgDiscoverWithField } from './discover-with-field-icon.svg';
export { default as bell, ReactComponent as SvgBellIcon } from './bell.svg';
export { default as discoverIconsRight } from './discover-icons-right.png';
export { default as discoverIconsLeft } from './discover-icons-left.png';
export { default as productDiscoverBackground } from './product-discover-background.jpg';
export { default as productCommunity } from './product-community-image.jpg';
export { default as productPro } from './product-pro-image.jpg';
export { default as productHeaderBackground } from './product-header-background.jpg';
export { default as blueSquarePolygons } from './blue-square-polygons.png';
export { default as platformOverviewHero } from './platform-overview-hero.jpg';
export { default as platformOverviewImageOne } from './platform-overview-image-1.png';
export { default as platformOverviewImageTwo } from './platform-overview-image-2.png';
export { default as leftSquares } from './left-squares.svg';
export { default as quoteLeftCircles } from './quote-left-circles.svg';
export { default as quoteRightSquares } from './quote-right-squares.svg';
export { default as platformImage } from './platform-image.jpg';
export { default as graphImage } from './graph-image.svg';
export { default as leftPolygonSquares } from './yellow-square-polygons.png';
export { default as rightPolygonSquares } from './yellow-square-right-polygons.png';
export { default as locateIcon, ReactComponent as SvgLocateIcon } from './edition-locate-icon.svg';
export { default as editionCubesBg } from './edition-cubes-bg.jpg';
export { default as editionScapeBg } from './edition-scape-bg.jpg';
export { default as identificationScreenshot } from './identification-screenshot.png';
export { default as prioritizeScreenshot } from './prioritize-screenshot.png';
export { default as everyAccountSreenshot } from './every-account-screenshot.png';
export { default as monitoringScreenshot } from './monitoring-screenshot.png';
export { default as curatedQueries } from './curated-queries.jpg';
export { default as visualizations } from './visualizations.jpg';
export { default as partners } from './partners.jpg';
export { default as pePageImageOne } from './pe-page-image-1.jpg';
export { default as pePageImageTwo } from './pe-page-image-2.jpg';
export { default as pePageImageThree } from './pe-page-image-3.jpg';
export { default as pePageImageFour } from './pe-page-image-4.jpg';
export { default as pePageImageFive } from './pe-page-image-5.jpg';
export { default as peLeftSquares } from './pe-left-squares.svg';
export { default as greyscaleSquarePolygonsLeft } from './greyscale-square-polygons-left.svg';
export { default as greyscaleSquarePolygonsRight } from './greyscale-square-polygons-right.svg';
export { default as dataSheetIcon } from './data-sheet-icon.svg';
export { default as videoPlayIcon, ReactComponent as SvgVideoPlayIcon } from './video-play-icon.svg';
export { default as whitePaperIcon } from './white-paper-icon.svg';
export { default as webinarIcon, ReactComponent as SvgWebinarIcon } from './webinar-icon.svg';
export { default as blueDownloadIcon } from './blue-download-icon.svg';
export { default as newsBackgroundImage } from './news-header-image.jpg';
export { default as mediaKitHeaderBg } from './media-kit-header-background.jpg';
export { default as misconfigureIcon, ReactComponent as MisconfigureIcon } from './misconfigure.svg';
export { default as shadowIcon, ReactComponent as ShadowIcon } from './shadow.svg';
export { default as sprawl, ReactComponent as SprawlIcon } from './sprawl.svg';
export { default as communityUseCase1 } from './community-use-case-image-1.jpg';
export { default as communityUseCase2 } from './community-use-case-image-2.jpg';
export { default as communityUseCase3 } from './community-use-case-image-3.jpg';
export { default as homeVideoPoster } from './homepage/home-video-poster.png';
export { default as daveC } from './dave-c.png';
export { default as markC } from './mark-c.png';
export { default as kara } from './kara.png';
export { default as phil } from './phil.png';
export { default as contactPageHeaderBackgroundImage } from './contact-page-header-background-image.jpg';
export { default as newVideoButton } from './new-video-button.jpg';
export { default as newVideoButtonWebp } from './new-video-button.webp';
export { default as faqPageHeaderBackgroundImage } from './faq-page-header-background-image.jpg';
export { default as Q, ReactComponent as QIcon } from './Q.svg';
export { default as A, ReactComponent as AIcon } from './A.svg';
export { default as smallCloseIcon, ReactComponent as SmallCloseIconSvg } from './small-close-icon.svg';
export { default as testimonialBlocksLeft } from './testimonial-blocks-left.svg';
export { default as testimonialBlocksRight } from './testimonial-blocks-right.svg';
export { default as requestSubmittedBackground } from './request-submitted-background.png';
export { default as requestSubmittedMobileBackground } from './request-submitted-mobile-background.png';
export { default as trialButtonBackground } from './trial-button-bg.png';
export { default as blueTrianglesLeft } from './blue-triangles-left.png';
export { default as blueTrianglesRight } from './blue-triangles-right.png';
export { default as gatedAssetsTrianglesRight } from './gated-asset-triangles-right.png';
export { default as gatedAssetsTrianglesLeft } from './gated-asset-triangles-left.png';
export { default as solutionMountains, ReactComponent as SvgSolutionMountains } from './solution-mountains.svg';
export { default as documentStackIcon, ReactComponent as DocumentStackIcon } from './document-stack-icon.svg';
export { default as demoClassify, ReactComponent as DemoClassify } from './demo-classify.png';
export { default as demoDiscover, ReactComponent as DemoDiscover } from './demo-discover.png';
export { default as demoMonitor, ReactComponent as DemoMonitor } from './demo-monitor.png';
export { default as demoProtect, ReactComponent as DemoProtect } from './demo-protect.png';
export { default as discoverCategoryIcon, ReactComponent as DiscoverCategoryIcon } from './discover-icon.png';
export { default as classifyCategoryIcon, ReactComponent as ClassifyCategoryIcon } from './classify-icon.png';
export { default as monitorCategoryIcon, ReactComponent as MonitorCategoryIcon } from './monitor-icon.png';
export { default as protectCategoryIcon, ReactComponent as ProtectCategoryIcon } from './protect-icon.png';
export { default as logoWhite, ReactComponent as LogoWhite } from './logo-white.svg';
export { ReactComponent as FacebookIconWhite } from './facebook.svg';
export { ReactComponent as InstagramIconWhite } from './instagram.svg';
export { ReactComponent as TwitterIconWhite } from './twitter.svg';
export { ReactComponent as LinkedInIconWhite } from './linkedin.svg';
export { ReactComponent as OctocatIconWhite } from './octocat.svg';
