export const BASE_NAVIGATION_CONFIG = [
  {
    title: 'My Workspaces',
    key: '/account-management/my-workspaces',
    links: [
    ],
  },
  {
    title: 'Profile',
    key: '/account-management/profile-settings',
    links: [
    ],
  },
];

export const MOBILE_NAVIGATION_CONFIG = [
  {
    title: 'My Workspaces',
    to: '/account-management/my-workspaces',

  },
  {
    title: 'Profile',
    to: '/account-management/profile-settings',

  },
];
