import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import Modal from 'react-modal';
import { App } from './app';
import { initModernizr } from './modernizr-webp';
import { unregister } from './lib/register-service-worker';
import './translations/config';

const fontsLoader = document.getElementById('fonts-loader');

if (fontsLoader !== null) {
  fontsLoader.removeAttribute('media');
}

const rootElement = document.getElementById('root');

Modal.setAppElement(rootElement);

render(<App />, rootElement);

if (module.hot) {
  module.hot.accept('./app', () => {
    const Application = require('./app');
    render(
      <Application />,
      rootElement
    );
  });
}

initModernizr();

/**
 * the following line opts us into to PWA behavior.
 * https://facebook.github.io/create-react-app/docs/making-a-progressive-web-app
 *
 * This line only runs in production. To see it in action,
 * build and serve from the build directory first.
 *
 * To take full advantage of what registering the service worker does for us,
 * you will want to change the manifest.json file in /public.
 * This starter kit, by default, is set to use few of these capabilities
 * in order to be unopinionated.
 *
 * https://developers.google.com/web/fundamentals/web-app-manifest/
 *
 */
//registerServiceWorker();
unregister();
