import { unpackImage, unpackCopyToPlainText, unpackCopyToReactComponent } from 'lib/utils/contentful';
import { blogConstants } from 'constants';

const { defaultTag } = blogConstants;

// TODO: ENG-60 - implement search with algolia.
const filterByPublished = blogs => blogs.filter(blog => blog.published);
const filterByTag = (blogs, selectedTag) => (
  selectedTag !== defaultTag.name ? blogs.filter(({ tags }) => (
    tags.some(({ name }) => name === selectedTag)
  )) : blogs
);

const dateSorter = (a, b) => new Date(b.date) - new Date(a.date);
const filterBlogs = (blogs, selectedTag = defaultTag.name) => {
  const filteredBlogs = filterByTag(filterByPublished(blogs), selectedTag);
  // Do some sorting beforehand
  const sortedBlogs = filteredBlogs.sort(dateSorter);

  // Find featured blog post if any and put it at the front
  for (let i = 0; i < sortedBlogs.length; i += 1) {
    if (sortedBlogs[i].featured) {
      sortedBlogs.unshift(sortedBlogs[i]);
      // delete
      sortedBlogs.splice(i + 1, 1);
      break;
    }
  }

  return sortedBlogs;
};

const makeAuthor = (author) => {
  const authorName = author.fields?.name; //get(author, 'fields', 'name');
  const jobTitle = author.fields?.title; //get(author, 'fields', 'title');
  const email = author.fields?.email; // get(author, 'fields', 'email');
  const linkedInUrl = author.fields?.linkedIn; // get(author, 'fields', 'linkedInUrl');
  const shortBio = author.fields?.shortBio; // get(author, 'fields', 'shortBio');
  const profileImage = unpackImage(author.fields?.image); //get(author, 'fields', 'profileImage', {})

  return {
    authorName,
    jobTitle,
    email,
    linkedInUrl,
    profileImage,
    shortBio,
  };
};

const removeTagsHoverColor = tags => tags.map((tag) => {
  if (tag && tag.fields) {
    const {
      name,
      slug,
      color,
    } = tag.fields;

    return { name, slug, color };
  }

  throw new Error('Improprly formatted tag object');
});

const dataTransformer = (blogPayload, selectedTag/*, localComments*/) => {
  if (!Array.isArray(blogPayload)) {
    return [];
  }

  const blogs = blogPayload.map((item) => {
    const {
      sys: {
        id,
      },
    } = item;

    const blog = item.fields;
    const {
      date,
      featured,
      slug,
      title,
    } = blog;

    const author = makeAuthor(blog.author);
    const bodyText = unpackCopyToReactComponent(blog.bodyText);
    const content = unpackCopyToPlainText(blog.bodyText);
    const previewText = unpackCopyToPlainText(blog.previewText);
    const headerImage = unpackImage(blog.headerImage);
    const tags = removeTagsHoverColor(blog.tags);
    const blogUrl = `${window.location.origin}/blog/${slug || id}`;

    return {
      id,
      author,
      date,
      featured,
      slug,
      bodyText,
      content,
      previewText,
      headerImage,
      published: true,
      tags,
      title,
      blogUrl,
    };
  });

  return filterBlogs(blogs, selectedTag);
};

export {
  dataTransformer,
};
