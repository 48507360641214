import { defineMessages } from 'react-intl';

const BASE = 'install-landing';

export const INSTALL_LANDING = defineMessages({
  ARCHITECTURE_LABEL: {
    id: `${BASE}.architecture-label`,
    description: 'Architecture label text',
    defaultMessage: 'Deployment architecture',
  },
  ARCHITECTURE_PARAGRAPH: {
    id: `${BASE}.architecture-paragraph`,
    description: 'Architecture paragraph text',
    defaultMessage: 'The Open Raven platform deploys in your AWS cloud within minutes. Your data stays with you and any access to scan your infrastructure is only visible to you. Kubernetes manages the environment for you and our platform management technology updates your instance so you are always running the latest version.',
  },
  ARCHITECTURE_TITLE: {
    id: `${BASE}.architecture-title`,
    description: 'Architecture title text',
    defaultMessage: 'Modern on-prem',
  },
  AWS_STEP_ONE: {
    id: `${BASE}.aws-step-one`,
    description: 'AWS First step text',
    defaultMessage: '1. If needed, login to the AWS account you will install into',
  },
  AWS_STEP_TWO: {
    id: `${BASE}.aws-step-two`,
    description: 'AWS Second step text',
    defaultMessage: '2. The Cloudformation create stack form shows',
  },
  AWS_STEP_THREE: {
    id: `${BASE}.aws-step-three`,
    description: 'AWS Third step text',
    defaultMessage: '3. Make any changes… Ensure that the correct SSH key is set. DO NOT change the Okta Group ID (we’ll remove Okta from here)',
  },
  AWS_STEP_FOUR: {
    id: `${BASE}.aws-step-four`,
    description: 'AWS Fourth step text',
    defaultMessage: '4. Agree to the IAM creation (we create two roles to limit access of the cluster into customer’s infrastructure [to ReadOnlyAccess]), and click the “Create stack” button',
  },
  AWS_STEP_FIVE: {
    id: `${BASE}.aws-step-five`,
    description: 'AWS Fifth step text',
    defaultMessage: '5. The provisioning of AWS resources will start.  You do not need to follow this screen',
  },
  AWS_STEP_SIX: {
    id: `${BASE}.aws-step-six`,
    description: 'AWS Sixth step text',
    defaultMessage: '6. When install is complete, you’ll receive an email',
  },
  AWS_STEP_SEVEN: {
    id: `${BASE}.aws-step-seven`,
    description: 'AWS Seventh step text',
    defaultMessage: '7. Click on the button to login',
  },
  AWS_STEP_EIGHT: {
    id: `${BASE}.aws-step-eight`,
    description: 'AWS Eighth step text',
    defaultMessage: '8. …and go to your cluster',
  },
  AWS_STEPS_HEADER: {
    id: `${BASE}.aws-steps-header`,
    description: 'AWS steps header text',
    defaultMessage: 'What to expect inside AWS',
  },
  HERO_HEADLINE: {
    id: `${BASE}.hero-headline`,
    description: 'Hero Headline Text',
    defaultMessage: 'Easy 2 Step Install',
  },
  HERO_TITLE: {
    id: `${BASE}.hero-title`,
    description: 'Hero banner title text',
    defaultMessage: 'Install',
  },
  HERO_LEFT_HEADLINE: {
    id: `${BASE}.pointers.left`,
    description: 'Hero banner left panel header text',
    defaultMessage: '1. Choose a name for your organization',
  },
  HERO_LEFT_TEXT: {
    id: `${BASE}.pointers.left.p`,
    description: 'Hero banner left panel text',
    defaultMessage: 'Create your user account on openraven.com and choose a name for your team',
  },
  HERO_RIGHT_HEADLINE: {
    id: `${BASE}.pointers.right`,
    description: 'Hero banner right panel header text',
    defaultMessage: '2. Deploy to AWS with CloudFormation',
  },
  HERO_RIGHT_TEXT: {
    id: `${BASE}.pointers.right.p`,
    description: 'Hero banner right panel text',
    defaultMessage: 'Login to your AWS account, choose your options and we will deploy for you',
  },
  HERO_CTA: {
    id: `${BASE}.text-holder.h6`,
    description: 'bottom cta text',
    defaultMessage: 'It’s that easy.',
  },
  HERO_CTA_LONGER: {
    id: `${BASE}.text-holder.span`,
    description: 'bottom cta text',
    defaultMessage: 'Click the button to start your installation.',
  },
  HERO_RECOMMENDED_READING: {
    id: `${BASE}.text-holder.p`,
    description: 'bottom text',
    defaultMessage: 'We recommend reading our {linkText} before you install.',
  },
  HERO_GUIDE: {
    id: `${BASE}.text-holder.a`,
    description: 'bottom text',
    defaultMessage: 'Getting Started Guide',
  },
  HOW_TO_AWS_ACCOUNT: {
    id: `${BASE}.how-to-aws-account`,
    description: 'How to aws account text',
    defaultMessage: 'An AWS account with the ability to:',
  },
  HOW_TO_CREATE_ASG: {
    id: `${BASE}.how-to-create-asg`,
    description: 'How to create ASG text',
    defaultMessage: 'Create an ASG (Auto-Scaling Group)',
  },
  HOW_TO_CREATE_CFN: {
    id: `${BASE}.how-to-create-cfn`,
    description: 'How to create CFN text',
    defaultMessage: 'Create a CFN (CloudFormation stack)',
  },
  HOW_TO_CREATE_EIP: {
    id: `${BASE}.how-to-create-eip`,
    description: 'How to create EIP text',
    defaultMessage: 'Create an EIP (Elastic IP Address)',
  },
  HOW_TO_CREATE_ESB: {
    id: `${BASE}.how-to-create-esb`,
    description: 'How to create ESB text',
    defaultMessage: 'Create an ELB (Elastic Load Balancer)',
  },
  HOW_TO_CREATE_VPC: {
    id: `${BASE}.how-to-create-vpc`,
    description: 'How to create VPC',
    defaultMessage: 'Create a VPC (Virtual Private Cloud)',
  },
  HOW_TO_DEPLOY: {
    id: `${BASE}.how-to-deploy`,
    description: 'How to deploy AWS text',
    defaultMessage: 'How to deploy AWS',
  },
  HOW_TO_DEPLOY_DESCRIPTION: {
    id: `${BASE}.how-to-deploy-description`,
    description: 'How to deploy AWS description',
    defaultMessage: 'We use AWS Cloudformation to create a new Virtual Private Cloud in your AWS account and then deploy and configure all the resources needed. After creating your account on openraven.com, you will choose an organization name for your team and then navigate to AWS to complete setup. It usually takes less than 15 mins end to end.',
  },
  HOW_TO_DEPLOY_DOCS: {
    id: `${BASE}.how-to-deploy-docs`,
    description: 'How to deploy documentation text',
    defaultMessage: 'There are some alternative ways to deploy. {linkText} for other options.',
  },
  HOW_TO_DEPLOY_DOCS_LINK: {
    id: `${BASE}.how-to-deploy-docs-link`,
    description: 'How to deploy documentation link text',
    defaultMessage: 'See documentation',
  },
  HOW_TO_DEPLOY_EC2: {
    id: `${BASE}.how-to-deploy-ec2`,
    description: 'How to deploy EC2 text',
    defaultMessage: 'Deploy EC2 instances (Elastic Computer Cloud)',
  },
  HOW_TO_DEPLOY_HIGHLIGHT: {
    id: `${BASE}.how-to-deploy-highlight`,
    description: 'How to deploy highlight',
    defaultMessage: 'deploy AWS',
  },
  HOW_TO_DEPLOY_LINK_TEXT: {
    id: `${BASE}.how-to-deploy-link-text`,
    description: 'How to deploy link text',
    defaultMessage: 'Sign up',
  },
  HOW_TO_DEPLOY_SUBTEXT: {
    id: `${BASE}.how-to-deploy-subtext`,
    description: 'How to deploy subtext',
    defaultMessage: '{linkText} here if you don’t already have an Open Raven account.',
  },
  HOW_TO_LIST_SSH: {
    id: `${BASE}.how-to-list-ssh`,
    description: 'How to list ssh text',
    defaultMessage: 'List SSH keys',
  },
  HOW_TO_WHAT_YOU_NEED: {
    id: `${BASE}.how-to-what-you-need`,
    description: 'How to what you need text',
    defaultMessage: 'What you’ll need:',
  },
  IAM_POLICY_LINK: {
    id: `${BASE}.iam-policy-link`,
    description: 'IAM Policy link text',
    defaultMessage: 'Click here to download the AWS IAM policy file',
  },
  INSTALL_FLOW_CONNECT: {
    id: `${BASE}.install-flow-connect`,
    description: 'Install Flow Connect Text',
    defaultMessage: '3. Connect your infrastructure',
  },
  INSTALL_FLOW_CONNECT_DESC: {
    id: `${BASE}.install-flow-connect-desc`,
    description: 'Install Flow Connect Description Text',
    defaultMessage: 'Add an AWS account and deploy the corporate network scanner to start discovery',
  },
  INSTALL_FLOW_CREATE_ORG: {
    id: `${BASE}.install-flow-create-org`,
    description: 'Install Flow Create Org Text',
    defaultMessage: '1. Create an account and an organization',
  },
  INSTALL_FLOW_CREATE_ORG_DESC: {
    id: `${BASE}.install-flow-create-org-desc`,
    description: 'Install Flow Create Org Description Text',
    defaultMessage: 'Create your user account on openraven.com and choose a name for your team',
  },
  INSTALL_FLOW_DEPLOY_AWS: {
    id: `${BASE}.install-flow-deploy-aws`,
    description: 'Install Flow Deploy to AWS Text',
    defaultMessage: '2. Deploy to AWS',
  },
  INSTALL_FLOW_DEPLOY_AWS_DESC: {
    id: `${BASE}.install-flow-deploy-aws-desc`,
    description: 'Install Flow Deploy to AWS Description Text',
    defaultMessage: 'Login to your AWS account, choose your options and we will deploy for you',
  },
  INSTALL_FLOW_GET_STARTED: {
    id: `${BASE}.install-flow-get-started`,
    description: 'Install Flow Get Started Button Text',
    defaultMessage: 'Start installation in your AWS account now ',
  },
});
