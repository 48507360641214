import { get } from 'lodash';
import env from '@beam-australia/react-env';

const mapImageDomain = (imageUrl) => {
  if (env('NODE_ENV') === 'development') {
    return (
      `${env('DEV_CMS_API_URL')}${imageUrl}`
    );
  }
  return imageUrl;
};

const dataTransformer = (payload) => {
  if (!Array.isArray(payload)) {
    return [];
  }
  return (
    payload.map(({ image, sourceIcon, ...data }) => ({
      ...data,
      image: mapImageDomain(get(image, 'url', '')),
      sourceIcon: mapImageDomain(get(sourceIcon, 'url', '')),
    }))
  );
};

export {
  dataTransformer,
};
