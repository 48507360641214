import sleep from './sleep';

async function poll(fn, duration) {
  try {
    await fn();
    await sleep(duration);
  } catch (err) {
    // Silently error out here on the poll
    console.error('Error polling', err);
  }

  poll(fn, duration);
}

export default poll;
