import { defineMessages } from 'react-intl';

const base = 'confirm-demo.';

export const CONFIRM_DEMO = defineMessages({
  HEADER_CONFIRM: {
    id: `${base}header-confirm`,
    description: 'Confirm demo page header',
    defaultMessage: 'Confirm your appointment',
  },
  HEADER_CONFIRMED: {
    id: `${base}header-confirmed`,
    description: 'Confirmed demo page header',
    defaultMessage: 'Confirmed.',
  },
  INDIVIDUAL_DEMO_NAME: {
    id: `${base}individual-demo-name`,
    description: 'individual demo name',
    defaultMessage: '1:1 demo',
  },
  GROUP_DEMO_NAME: {
    id: `${base}group-demo-header`,
    description: 'group demo name',
    defaultMessage: 'Weekly group demo',
  },
  SELECT_DIFFERENT_TIME: {
    id: `${base}select-different-time`,
    description: 'Link text for selecting a different time',
    defaultMessage: 'Select a different time.',
  },
  CONFIRM_LOGIN_SIGNUP: {
    id: `${base}confirm-login-signup`,
    description: 'Prompts user to login or signup',
    defaultMessage: 'To confirm, please {login} or {signup}',
  },
  THANKS_LOGGED_IN: {
    id: `${base}thanks-logged-in`,
    description: 'thanks user for logging in',
    defaultMessage: 'Thanks, {email}',
  },
  CONFIRMATION_THANK_YOU: {
    id: `${base}confirmation-thank-you`,
    description: 'Thanks user for confirming demo time',
    defaultMessage: 'Thanks for signing up for our 1:1 demo. We will send you a meeting invite shortly with a Zoom link and meeting instructions.',
  },
  QUESTIONS_EMAIL: {
    id: `${base}questions-email`,
    description: 'Tells user to email if they have questions',
    defaultMessage: 'If you have any questions before, please contact us at {email}',
  },
});
