import React from 'react';
import { TRANSLATION_KEYS } from 'translations/keys';
import { makeId, IDENTIFIERS } from 'lib/utils/make-id';
import {
  SvgArrowDropdown,
  PopoverMenu,
  hooks,
} from '@openraven/react-styleguide';
import './header-account-management.scss';

const ICON_SIZE = 12;
const { useMedia } = hooks;
const {
  HEADER: {
    MENU_ITEM_SUPPORT,
    MENU_ITEM_SIGN_OUT,
  },
} = TRANSLATION_KEYS;

const HeaderAccountManagement = ({
  savedAvatar,
  authEnabled,
  onLogoutClick,
}) => {
  const { isMobile, isTablet, isLandScape } = useMedia();

  const menuItems = [
    {
      label: 'Documentation',
      to: 'https://docs.openraven.com',
      external: true,
      target: '_blank',
      id: { name: 'account-management-docs', prefix: IDENTIFIERS.HEADER, suffix: IDENTIFIERS.LINK },
    },
    {
      label: MENU_ITEM_SUPPORT,
      to: 'https://www.openraven.com/contact',
      external: true,
      target: '_blank',
      id: { name: 'account-management-contact', prefix: IDENTIFIERS.HEADER, suffix: IDENTIFIERS.LINK },
    },
    {
      label: MENU_ITEM_SIGN_OUT,
      id: { name: 'account-management-sign-out', prefix: IDENTIFIERS.HEADER, sufix: IDENTIFIERS.LINK },
      onClick: () => {
        if (authEnabled) {
          onLogoutClick();
        }
      },
    },
  ];

  return (
    <PopoverMenu
      align="right"
      menuItems={menuItems}
      className="header-rct-component__account-menu"
      // Null Event defaults to opening on hover
      event={(isMobile || isTablet || isLandScape) ? 'click' : null}
    >
      <div className="account-management-link" id={makeId({ name: 'account-management', prefix: IDENTIFIERS.HEADER, suffix: IDENTIFIERS.BUTTON })}>
        <div className="avatar-container">
          <img
            className="avatar"
            src={savedAvatar}
            alt="Avatar"
          />
        </div>
        <SvgArrowDropdown
          height={ICON_SIZE}
          width={ICON_SIZE}
        />
      </div>
    </PopoverMenu>
  );
};

export {
  HeaderAccountManagement,
};
