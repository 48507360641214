import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useNewVersionAvailable } from 'lib/utils/check-version';

import './nav-link.scss';

// Accepts same prop types as LinkOrButton from react-shared, <a>, and <Link>
export const NavLink = ({
  external,
  onClick,
  to,
  children,
  ...rest
}) => {
  const { newVersionAvailable } = useNewVersionAvailable();
  const className = cx(rest.className, 'nav-link__rct-component');

  if (external || newVersionAvailable) {
    return (
      <a
        href={to}
        onClick={onClick}
        target={external ? '_blank' : ''}
        {...rest}
        className={className}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={to}
      onClick={onClick}
      {...rest}
      className={className}
    >
      {children}
    </Link>
  );
};
