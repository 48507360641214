/**
 * This file contains the basic configuration for the api calls
 * @author Ericky Dos Santos
 * @file api.js
 */

import env from '@beam-australia/react-env';
import axios from 'axios';
import {
  API_METHOD_GET,
  API_METHOD_POST,
  API_METHOD_DELETE,
  API_METHOD_PUT,
} from 'rdx/constants';

// Base url of the node server
export const BASE_URL = env('NODE_ENV') !== 'development' ? `${window.location.origin}/api/` : `${env('API_URL')}/api/`;

/**
 * All endpoints of the application.
 * The rdx modules uses this variable to create actions/reducers/selectors/types.
 * Actions will be named in the following convention:
 *  getTestRequest, getTestSuccess, getTestError, getTestReset
 *  postTestRequest, postTestSuccess, postTestError, postTestReset
 * Reducers will be named in the following convention:
 *  testData
 *  postTestData
 * Selectors will be named in the following convention:
 *  getTestData
 *  getPostTestData
 * Types will be named in the following convention:
 *  GET_TEST_DATA_REQUEST, GET_TEST_DATA_SUCCESS, GET_TEST_DATA_ERROR, GET_TEST_DATA_RESET
 *  POST_TEST_DATA_REQUEST, POST_TEST_DATA_SUCCESS, POST_TEST_DATA_ERROR, POST_TEST_DATA_RESET
 */
export const API_ENDPOINTS = {
  [API_METHOD_GET]: {
    USER_SETTINGS: '/okta/users/:userId',
    PARDOT_SCORE: '/salesforce/pardot-score',
    TAGS: '/contentful/blog-tags',
    BLOGS: '/contentful/blogs',
    NEWS: '/cms/articles',
    ABOUTS: '/contentful/our-story',
    SECURITY: '/contentful/security',
    LIBRARIES: '/cms/libraries',
    PLATFORMS: 'cms/platformpages',
    DEMOS: '/demo-booking/demos',
    GROUP_DEMOS: '/demo-booking/group-demos',
    CAREERS: '/contentful/careers',
    JOBS: '/contentful/jobs',
    PRODUCTS: '/cms/productoverviewpages',
  },
  [API_METHOD_POST]: {
    COMPANY_EMAIL_SUBSCRIBE: '/email/company/confirm',
    COMPANY_EMAIL_UNSUBSCRIBE: '/email/company/unsubscribe',
    RELEASE_EMAIL_SUBSCRIBE: '/email/release/confirm',
    RELEASE_EMAIL_UNSUBSCRIBE: '/email/release/unsubscribe',
    EMAIL_DEPLOY_INVITE: '/email/deploy-invite',
    EMAIL_DEPLOY_NOTE: '/email/deploy-note',
    EMAIL_SUBSCRIBE: '/email/newsletter/subscribe',
    EMAIL_UNSUBSCRIBE: '/email/newsletter/unsubscribe',
    EMAIL_CONFIRM: '/email/newsletter/confirm',
    JOB_APPLY: '/email/careers',
    CONTACT_SUBMIT: '/contacts/form/submit',
    DEMO_CONFIRMATION_EMAIL: '/email/demo-confirmation',
    STAGE_USER: '/okta/stage-user',
    AUTHENTICATE_ACTIVATION_TOKEN: '/okta/authenticate-activation-token',
    UPDATE_USER_SETTINGS: '/okta/update-user-settings',
    RESEND_USER_ACTIVATION_EMAIL: '/okta/resend-activation-email',
    RECOVER_PASSWORD: '/okta/recover-password',
    CHANGE_PASSWORD: '/okta/change-password',
    VERIFY_RECOVERY_TOKEN: '/okta/verify-recovery-token',
    RESET_PASSWORD: '/okta/reset-password',
    BLOG_COMMENT: '/cms/comments',
    INDIVIDUAL_DEMO: '/demo-booking/individual',
    EMAIL_DEMO_BOOKING: '/email/demo-booking',
    EMAIL_REQUEST_INSTALLATION: '/email/request-installation',
    GROUP_SUMMARIES: { url: '/groups/summaries', localStorage: true },
    UPLOAD_AVATAR: '/accounts/user/profile/avatar',
    MARKETPLACE: '/marketplace',
  },
  [API_METHOD_DELETE]: {
    GROUP: '/groups/:groupName',
    ORGANIZATION_USER: '/groups/:groupName/users/:userName',
    USER: '/okta/delete-user',
  },
  [API_METHOD_PUT]: {
    GROUP_DEMO: '/demo-booking/group',
  },
};

export const api = axios.create({
  baseURL: BASE_URL,
  timeout: env('AXIOS_TIMEOUT'),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const asgardApi = axios.create({
  baseURL: env('ASGARD_URL'),
  timeout: env('AXIOS_TIMEOUT'),
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});
